import { Alert, Avatar, Button, Card, Snackbar, ThemeProvider, Typography, createTheme } from '@mui/material';
import { dateAndTimeFormat } from '../../../functions/CommonFunctions';
import { IAlert, IComment } from '../../../api/interfaces/interfaces';
import { useCallback, useState } from 'react';
import { ResponseCommentForm } from './ResponseCommentForm';
import { sendCommentResponse } from '../../../api/commentsApi';
import { CustomAlert } from '../../utils/CustomAlert';
import { useNavigate } from 'react-router-dom';

interface CommentDetailProps {
	index: number;
	comment: IComment;
	isOwner: boolean;
	handleOnResponsePost: () => void;
}

export const CommentDetail = (props: CommentDetailProps) => {
	const navigate = useNavigate();
	const { index, comment, isOwner, handleOnResponsePost } = props;
	const [showResponseForm, setShowResponseForm] = useState<boolean>(false);
	const [isFetchingResponse, setIsFetchingResponse] = useState<boolean>(false);
	const [alert, setAlert] = useState<IAlert>({
		type: 'info',
		message: '',
		show: false,
	});
	// pop up
	const [notification, setNotifation] = useState(false);
	const closePopUp = () => {
		setNotifation(false)};
		const theme = createTheme({
			palette: {
			  success: {
				main: '#66BB6A', // Color principal personalizado para success
				contrastText: '#fff', // Color del texto en el botón de éxito
			  },
			},
		  });
	//

	const handleOnCLickAnswer = useCallback(() => {
		setShowResponseForm(true);
	}, []);

	const onSendResponse = async (commentID: string, comment: string) => {
		setIsFetchingResponse(true);
		const rta = await sendCommentResponse(commentID, comment);
		setIsFetchingResponse(false);
		setShowResponseForm(false);
		rta.status !== 200 &&
			setAlert({
				type: 'error',
				message: 'ups algo salio mal',
				show: true,
			});
		handleOnResponsePost();
		setNotifation(true);
	};

	return (
		<Card
			sx={{
				height: 'auto',
				margin: '2vh',
				backgroundColor: '#111',
			}}>
			<div style={{ display: 'flex' }}>
				<Button
					onClick={()=>navigate(`/sellerProfile/${comment.userID}`)}
				>
				<Avatar
					src={comment?.owner?.image?.imageData ?? ''}
					sx={{
						width: 30,
						height: 30,
					}}
					/>
					</Button>
				<Typography color="#f1f1f1" key={index + 'Typography'} style={{ margin: '1vh', marginLeft: '-.3rem' }}>
					{dateAndTimeFormat(comment.createdAt)}
				</Typography>
			</div>
			<Typography color="#f1f1f1" key={index + 'Typography2'} style={{ margin: '1vh' }}>
				- {comment.comment}
			</Typography>
			{comment.responses !== null && comment.responses.length > 0 ? (
				<Card
					sx={{
						height: 'auto',
						margin: '2vh',
						backgroundColor: '#111',
						boxShadow: 'none',
					}}>
					<div style={{ display: 'flex' }}>
						<Avatar
							src={comment?.product?.userOwner?.image?.imageData ?? ''}
							sx={{
								width: 30,
								height: 30,
							}}
						/>
						<Typography color="#f1f1f1" key={index + 'Typography3'} style={{ margin: '1vh' }}>
							{dateAndTimeFormat(comment.responses[0].createdAt.toString())}
						</Typography>
					</div>
					<Typography color="#f1f1f1" key={index + 'Typography4'} style={{ margin: '1vh' }}>
						- {comment.responses[0].comment}
					</Typography>
				</Card>
			) : (
				<>
					{showResponseForm ? (
						<ResponseCommentForm
							commentID={comment.id}
							onSendResponse={onSendResponse}
							isLoading={isFetchingResponse}
						/>
					) : (
						isOwner && (
							<Button
								size="small"
								sx={{
									bgcolor: '#111',
									color: 'white',
									'&:hover': { bgcolor: 'grey' },
									width: 'fit-content',
									margin: .5,
									marginLeft:'1rem',
									borderBottom: 'thin inset #B71C1C',
									borderRadius: '5px',
								}}
								onClick={handleOnCLickAnswer}>
								Responder
							</Button>
						)
					)}
				</>
			)}
			{alert.show && <CustomAlert message={alert.message} setAlert={setAlert} type={alert.type} />}
			<ThemeProvider theme={theme}>
			<Snackbar
				open={notification}
				autoHideDuration={4000}
				onClose={closePopUp}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				>
				<Alert
					onClose={closePopUp}
					severity="success"
					variant="filled"
					sx={{ width: {xs:'80%',sm:'100%'}, mt:'2.8rem' }}
				>
					Respuesta enviada exitosamente
				</Alert>
				</Snackbar>
				</ThemeProvider>
		</Card>
	);
};
