import { Box, Button, Card, Grid, IconButton, Paper, TextField, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { IProduct, ISellerProfile } from '../../api/interfaces/interfaces';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SearchIcon from '@mui/icons-material/Search';
import { useParams } from 'react-router-dom';
import { getProductByOwner, getProductByOwnerID, getProductBySearchByOwner } from '../../api/productsApi';
import { getSellerProfileByID } from '../../api/userApi';
import { ProductCard } from '../products/ProductCard';
import persona from '../../images/persona.png';
import { dateFormat } from '../../functions/CommonFunctions';
import { CommentPagination } from '../products/comments/commentPagination';
import { useNavigate } from 'react-router-dom';
import BadgeIcon from '@mui/icons-material/Badge'; //name
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'; //phone
import LocationOnIcon from '@mui/icons-material/LocationOn'; //lcation
import DescriptionIcon from '@mui/icons-material/Description'; //descrip
import InfoIcon from '@mui/icons-material/Info';
import { ProductPreviewSkeleton } from '../products/ProductPreviewSkeleton';
interface PreviewProps {
	// personalData?: IPersonalInformation;
	sellerProfileData?: ISellerProfile;
	setShowPreview?: (value: boolean) => void;
}

export const UserProfile = (props: PreviewProps) => {
	const { sellerProfileData, setShowPreview } = props;
	const navigate = useNavigate();
	const { sellerID } = useParams();
	const [products, setProducts] = useState<IProduct[]>([]);
	const [selectedPage, setSelectedPage] = useState<number>(1);
	const [cantPages, setCantPages] = useState<number>(0);
	const [inputSearch, setInputSearch] = useState<string>('');
	const [sellerProfile, setSellerProfile] = useState<ISellerProfile>();
	const [loading, setLoading] = useState(false);
	const avgResponseTimeToNumber = parseFloat(
		sellerProfile?.avgResponseTime !== null ? sellerProfile?.avgResponseTime! : '0',
	);
	function formatoFlexible(value: number) {
		const minutes = Math.floor(value);
		const hours = Math.floor(minutes / 60);
		const days = Math.floor(hours / 24);
		const weeks = Math.floor(days / 7);
		const remainingHours = hours % 24;
		const remainingMinutes = minutes % 60;
		let result = '';

		
		if (days > 0) {
			result += days === 1 ? `${days} día ` : `${days} días `;
			if (remainingHours > 0 || remainingMinutes > 0) {
				result += 'y ';
			}
		}
		if (remainingHours > 0) {
			result += remainingHours === 1 ? `${remainingHours} hora ` : `${remainingHours} horas `;
			if (remainingMinutes > 0) {
				result += ' ';
			}
		}
		return result;
	}

	const handlePageClick = (index: number) => {
		setSelectedPage(index);
	};

	const handleNext = useCallback(() => {
		if (selectedPage < cantPages!) handlePageClick(selectedPage + 1);
	}, [cantPages, selectedPage]);

	const handleBack = useCallback(() => {
		if (selectedPage > 1) handlePageClick(selectedPage - 1);
	}, [selectedPage]);

	const getSellerProfile = async () => {
		const profile = await getSellerProfileByID(sellerID!);
		setSellerProfile(profile);
	};
	const getSellerProducts = async () => {
		if (sellerID) {
			setLoading(true);
			const { products, totalPages } = await getProductByOwnerID(sellerID, 10, selectedPage);
			setProducts(products);
			setCantPages(totalPages);
			setLoading(false);
		} else {
			setLoading(true);
			const { products, totalPages } = await getProductByOwner(10, selectedPage);
			setProducts(products);
			setCantPages(totalPages);
			setLoading(false);
		}
	};

	const handleSearch = async () => {
		if (inputSearch.length > 3) await getSellerProductsBySearch();
	};

	const getSellerProductsBySearch = async () => {
		if (sellerID) {
			const { products, totalPages } = await getProductBySearchByOwner(inputSearch, 10, selectedPage, sellerID);
			setProducts(products);
			setCantPages(totalPages);
		} else {
			const { products, totalPages } = await getProductBySearchByOwner(inputSearch, 10, selectedPage);
			setProducts(products);
			setCantPages(totalPages);
		}
	};

	useEffect(() => {
		getSellerProducts();
	}, [selectedPage]);

	useEffect(() => {
		getSellerProducts();
		if (sellerID) {
			getSellerProfile();
		} else setSellerProfile(sellerProfileData);
	}, [sellerID]);

	const capitalizeFirstLetter = (text: string | undefined) => {
		if (!text) return '';
		return text
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	};
	const formattedSellerName = capitalizeFirstLetter(sellerProfile?.name); // convierte todos los nombres de usuario a Este Formato
	const formattedSellerLastName = capitalizeFirstLetter(sellerProfile?.lastname); // convierte todos los apellidos de usuario a Este Formato

	return (
		<Grid sx={{ backgroundColor: '#111111', display: 'flex', justifyContent: 'center' }}>
			<Card
				sx={{
					width: { xs: '100vw', lg: '80vw' },
					height: '115vh',
					backgroundColor: '#111111',
					overflowY: 'auto',
					'&::-webkit-scrollbar': {
						width: 0, // Ancho cero para hacerla invisible
					},
				}}>
				<Grid container>
					<Grid item sx={{ marginLeft: { xs: '1.5rem', lg: '1.5rem' } }}>
						{sellerID === undefined ? (
							<Button
								sx={{
									minWidth: { xs: '20px', lg: '50px' },
									padding: { xs: 0, lg: 0.4 },
									backgroundColor: '#111111',
									color: 'white',
									borderLeft: '1px groove #B71C1C',
									borderBottom: '1.5px inset #B71C1C',
									fontSize: { xs: '.6rem', md: '.8rem' },
								}}
								onClick={() => setShowPreview!(false)}>
								{' '}
								<KeyboardBackspaceIcon />
							</Button>
						) : (
							<Button
								sx={{
									minWidth: { xs: '20px', lg: '50px' },
									padding: { xs: 0, lg: 0.4 },
									backgroundColor: '#111111',
									color: 'white',
									borderLeft: '1px groove #B71C1C',
									borderBottom: '1.5px inset #B71C1C',
									fontSize: { xs: '.6rem', md: '.8rem' },
								}}
								onClick={() => navigate(-1)}>
								{' '}
								<KeyboardBackspaceIcon />
							</Button>
						)}
					</Grid>

					<Grid container sx={{ mt: '1rem' }}>
						{!sellerProfile?.image?.imageData ? (
							<Grid
								item
								xs={3.5}
								sm={2.5}
								md={2.5}
								sx={{ mt: { xs: '4.5rem', md: '0' }, mr: '1rem', height: { xs: '20vh', lg: '35vh' } }}>
								<Box
									component="img"
									alt="fotoTest"
									src={persona}
									style={{
										borderRadius: '2rem',
										marginLeft: '2rem',
										maxWidth: '100%',
										maxHeight: '100%',
										padding: '4%',
										backgroundColor: 'white',
									}}
								/>
							</Grid>
						) : (
							<Grid item xs={3.5} sm={2.5} md={2.5} sx={{ mt: { xs: '4.5rem', md: '0' }, mr: '1rem' }}>
								<Box
									component="img"
									alt="fotoTest"
									src={sellerProfile?.image?.imageData!}
									style={{
										borderRadius: '2rem',
										marginLeft: '2rem',
										maxWidth: '100%',
										maxHeight: '100%',
										padding: '4%',
										backgroundColor: '#111111',
									}}
								/>
							</Grid>
						)}

						<Grid
							item
							xs={8.5}
							sm={6.5}
							md={6.5}
							sx={{ display: 'flex', flexDirection: 'column', height: 'fit-content', ml: '-1rem' }}>
							<Grid
								item
								sx={{
									display: 'flex',
									justifyContent: 'center',
									mt: { lg: '-1.5rem' },
									mb: { xs: '2.6rem', lg: '1rem' },
									width: { xs: '80%', md: '80%' },
									ml: { xs: '-2rem', lg: '12rem' },
								}}>
								{!sellerProfile?.sellerName ? (
									<Typography
										sx={{
											color: 'white',
											fontSize: { xs: '1rem', lg: '1.5rem' },
											borderBottom: '1px solid #F5F5F5',
											whiteSpace: 'nowrap',
										}}>
										Nombre de vendedor no disponible
									</Typography>
								) : (
									<Typography
										sx={{
											color: 'white',
											fontSize: { xs: '1.5rem', lg: '2.2rem' },
											borderBottom: '2px groove #B71C1C',
											whiteSpace: 'nowrap',
										}}>
										{sellerProfile?.sellerName}
									</Typography>
								)}
							</Grid>

							<Grid item sx={{ ml: { xs: '3rem', lg: '4rem' }, mb: '1.2rem', width: { xs: '50%', lg: '30%' } }}>
								<Typography
									sx={{
										color: 'white',
										fontSize: { xs: '.7rem', lg: '1rem' },
										display: 'flex',
										alignItems: 'center',
										// borderBottom: '1px solid #F5F5F5',
									}}>
									<BadgeIcon sx={{ mr: '2%' }} />
									{formattedSellerName} {formattedSellerLastName}
								</Typography>
							</Grid>
							{/* <Grid item sx={{ ml: { xs: '3rem', lg: '4rem' }, mb: '1.2rem', width: { xs: '50%', lg: '30%' } }}>
								<Typography
									sx={{
										color: 'white',
										fontSize: { xs: '.7rem', lg: '1rem' },
										borderBottom: '1px solid #F5F5F5',
									}}>
									
								</Typography>
							</Grid> */}
							<Grid item sx={{ ml: { xs: '3rem', lg: '4rem' }, mb: '1.2rem', width: { xs: '50%', lg: '30%' } }}>
								{!sellerProfile?.sellerPhone ? (
									<Typography
										sx={{
											color: 'white',
											fontSize: { xs: '.7rem', lg: '.8rem' },
											fontWeight: 'bold',
											// borderBottom: '1px solid #F5F5F5',
											whiteSpace: 'nowrap',
											display: 'flex',
											alignItems: 'center',
										}}>
										<PhoneIphoneIcon sx={{ mr: '2%' }} />
										Teléfono no disponible
									</Typography>
								) : (
									<Typography
										sx={{
											color: 'white',
											fontSize: { xs: '.7rem', lg: '1rem' },
											display: 'flex',
											alignItems: 'center',
											// borderBottom: '1px solid #F5F5F5',
										}}>
										<PhoneIphoneIcon sx={{ mr: '2%' }} />
										{sellerProfile?.sellerPhone}
									</Typography>
								)}
							</Grid>
							<Grid item sx={{ ml: { xs: '3rem', lg: '4rem' }, mb: '1.7rem', width: { xs: '50%', lg: '30%' } }}>
								{!sellerProfile?.state ? (
									<Typography
										sx={{
											color: 'white',
											fontSize: { xs: '.7rem', lg: '.8rem' },
											fontWeight: 'bold',
											// borderBottom: '1px solid #F5F5F5',
											whiteSpace: 'nowrap',
											display: 'flex',
											alignItems: 'center',
										}}>
										<LocationOnIcon sx={{ mr: '2%' }} />
										Dirección no disponible
									</Typography>
								) : (
									<Typography
										sx={{
											color: 'white',
											fontSize: { xs: '.7rem', lg: '1rem' },
											// borderBottom: '1px solid #F5F5F5',
											whiteSpace: 'nowrap',
											display: 'flex',
											alignItems: 'center',
										}}>
										<LocationOnIcon sx={{ mr: '2%' }} />
										{sellerProfile?.state}
									</Typography>
								)}
							</Grid>

							<Grid
								item
								sx={{
									ml: { xs: '-5rem', lg: '3.8rem' },
									mt: { sm: '2rem', md: -1.5 },
									mb: { xs: '0', sm: '1rem', lg: '1.2rem' },
								}}>
								{sellerProfile?.sellerDescription && (
									<Typography
										sx={{
											overflow: 'auto',
											width: '80%',
											height: { xs: '7vh', sm: '5vh', lg: '10vh' },
											color: 'white',
											fontSize: { xs: '.6rem', lg: '1rem' },
											// border: '2px inset #F5F5F5',
											borderRadius: '5px',
											padding: '0.25rem',
											display: 'flex',
											alignItems: 'flex-start',
										}}>
										<DescriptionIcon sx={{ mr: '2%' }} />
										{sellerProfile?.sellerDescription}
									</Typography>
								)}
							</Grid>
						</Grid>

						<Grid item xs={12} sm={3} md={3} sx={{ marginTop: { sm: '2rem' }, marginLeft: { xs: '2rem', sm: 0 } }}>
							<Paper sx={{ backgroundColor: '#111111' }}>
								<Typography
									color="white"
									whiteSpace="nowrap"
									sx={{
										fontSize: { xs: '.7rem', sm: '1rem' },
										width: 'fit-content',
										// borderBottom: '1px solid #F5F5F5',
										marginTop: '1rem',
										display: 'flex',
										alignItems: 'center',
									}}>
									<InfoIcon sx={{ mr: '2%' }} />
									<strong>Publicaciones:</strong>&nbsp;{sellerProfile?.productCount!}
								</Typography>
								<Typography
									color="white"
									whiteSpace="nowrap"
									sx={{
										fontSize: { xs: '.7rem', sm: '1rem' },
										width: 'fit-content',
										marginTop: '1rem',
										display: 'flex',
										alignItems: 'center',
									}}>
									<InfoIcon sx={{ mr: '2%' }} />
									<strong>Publicando desde:&nbsp; </strong>
									{dateFormat(sellerProfile?.firstPublishDate!)}
								</Typography>
								<Typography
									color="white"
									sx={{
										fontSize: { xs: '.7rem', sm: '1rem' },
										width: 'fit-content',
										marginTop: '1rem',
										whiteSpace: 'nowrap',
										display: 'flex',
										alignItems: 'flex-start',
									}}>
									<InfoIcon sx={{ mr: '2%' }} />
									<div>
										<strong>Tiempo promedio de respuesta: </strong> <br />
										{avgResponseTimeToNumber > 0 ? <>{formatoFlexible(avgResponseTimeToNumber)}</> : 'No disponible'}
									</div>
								</Typography>
								{/* <Typography
									color="white"
									whiteSpace="nowrap"
									sx={{
										fontSize: { xs: '.7rem', sm: '1rem' },
										width: { xs: 'fit-content', sm: '50%' },
										borderBottom: '1px solid #F5F5F5',
										marginTop: '1.3rem',
									}}>
									<strong>Otro dato relevante:</strong>
								</Typography> */}
							</Paper>
						</Grid>
					</Grid>
				</Grid>

				<Grid
					container
					xs={12}
					display="flex"
					justifyContent="flex-start"
					sx={{ marginLeft: { xs: '2rem', md: '3rem' }, marginTop: { xs: '1.5rem', md: 0 } }}>
					<Grid item xs={12} md={8}>
						<Box
							sx={{
								color: 'white',
								fontSize: { xs: '.6rem', md: '1.1rem' },
								marginTop: { xs: '.5rem', md: '.5rem' },
							}}>
							Otros productos publicados por el usuario
						</Box>
					</Grid>

					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Box
							sx={{
								marginTop: { xs: '.5rem', md: '.5rem' },
								height: { xs: '5.2vh', sm: '5.5vh', md: '3.5vh' },
								width: { xs: '60vw', sm: '30.5vw', md: '18vw' },
							}}
							key="box1">
							<TextField
								variant="outlined"
								placeholder="Buscar repuestos…"
								fullWidth
								onKeyDown={({ code }) => code === 'Enter' && handleSearch()}
								onChange={({ target: { value } }) => setInputSearch(value)}
								InputProps={{
									startAdornment: (
										<IconButton
											edge="start"
											aria-label="search"
											sx={{ p: 1, borderRadius: 10, color: 'white' }}
											onClick={handleSearch}>
											<SearchIcon sx={{ fontSize: { xs: '.7rem', lg: '1.5rem' }, color: 'grey' }} />
										</IconButton>
									),
									sx: {
										backgroundColor: 'transparent',
										outline: 'none',
										color: 'white',
										height: { xs: '2.5vh', md: '3.5vh' },
										fontSize: { xs: '.6rem', lg: '1rem' },
										'&:hover .MuiOutlinedInput-notchedOutline': {
											borderColor: 'transparent',
										},
										'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: 'transparent',
										},
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: 'transparent',
										},
									},
								}}
								sx={{
									border: 'solid thin',
									color: '#717171',
									borderColor: '#717171',
									borderRadius: 10,
									':focus': {
										outline: 'none',
										bordeColor: 'blue',
									},
								}}
							/>
						</Box>
					</Grid>
					<Grid container sx={{ marginTop: '1rem' }} display="flex">
						{/* Por ahora lo resolvemos asi, mas adelante vemos si lo cambiamos( lo tire ahi al map, no se si esta en el lugar correcto) */}
						{!loading
							? products.slice(0, 4)?.map((product) => (
								<Grid item xs={10} sm={6} md={6} lg={4} xl={2.8}>
									<ProductCard
										product={{
											id: product.id!,
											condition: product.condition!,
											images: product.images!,
											name: product.name!,
											price: product.price!,
											publishedAt: product.publishedAt!,
											status: product.status!,
											userOwner: {
												image: { imageData: sellerProfile?.image?.imageData! },
												sellerName: sellerProfile?.sellerName!,
											},
											city: product.city!,
											state: product.state!,
										}}
									/>
								</Grid>
							))
							: Array(4)
								.fill(0)
								.map((i, index) => (
									<Grid item key={index} xs={10} sm={6} md={6} lg={4} xl={2.8} padding={2}>
										<ProductPreviewSkeleton key={index + 'skeleton'} />
									</Grid>
								))}
					</Grid>
					{cantPages > 1 && (
						<CommentPagination
							handleBack={handleBack}
							handleNext={handleNext}
							handlePageClick={handlePageClick}
							selectedPage={selectedPage}
							totalPages={cantPages}
						/>
					)}
				</Grid>
			</Card>
		</Grid>
	);
};
