import { Navigate, useNavigate } from 'react-router-dom';
import { UserContext } from '../provider/UserContext';
import { useContext, useEffect } from 'react';
import NavBar from '../components/navbar/NavBar';
import { checkToken, logout, refreshToken } from '../api/authApi';

interface PrivateRouteProps {
	element: JSX.Element;
}
export const PrivateRoutes = (props: PrivateRouteProps) => {
	const { element } = props;
	const navigate = useNavigate();
	const userContext = useContext(UserContext);

	const checkAndRefreshToken = async () => {
		const { request } = await checkToken();
		if (request.status === 401) {
			const {
				request: { status },
			} = await refreshToken(userContext);
			if (status === 401 || status === 403) {
				logout(userContext);
				navigate('/login');
			} 
			else window.location.reload();
		}
	};

	useEffect(() => {
		checkAndRefreshToken();
	});
	return userContext.token ? [<NavBar key={'navbar'} />, element] : <Navigate to="/login" replace />;
};
