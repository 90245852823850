import { Box, CircularProgress, Grid, Tab } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { TabPanel, TabContext, TabList } from '@mui/lab';
import { PersonalInformationTabPanel } from './PersonalInformationTabPanel';
import { PrivacityConfigurationTabPanel } from './privacityConfiguration/PrivacityConfigurationTabPanel';
import { SellerProfileTabPanel } from './SellerProfileTabPanel';
import { getAccountInformation, getPersonalInformation, getSellerProfile } from '../../../api/userApi';
import {
	IAccountInformation,
	IImage,
	IPersonalInformation,
	ISellerProfile,
	IState,
} from '../../../api/interfaces/interfaces';
import { getArgentinianStates } from '../../../api/argentinaGobApi';
import { UserProfile } from '../UserProfile';

export const EditUserProfile = () => {
	const [value, setValue] = useState('Personal');
	const [loading, setLoading] = useState(true);
	const [sellerProfile, setSellerProfile] = useState<ISellerProfile>({
		avgResponseTime: '',
		city: '',
		firstPublishDate: '',
		lastname: '',
		name: '',
		productCount: 0,
		sellerDescription: '',
		sellerName: '',
		sellerPhone: 0,
		state: '',
		image: {},
	});
	const [showPreview, setShowPreview] = useState(false);
	const [personalInformation, setPersonalInformation] = useState<IPersonalInformation>();
	const [accountInformation, setAccountInformation] = useState<IAccountInformation>({ email: '' });
	const [states, setStates] = useState<IState[]>([]);

	const handlePreview = (personalData: IPersonalInformation, sellerData: ISellerProfile) => {
		setSellerProfile(sellerData);
		setPersonalInformation(personalData);
		setShowPreview(true);
	};

	const handleChange = (event: any, newValue: any) => {
		setValue(newValue);
	};

	const handleChangeSellerForm = useCallback(
		(fieldName: keyof ISellerProfile, value: string | number | IImage | null) => {
			setSellerProfile((prevData) => ({
				...prevData,
				[fieldName]: value,
			}));
		},
		[sellerProfile],
	);

	const loadSellerProfile = async () => {
		const seller = await getSellerProfile();
		setSellerProfile(seller);
	};

	const loadPersonalInformation = async () => {
		const pInfo = await getPersonalInformation();
		setPersonalInformation(pInfo);
	};

	const loadAccountInformation = async () => {
		const info = await getAccountInformation();
		setAccountInformation(info);
	};

	const loadUserData = async () => {
		setLoading(true);
		await loadPersonalInformation();
		await loadSellerProfile();
		await loadAccountInformation();
		await loadStates();
		setLoading(false);
	};
	const loadStates = async () => {
		const states = await getArgentinianStates('id,nombre');
		// Ordenar los estados alfabeticamente
		const sortedStates = states.sort((a, b) => a.nombre.localeCompare(b.nombre));
		setStates(sortedStates);
	};
	useEffect(() => {
		loadUserData();
	}, []);

	return (
		<>
			<Grid
				container
				width={'auto'}
				height="110vh"
				overflow="hidden"
				sx={{
					backgroundColor: '#111111',
				}}>
				<Grid
					item
					container
					justifyContent="center"
					sx={{
						margin: 'auto',
						mt: 5,
						height: 'auto',
						width: { xs: '80%', md: '50%' },
						display: 'flex',
						justifyContent: 'center',
					}}>
					{!showPreview && (
						<Box
							sx={{
								width: '100%',
								padding: 1,
								backgroundColor: '#111111',
								border: '1px inset #F44336',
								borderRadius: '14px',
							}}>
							<TabContext value={value}>
								<Grid container display="flex" justifyContent="space-around">
									<TabList
										TabIndicatorProps={{
											sx: {
												backgroundColor: '#B71C1C',
											},
										}}
										onChange={handleChange}
										aria-label="basic tabs example"
										sx={{ backgroundColor: '#111111' }}>
										<Tab
											label="Personal"
											value="Personal"
											sx={{
												color: 'white',
												fontSize: { xs: '.5rem', md: '.9rem' },
												marginRight: { xs: 0.5, xl: 15 }, // Márgen derecho para separar del siguiente tab
												'&.Mui-selected': {
													// Estilos cuando el tab está seleccionado
													color: 'white',
												},
											}}
										/>
										<Tab
											label="Perfil de ventas"
											value="Ventas"
											sx={{
												color: 'white',
												fontSize: { xs: '.5rem', md: '.9rem' },
												marginRight: { xs: 0.5, xl: 15 }, // Márgen derecho para separar del siguiente tab
												'&.Mui-selected': {
													// Estilos cuando el tab está seleccionado
													color: 'white',
												},
											}}
										/>
										<Tab
											label="Seguridad"
											value="Seguridad"
											sx={{
												color: 'white',
												fontSize: { xs: '.5rem', md: '.9rem' },
												'&.Mui-selected': {
													// Estilos cuando el tab está seleccionado
													color: 'white',
												},
											}}
										/>
									</TabList>
								</Grid>
								<TabPanel value="Personal" sx={{ marginTop: '-2%' }}>
									{loading ? (
										<Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
											<CircularProgress color="error" size={'3rem'} sx={{ marginTop: '1rem' }} />
										</Grid>
									) : (
										<PersonalInformationTabPanel
											personalData={personalInformation!}
											setPersonalInformation={setPersonalInformation}
										/>
									)}
								</TabPanel>

								<TabPanel value="Ventas" sx={{ marginTop: '-2%' }}>
									{loading ? (
										<Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
											<CircularProgress color="error" size={'3rem'} sx={{ marginTop: '1rem' }} />
										</Grid>
									) : (
										<SellerProfileTabPanel
											sellerProfile={sellerProfile!}
											handleChangeSellerForm={handleChangeSellerForm}
											states={states}
											handlePreview={handlePreview}
											personalData={personalInformation!}
											setPersonalInformation={setPersonalInformation}
										/>
									)}
								</TabPanel>

								<TabPanel value="Seguridad" sx={{ marginTop: '-2%' }}>
									{loading ? (
										<Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
											<CircularProgress color="error" size={'3rem'} sx={{ marginTop: '1rem' }} />
										</Grid>
									) : (
										<PrivacityConfigurationTabPanel
											accountInfo={accountInformation}
											setAccountInformation={setAccountInformation}
										/>
									)}
								</TabPanel>
							</TabContext>
						</Box>
					)}
					{showPreview && (
						<UserProfile
							// personalData={personalInformation}
							sellerProfileData={{
								...sellerProfile!,
								name: personalInformation?.name!,
								lastname: personalInformation?.lastname!,
							}}
							setShowPreview={setShowPreview}
						/>
					)}
				</Grid>
			</Grid>
		</>
	);
};
