import { Grid, Typography } from '@mui/material';
import { ProductListCarousel } from '../ProductListCarousel';
import { ProductListCarouselSkeleton } from '../ProductListCarouselSkeleton';
import { IProductPreview } from '../../../api/interfaces/interfaces';

interface RecientlyAddedProps {
	products: IProductPreview[];
	loading: boolean;
	isMobile: boolean;
}
export const ProductsRecientlyAdded = (props: RecientlyAddedProps) => {
	const { loading, products, isMobile } = props;
	return (
		<Grid
			container
			width="100%"
			alignItems="start" // Cambia de "center" a "start" para alinear los items al inicio
			justifyContent="center" // Cambia de "center" a "start" para justificar los items al inicio
			display="grid"
			marginTop="3rem"
			gap="1rem" // Ejemplo de margen entre los elementos de la cuadrícula
			sx={{
				pl: { xs: 0, sm: 0, md: '10rem' },
				pr: { xs: 0, sm: 0, md: '10rem' },
			}}>
			<Typography
				sx={{
					color: 'white',
					fontSize: '1rem',
					fontFamily: 'Roboto',
					fontWeight: 'bold',
					marginBottom: '-3rem',
				}}>
				Repuestos agregados recientemente:
			</Typography>
			{!loading ? (
				<ProductListCarousel productList={products} />
			) : isMobile ? (
				Array(1)
					.fill(0)
					.map((i, index) => (
						<Grid item key={index} xs={1} xl={2} sx={{ marginTop: '3rem' }}>
							<ProductListCarouselSkeleton key={index + 'skeleton'} />
						</Grid>
					))
			) : (
				Array(1)
					.fill(0)
					.map((i, index) => (
						<Grid item key={index} xs={1} xl={2} sx={{ marginTop: '3rem' }}>
							<ProductListCarouselSkeleton key={index + 'skeleton'} />
						</Grid>
					))
			)}
		</Grid>
	);
};
