import { Grid } from '@mui/material';
import { FileInput } from '../../../utils/FileInput';
import { IImage, INewProduct } from '../../../../api/interfaces/interfaces';

interface StepOneProps {
	handleChangeForm: (fieldName: keyof INewProduct, value: string | number | IImage[] | null) => void;
	newProduct: INewProduct;
	editedProductId: string;
}

export const StepTwo = (props: StepOneProps) => {
	const { handleChangeForm, newProduct, editedProductId } = props;
	return (
		<Grid
			item
			xs={12}
			sx={{
				mt: 5,
				width: { xs: '90%', sm: '80%', md: '100%', xl: '100%' },
				margin: 'auto',
			}}>
			<Grid
				item
				xs={12}
				sx={{
					marginTop: 2,
					mt: 5,
					width: { xs: '90%', sm: '80%', md: '80%', lg: '80%  ' },
					margin: 'auto',
				}}
				alignItems={'center'}>
				<FileInput
					editedProductId={editedProductId}
					handleImages={(value) => handleChangeForm('images', value)}
					addedImages={newProduct.images!}
					renderStack={true}
				/>
			</Grid>
		</Grid>
	);
};
