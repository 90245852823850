import { Button, ButtonGroup, Grid, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IProduct } from "../../../api/interfaces/interfaces";
import CustomDialog from "../../utils/CustomDialog";
import { useState } from "react";
import { UUID } from "crypto";

interface ActionButtonsProps {
  product: IProduct;
  handleChangeStatusProduct: (productID: UUID, productStatus: string) => void;
  handleEditProduct: (productID: string) => void;
  setSelectedProduct: (product: IProduct | null) => void;
  onConfirmDialog: () => void;
}
export const ActionButtons = (props: ActionButtonsProps) => {
  const {
    product,
    handleChangeStatusProduct,
    handleEditProduct,
    onConfirmDialog,
    setSelectedProduct,
  } = props;
  const [openCloseDialog, setOpenCloseDialog] = useState<boolean>(false);

  const handleDeleteProduct = (product: IProduct) => {
    setOpenCloseDialog(!openCloseDialog);
    setSelectedProduct(product);
  };

  return (
    <Grid item xs={1} sx={{margin: {xs: '-.65rem',md: '-.5rem'}}}>
      <ButtonGroup variant="text" aria-label="Basic button group" sx={{color: 'white' , height: '1.4rem'}}>
      <Tooltip title="Ocultar producto" placement="top">
        <Button
        sx={{color: 'white', }}
          onClick={() =>
            handleChangeStatusProduct(product.id!, product.status!)
          }
        >
          {product.status === "Activo" ? (
            <VisibilityIcon />
          ) : (
            <VisibilityOffIcon />
          )}
        </Button>
        </Tooltip>
        <Tooltip title="Editar producto" placement="top">

        <Button onClick={() => handleEditProduct(product.id!)}>
          <EditIcon color="action" sx={{color: 'white'}}/>
        </Button>
        </Tooltip>
        <Tooltip title="Eliminar producto" placement="top">
        <Button onClick={() => handleDeleteProduct(product)}>
          <DeleteIcon sx={{ color: "white" }} />
        </Button>
        </Tooltip>

      </ButtonGroup>
      <CustomDialog
        handleConfirm={() => {
          onConfirmDialog();
          setOpenCloseDialog(!openCloseDialog);
        }}
        handleOpenClose={() => {
          setSelectedProduct(null);
          setOpenCloseDialog(!openCloseDialog);
        }}
        open={openCloseDialog}
        title="Confirmar"
        body="Seguro que quieres eliminar el repuesto?"
      />
    </Grid>
  );
};
