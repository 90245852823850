import { Insured, CreateInsuredData, Accident, CreateAccidentData } from "./interfaces/interfaces";
import { axiosService } from "./axiosService";



//// ASEGURADOS

export const getAllInsured = async (): Promise<Insured[]> => {
    try {
      const response = await axiosService.get(`/insured`);
      if (response.status === 200) {
        return response.data as Insured[];
      } else {
        console.warn('Unexpected response status:', response.status);
        return [];
      }
    } catch (error) {
      console.error('Error fetching insured data:', error);
      return [];
    }
  };

  export const createInsured = async (insuredData: CreateInsuredData): Promise<Insured | null> => {
    try {
      const response = await axiosService.post(`/insured/createNewInsured`, insuredData);
      if (response.status === 201) {
        return response.data as Insured;
      } else {
        console.warn('Unexpected response status:', response.status);
        return null;
      }
    } catch (error) {
      console.error('Error creating insured:', error);
      return null;
    }
  };


  export const getInsuredById = async (id: string): Promise<Insured | null> => {
    try {
      const response = await axiosService.get(`/insured/insuredById/${id}`);
      if (response.status === 200) {
        return response.data as Insured;
      } else {
        console.warn("Unexpected response status:", response.status);
        return null;
      }
    } catch (error) {
      console.error("Error fetching insured by ID:", error);
      return null;
    }
  };

  //////// ACCIDENTES

export const getAllAccidents = async (): Promise<Accident[]> => {
  try {
    const response = await axiosService.get("/accidents");
    if (response.status === 200) {
      return response.data as Accident[];
    } else {
      console.warn("Unexpected response status:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching accidents:", error);
    return [];
  }
};

export const createAccident = async (accidentData: CreateAccidentData): Promise<Accident | null> => {
  try {
    const response = await axiosService.post("/accidents/createNewAccident", accidentData);
    if (response.status === 201) {
      return response.data as Accident;
    } else {
      console.warn("Unexpected response status:", response.status);
      return null;
    }
  } catch (error) {
    console.error("Error creating accident:", error);
    return null;
  }
};

export const getAccidentById = async (id: string): Promise<Accident | null> => {
  try {
    const response = await axiosService.get(`/accidents/accidentById/${id}`);
    if (response.status === 200) {
      return response.data as Accident;
    } else {
      console.warn("Unexpected response status:", response.status);
      return null;
    }
  } catch (error) {
    console.error("Error fetching accident by ID:", error);
    return null;
  }
};

export const updateAccidentById = async (id: string, accidentData: Partial<CreateAccidentData>): Promise<Accident | null> => {
  try {
    const response = await axiosService.patch(`/accidents/updateUserById/${id}`, accidentData);
    if (response.status === 200) {
      return response.data as Accident;
    } else {
      console.warn("Unexpected response status:", response.status);
      return null;
    }
  } catch (error) {
    console.error("Error updating accident by ID:", error);
    return null;
  }
};