import { Box, Button } from '@mui/material';
import { useEffect, useState, useMemo } from 'react';
import { IImage } from '../../api/interfaces/interfaces';
import Slider from 'react-slick';
import CancelIcon from '@mui/icons-material/Cancel';
// import {useMemo} from 'react';
import { useDropzone } from 'react-dropzone';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { deleteImageById, sendImage } from '../../api/imagesApi';
const baseStyle = {
	flex: 1,
	display: 'flex',
	// flexDirection: 'column',
	alignItems: 'center',
	 padding: '5px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: 'inherit',
	color: '#bdbdbd',
	outline: 'none',
	transition: 'border .24s ease-in-out',
	cursor: 'grab'
};

const focusedStyle = {
	borderColor: '#2196f3',
};

const acceptStyle = {
	borderColor: '#00e676',
};

const rejectStyle = {
	borderColor: '#ff1744',
};
interface FileInputProps {
	handleImages: (images: IImage[]) => void;
	addedImages: IImage[];
	editedProductId?: string;
	renderStack: boolean;
}

export const FileInput = (props: FileInputProps) => {
	const { handleImages, addedImages, editedProductId, renderStack } = props;
	const [images, setImages] = useState<IImage[]>(addedImages);

	function StyledDropzone(props: any) {
		const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
			accept: { 'image/*': [] },
			onDrop: (acceptedFiles) => {
				handleFileChange(acceptedFiles);
			},
		});

		const style = useMemo(
			() => ({
				...baseStyle,
				...(isFocused ? focusedStyle : {}),
				...(isDragAccept ? acceptStyle : {}),
				...(isDragReject ? rejectStyle : {}),
			}),
			[isFocused, isDragAccept, isDragReject],
		);
		return (
			<div className="container">
				<div {...getRootProps({ style })}>
					<input {...getInputProps()} />
					<p>Arrastra aquí o selecciona tus imágenes *</p>
				</div>
			</div>
		);
	}
	// console.log(addedImages);
	const convertFileToBlob = (file: File): Promise<Blob> => {
		const blob = file.arrayBuffer().then((arrayBuffer) => new Blob([new Uint8Array(arrayBuffer)], { type: file.type }));
		return blob;
	};

	const addImages = (reader: FileReader, file: File, blob: Blob) => {
		setImages((prevFiles) => [
			...prevFiles,
			{
				name: file.name,
				mimeType: blob.type,
				imageData: reader.result?.toString()!,
			},
		]);
	};

	const handleFileChange = async (files: File[]) => {
		try {
			// Procesar cada archivo individualmente
			await Promise.all(
				files.map(async (file) => {
					const blob = await convertFileToBlob(file);

					// Crear un FileReader para cada archivo
					const reader = new FileReader();

					// Leer el archivo como datos de URL
					const imageDataPromise = new Promise<string | ArrayBuffer>((resolve, reject) => {
						reader.onloadend = () => resolve(reader.result as string | ArrayBuffer);
						reader.onerror = reject;
					});

					reader.readAsDataURL(blob);
					const imageData = await imageDataPromise;

					if (!!editedProductId) {
						// Si hay un ID de producto editado, enviar la imagen
						await sendImage({
							imageData: imageData.toString(),
							mimeType: blob.type,
							name: file.name,
							ownerID: editedProductId!,
						});
					}

					// Agregar la imagen (o sus datos) según sea necesario
					addImages(reader, file, blob);
				}),
			);

			console.log('Todas las imágenes procesadas con éxito');
		} catch (error) {
			console.error('Error al procesar las imágenes:', error);
		}
	};

	const handleRemoveImage = async (index: number) => {
		if (!!editedProductId) {
			const imageID = images[index].id;
			await deleteImageById(imageID!);
		}
		const updatedImageList = [...images];
		updatedImageList.splice(index, 1);
		handleImages(updatedImageList);
		setImages(updatedImageList);
	};

	const settings = {
		dots: true,
		infinite: false,
		speed: 500,
		// slidesToShow: 1,
		slidesToScroll: 1,
		centerMode: true, // Centra el slide activo
		centerPadding: '0', // Elimina el relleno en los lados del slide activo
		appendDots: (dots: any) => (
			<div style={{ position: 'absolute', bottom: '-12px', left: '50%', transform: 'translateX(-50%)' }}>{dots}</div>
		),
	};
	useEffect(() => {
		handleImages(images);
	}, [images]);

	return (
		<section>
			{renderStack && (
				<Slider {...settings}>
					{images?.map((imagen, index) => (
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								marginTop: '5%',
								width: { xs: '70%', md: '70%', lg: '70%' },
								height: { xs: '25vh', md: '15vh', lg: '15vw' },
								overflow: 'hidden',
								position: 'relative', // Asegura que el ícono de eliminar esté posicionado correctamente
							}}>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									width: '100%',
									height: '100%',
								}}>
								<img
									alt="preview"
									src={imagen.imageData!}
									style={{
										maxWidth: '110%', // Permite que la imagen sea hasta el doble del ancho del contenedor
										maxHeight: '110%', // Permite que la imagen sea hasta el doble de la altura del contenedor
										objectFit: 'cover', // Mantén la relación de aspecto de la imagen
										borderRadius: '14px',
									}}
								/>
							</div>
							<span
								style={{
									position: 'absolute',
									top: '0',
									right: '0',
									cursor: 'pointer',
									padding: '0px',
								}}
								onClick={() => handleRemoveImage(index)}>
								<CancelIcon />
							</span>
						</Box>

						// 	</div>
						// </div>
					))}
				</Slider>
			)}
			<Box
				sx={{
					width: '100%',
					height: 'auto',
					// border: "solid",
					// borderRadius: "3px",
					// borderWidth: "2px",
				}}
				style={{ marginTop: '5%' }}
				// onDrop={({ dataTransfer: { files } }) => handleFileChange(files)}
				// onDragOver={(event) => event.preventDefault()}
			>
				{/* <input
    type="file"
    accept=".png, .jpeg, .jpg"
    lang="es"
    multiple
    hidden={true}
    onChange={({ currentTarget: { files } }) => handleFileChange(files!)}
  />
  <button onClick={() => document.querySelector('input[type=file]')?.click()}>Seleccionar archivos</button> */}
				<StyledDropzone />
			</Box>
		</section>
	);
};
