import { AppTheme } from "./theme/AppTheme";
import { Toolbar } from "@mui/material";
import "./index.css";
import RouterRoutes from "./router";
import UserProvider from "./provider/UserProvider";
import GlobalProvider from "./provider/GlobalProvider";

const App = () => {
  return (
    <AppTheme>
      <UserProvider>
        <GlobalProvider>
        <Toolbar />
        <RouterRoutes />
        </GlobalProvider>
      </UserProvider>
    </AppTheme>
  );
};

export default App;
