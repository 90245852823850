import { Breadcrumbs, Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getCategoryTreeByLeaf } from "../../../api/categoriesApi";
import { useEffect, useState } from "react";
import { ICategory } from "../../../api/interfaces/interfaces";
import { Box } from "@mui/system";

interface CategoryPathProps {
  leafId: string;
}

export const CategoriesPath = (props: CategoryPathProps) => {
  const { leafId } = props;
  const [categoryTree, setCategoryTree] = useState<ICategory[]>([]);

  const loadTree = async () => {
    const categories = await getCategoryTreeByLeaf(leafId);
    setCategoryTree(categories);
  };

  useEffect(() => {
    leafId && loadTree();
  }, [leafId]);

  return (
    <Grid>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ marginLeft: "15px", padding: 0, marginRight: 0 }}
      >
        {categoryTree.map((category, index) => (
          <Box display={"flex"} key={index + "box"}>
            <Link color="inherit" to="/" key={index + "link"}>
              <Typography align="center">
                <Button
                  variant={"contained"}
                  size="small"
                  sx={{
                    fontSize: "10px",
                    color: "white",
                    margin: "auto",
                    backgroundColor: '#111',
                    padding: 0,
                  }}
                  key={index + "btn"}
                >
                  {category.name}
                </Button>
              </Typography>
            </Link>
            {index !== categoryTree.length - 1 && (
              <ArrowForwardIosIcon
                sx={{
                  color: "white",
                  fontSize: "15px",
                  marginTop: "5px",
                  marginLeft: "10px",
                }}
                key={index + "icon"}
              />
            )}
          </Box>
        ))}
      </Breadcrumbs>
    </Grid>
  );
};
