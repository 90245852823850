import { Navigate, Route } from 'react-router-dom';
import { UserContext } from '../provider/UserContext';
import { useContext } from 'react';
import NavBar from '../components/navbar/NavBar';

interface PrivateRouteProps {
	element: JSX.Element;
}
export const PublicRoutes = (props: PrivateRouteProps) => {
	const { element } = props;
	const { token } = useContext(UserContext);

	return token ? <Navigate to="/login" replace /> : [<NavBar />, element];
};
