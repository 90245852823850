import { Box, Card, DialogContent, DialogContentText } from '@mui/material';
import mecanicpw from '../../../../../images/mecanicpw.png';

export const PasswordChangeAceptedDialog = () => {
  return (
    <Card sx={{ backgroundColor: '#111111', height: '15h', border: '1px inset #B71C1C' }}>
						<DialogContent>
							<DialogContentText sx={{ color: 'white', whiteSpace: 'nowrap', fontSize: { xs: '.8rem', lg: '1rem' } }}>
								Tu contraseña ha sido modificada correctamente
							</DialogContentText>
							<Box
								component="img"
								src={mecanicpw}
								alt="tu_imagen"
								sx={{
									margin: 'auto',
									marginTop: '1rem',
									width: '6rem',
									height: '5rem',
									display: 'flex',
								}}></Box>
						</DialogContent>
					</Card>
  )
}
