import { Card, CardContent, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <Card
      sx={{
        marginTop: "2rem",
        backgroundColor: "#111",
        color: "#fff",
        textAlign: "center",
        boxShadow: "0px -4px 10px rgba(0, 0, 0, 0.1)",
        maxHeight: "auto",
        borderTop: '1px solid white'
      }}
    >
      <CardContent>
        <Typography fontSize="20px" gutterBottom sx={{ marginTop: "-.7rem" }}>
          Encuentra los mejores repuestos para tu auto al mejor precio en RepuestARG <b style={{fontSize:'1rem'}}>®</b>
        </Typography>
        <Typography variant="body2" sx={{ marginTop: "-5px", marginBottom: '-.7rem' }}>
          ¿Necesitas ayuda?{" "}
          <Link to={""} style={{ textDecoration: "none", color: "white" }}>
            Contáctanos
          </Link>
        </Typography>
      </CardContent>
    </Card>
  );
};
