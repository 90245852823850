import { Avatar, Card, Grid, Typography, CardContent } from '@mui/material';
import { IRecivedAnsers } from '../../api/interfaces/interfaces';
interface CardContentComponentProps {
	answer: IRecivedAnsers;
}

export const AnswerContentComponent = (props: CardContentComponentProps) => {
	const { answer } = props;
	return (
		<Card style={{ padding: 2, margin: 2, backgroundColor: '#1c1c1c' }}>
			<CardContent>
				<Grid container spacing={2} alignItems="center">
					<Grid item>
						<Avatar alt="Foto de Usuario" />
					</Grid>
					<Grid item xs>
						<Typography variant="subtitle1">{answer?.whoAnser}</Typography>
						<Typography variant="body2" color="text.secondary">
							2:30 PM
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body1">Ha respondido a tu pregunta en </Typography>
						<Typography variant="body1">{answer?.productName}</Typography>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};
