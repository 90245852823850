import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Box, Card, CardActionArea, CardActions, CardMedia, Grid, Hidden, Modal, useMediaQuery } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { IImage } from '../../../api/interfaces/interfaces';

interface ProductImages {
	images: IImage[];
	selectedImage: number;
	handleImageClick: (index: number) => void;
}

export default function ProductImageCarousel(props: ProductImages) {
	const { images, selectedImage, handleImageClick } = props;
	const [values, setValues] = useState<number[]>([]);
	//modal
	const [modalOpen, setModalOpen] = useState(false);
	const isMobile = useMediaQuery('(max-width:1000px)');
	const handleModalClose = () => setModalOpen(false);
	//
	const handleNext = useCallback(() => {
		if (selectedImage < images?.length - 1) handleImageClick(selectedImage + 1);
		else handleImageClick(0);
	}, [handleImageClick, images?.length, selectedImage]);

	const handleBack = useCallback(() => {
		if (0 < selectedImage) handleImageClick(selectedImage - 1);
		else handleImageClick(images?.length - 1);
	}, [handleImageClick, images?.length, selectedImage]);

	useEffect(() => {
		function renderPaginateNumberDinamic(indice: number, cantidad: number) {
			const arrayNumericoAscendente = Array.from({ length: images.length }, (_, index) => index);
			const arrayNumericoDescendente = Array.from({ length: images.length }, (_, index) => images.length - index - 1);

			const diferencia = Math.floor(cantidad / 2);

			//calculo las posiciones de mi indice en mi array
			const indiceArrayAscendente = arrayNumericoAscendente.indexOf(indice);
			const indiceArrayDescendente = arrayNumericoDescendente.indexOf(indice);

			let contador = indice === 0 ? -1 : 0;
			let indiceSuperior = indiceArrayAscendente;
			const conjuntoNumerico = new Set<number>();

			while (contador < diferencia && indiceSuperior < arrayNumericoAscendente.length) {
				conjuntoNumerico.add(arrayNumericoAscendente[indiceSuperior]);
				contador++;
				indiceSuperior++;
			}

			contador = indice === images.length - 1 ? -1 : 0;
			let indiceInferior = indiceArrayDescendente;
			while (contador < diferencia && indiceInferior < arrayNumericoDescendente.length) {
				conjuntoNumerico.add(arrayNumericoDescendente[indiceInferior]);
				contador++;
				indiceInferior++;
			}
			return [...conjuntoNumerico].sort((a, b) => a - b);
		}
		setValues(renderPaginateNumberDinamic(selectedImage, 4));
	}, [images?.length, selectedImage]);



	return (
		<>
			<Card
				sx={{
					height: 'min-content',
					lineHeight: 0,
					backgroundColor: '#111',
					boxShadow: 'none'
				}}>
				<CardActionArea onClick={() => setModalOpen(true)}>
					<CardMedia
						style={{
							width: '100%',
							minHeight: '500px',
							height: '500px',
							maxHeight: '500px',
							objectFit: 'contain',
							alignSelf: 'center',
							lineHeight: 0,
							backgroundColor: '#111',
						}}
						src={images[selectedImage]?.imageData!}
						alt={images[selectedImage]?.name!}
						component="img"
					/>
				</CardActionArea>

				{images.length > 1 && 
				<CardActions sx={{ lineHeight: 0, padding: 0, boxShadow: 'none !omportant', backgroundColor: '#111 !important' }} >
					<Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ margin: 'auto',backgroundColor: '#111', mt: 1, width: {xs:'80%',sm: '100%',md:'100%'} }}>
						<Grid item xs sx={{ backgroundColor: '#111' }}>
							<Button
								sx={{ color: 'white', padding: 0 }}
								size="small"
								onClick={() => {
									handleBack();
								}}
								disabled={values.length === 1}>
								<KeyboardArrowLeft />
								<Hidden smDown>{' Anterior'}</Hidden>
							</Button>
						</Grid>
						{values?.map((value, index) => {
							return (
								<Grid item xs key={index}>
									<Button
										sx={{
											color: 'white',
											border: selectedImage === value ? 'thin solid #a31544' : 'solid transparent',
											cursor: 'pointer',
											padding: 0,
										}}
										size="small"
										onClick={() => {
											handleImageClick(value);
										}}>
										{value + 1}
									</Button>
								</Grid>
							);
						})}
						<Grid item xs>
							<Button
								size="small"
								onClick={() => {
									handleNext();
								}}
								sx={{ color: 'white', padding: 0 }}
								disabled={values.length === 1}>
								<Hidden smDown>{'Siguiente '}</Hidden>
								<KeyboardArrowRight />
							</Button>
						</Grid>
					</Grid>
				</CardActions>
				}
			</Card>

			{!isMobile &&
				<Modal
					open={modalOpen}
					onClose={handleModalClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
					closeAfterTransition

				>
					<Box
						sx={{
							position: 'absolute' as 'absolute',
							top: { xs: '35%', sm: '22.5%' },
							left: '50%',
							transform: 'translate(-50%, -50%)',
							maxWidth: '50%',
							maxHeight: '30%',
							boxShadow: 24,
							p: { xs: 0, sm: 4 }
						}}
					>
						<Grid container alignItems="center" justifyContent="center" sx={{ position: 'relative' }}>
							<Button
								sx={{
									position: 'absolute',
									left: -50,
									color: '#E0E0E0',
									padding: 0,
									minWidth: 'auto',
									backgroundColor: 'transparent',
									'&:hover': {
										backgroundColor: 'transparent'
									}
								}}
								onClick={handleBack}
								disabled={values.length === 1}>
								<KeyboardArrowLeft sx={{ fontSize: '3rem' }} />
							</Button>
							<CardMedia
								component="img"
								src={images[selectedImage]?.imageData!}
								alt={images[selectedImage]?.name!}
								sx={{
									width: { xs: '100%', sm: '100%' },
									height: { xs: '30vh', sm: 'auto' },
									borderRadius: '4px'
								}}
							/>
							<Button
								sx={{
									position: 'absolute',
									right: -50,
									color: '#E0E0E0',
									padding: 0,
									minWidth: 'auto',
									backgroundColor: 'transparent',
									'&:hover': {
										backgroundColor: 'transparent'
									}
								}}
								onClick={handleNext}
								disabled={values.length === 1}>
								<KeyboardArrowRight sx={{ fontSize: '3rem' }} />
							</Button>

						</Grid>
					</Box>
				</Modal>
			}
		</>
	);
}
