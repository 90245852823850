import { Alert, Card, Snackbar, ThemeProvider, Typography, createTheme } from '@mui/material';
import { sendComment, getQuestionsWithResponses } from '../../../api/commentsApi';
import { useCallback, useEffect, useState } from 'react';
import { IProductComment } from '../../../api/interfaces/interfaces';
import { CommentForm } from '../comments/CommentForm';
import { UUID } from 'crypto';
import { CommentPagination } from '../comments/commentPagination';
import { CommentDetail } from '../comments/commentDetail';

interface QuestionProps {
	productID: UUID;
	isOwner: boolean;
	commentID?: string;
}

export const QuestionAnswer = (props: QuestionProps) => {
	const { productID, isOwner, commentID = '' } = props;
	const [questions, setQuestions] = useState<IProductComment>();
	const [comment, setComment] = useState<string>('');
	const [isLoadingComment, setIsLoadingComment] = useState<boolean>(false);
	const [filterID, setFilterID] = useState<string>(commentID);
	const [selectedPage, setSelectedPage] = useState<number>(1);
	// pop up
	const [notification, setNotifation] = useState(false);
	const closePopUp = () => {
		setNotifation(false)
	};
		const theme = createTheme({
			palette: {
			  success: {
				main: '#66BB6A', // Color principal personalizado para success
				contrastText: '#fff', // Color del texto en el botón de éxito
			  },
			},
		  });
	//

	const handleSendQuestion = async () => {
		setIsLoadingComment(true);
		await sendComment(productID, comment);
		getQuestions();
		setComment('');
		setFilterID('');
		setIsLoadingComment(false);
		setNotifation(true);
	};

	const getQuestions = async () => {
		const preguntas = await getQuestionsWithResponses(productID, 10, selectedPage);
		const comentariosFiltrados = preguntas.comments.filter((comment) => filterID === '' || comment.id === filterID);
		const preguntasActualizadas: IProductComment = {
			comments: comentariosFiltrados,
			cantPages: preguntas.cantPages,
		};
		setQuestions(preguntasActualizadas);
	};

	useEffect(() => {
		getQuestions();
	}, [selectedPage, filterID]);

	const handleInputChange = (value: string) => {
		if (comment.length <= 250) {
			setComment(value);
		}
	};

	const handleOnResponsePost = useCallback(() => {
		getQuestions();
	}, []);

	// const pages = Array.from({ length: questions?.cantPages! }, (_, index) => index + 1);

	const handlePageClick = (index: number) => {
		setSelectedPage(index);
	};

	const handleNext = useCallback(() => {
		if (selectedPage < questions?.cantPages!) handlePageClick(selectedPage + 1);
	}, [questions?.cantPages, selectedPage]);

	const handleBack = useCallback(() => {
		if (selectedPage > 1) handlePageClick(selectedPage - 1);
	}, [selectedPage]);



	
	return (
		<Card
			key={'aa'}
			sx={{
				marginTop: '.8rem',
				backgroundColor: '#111',
				justifyContent: 'center',
			}}>
			<Card
				key={'ab'}
				sx={{
					width: '65%',
					height: 'auto',
					margin: 'auto',
					backgroundColor: '#111',
					boxShadow: 'none',
				}}>
				{!isOwner && (
					<>
						<CommentForm
							value={comment}
							isLoading={isLoadingComment}
							handleInputChange={handleInputChange}
							handleSendValue={handleSendQuestion}
							title="Escribe una pregunta al vendedor"
							descriptionButton="Enviar"
							placeholder="Escribí tu pregunta..."
						/>
					</>
				)}
			</Card>
			{questions && questions?.comments?.length > 0 && <Typography color="#f1f1f1" textAlign="initial" fontSize="17.5px" fontWeight='bold' marginTop='1rem' marginLeft='.5rem'>
				Preguntas sobre este producto
			</Typography>
			}{questions?.comments &&
				questions?.comments.map((item, index) => (
					<CommentDetail index={index} comment={item} isOwner={isOwner} handleOnResponsePost={handleOnResponsePost} />
				))}
			{questions && questions?.cantPages > 1 &&
				<CommentPagination
					totalPages={questions?.cantPages!}
					handleBack={handleBack}
					handleNext={handleNext}
					handlePageClick={handlePageClick}
					selectedPage={selectedPage}
				/>
			}
			<ThemeProvider theme={theme}>
			<Snackbar
				open={notification}
				autoHideDuration={4000}
				onClose={closePopUp}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				>
				<Alert
					onClose={closePopUp}
					severity="success"
					variant="filled"
					sx={{ width: {xs:'80%',sm:'100%'}, mt:'2.8rem' }}
				>
					Pregunta enviada exitosamente
				</Alert>
				</Snackbar>
				</ThemeProvider>
		</Card>
	);
};
