import { Box, Card, CardMedia, Stack } from "@mui/material";
import { IImage } from "../../../api/interfaces/interfaces";

interface ImageStackInterface {
  images: IImage[];
  selectedImage: number;
  handleImageClick: (index: number) => void;
}

export const ImageStack = (props: ImageStackInterface) => {
  const { images, selectedImage, handleImageClick } = props;

  return (
    <Box sx={{height: '100%'}}>
      <Stack
        direction="column"
        justifyContent="start"
        alignItems="center"
        spacing={2}
        padding={1}
        sx={{
          backgroundColor: "#111111",
          borderRadius: "3px",
          height: "100%",
          // minHeight: "88vh",
          maxHeight: "528px",
          paddingRight:'2rem',
        }}
      >
        {images?.map((image, index) => (
          <>
            {index < 5 && (
              <Card
                key={index}
                onClick={() => handleImageClick(index)}
                sx={{
                  border:
                    index === selectedImage
                      ? "2px solid #a31545"
                      : "2px solid transparent",
                  cursor: "pointer",
                  position: "relative",
                }}
              >
                {index === 4 && images.length > 4 && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      color: "white",
                      fontSize: "1.5rem",
                    }}
                  >
                    +{images.length - index}
                  </div>
                )}
                <CardMedia
                  component="img"
                  style={{
                    minWidth: "5vw",
                    maxWidth: "5vw",
                    minHeight: "5vw",
                    maxHeight: "5vw",
                  }}
                  src={image?.imageData!}
                  alt={`Image ${index + 1}`}
                />
              </Card>
            )}
          </>
        ))}
      </Stack>
    </Box>
  );
};
