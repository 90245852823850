import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { mainTheme } from "./mainTheme";

interface AppThemeProps {
  children: React.ReactNode;
}

export const AppTheme: React.FC<AppThemeProps> = ({ children }) => {
  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
