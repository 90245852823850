import { Visibility, VisibilityOff } from '@mui/icons-material';
import { login } from './../../api/authApi';
import {
	Button,
	Grid,
	TextField,
	Typography,
	Box,
	Card,
	CircularProgress,
	FormControl,
	InputLabel,
	OutlinedInput,
	InputAdornment,
	IconButton,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { Link, useNavigate} from 'react-router-dom';
import { UseUserContext } from '../../provider/UserContext';
import { ILoginUser } from '../../api/interfaces/interfaces';

export default function LoginPage() {
	const userContext = UseUserContext();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [errorLogin, setErrorLogin] = useState<boolean>(false);
	const [errorLogin401, setErrorLogin401] = useState<boolean>(false);
	const [showPassword, setShowPassword] = useState(false);

	const [user, setUser] = useState<ILoginUser>({
		username: '',
		password: '',
	});

	const validateUsername = (username: string) => {
		if (username === '') {
			return false;
		}
		const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return regexEmail.test(username) ? false : true;
	};

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	const keyPress = (e: any) => {
		if (e.keyCode === 13) sendLogin();
	};

	const handleChangeForm = useCallback((fieldName: keyof ILoginUser, value: string) => {
		setUser((prevUser) => ({
			...prevUser,
			[fieldName]: value,
		}));
	}, []);

	const sendLogin = async () => {
		setIsLoading(true);
		setErrorLogin(false);
		setErrorLogin401(false);
		const { status } = await login(user, userContext);
		setIsLoading(false);
		status === 200 ? navigate('/') : status === 401 ? setErrorLogin401(true) : setErrorLogin(true);
	};

	return (
		<Grid
			container
			spacing={1}
			direction="column"
			alignItems="center"
			justifyContent="center"
			sx={{ minHeight: { xs: '95vh', md: '92vh' }, backgroundColor: '#111111' }}>
			<Card
				sx={{
					width: { xs: '90%', sm: '80%', md: '40%' },
					margin: { sm: 'auto' },
					mr: { xs: '-2%' },
					textAlign: 'center',
					height: 'auto',
					borderRadius: '22px',
					border: '1px inset #F44336',
					backgroundColor: '#111111',
				}}>
				<Grid item xs={12}>
					<Typography
						sx={{
							marginTop: '5%',
							fontSize: '2rem',
							color: '#FFF4F4',
						}}>
						Inicia sesión
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					sx={{
						marginTop: 2,
						marginBottom: 10,
						width: { xs: '90%', sm: '80%', md: '50%' },
						margin: 'auto',
					}}>
					<TextField
						label="Correo Electrónico"
						type="email"
						placeholder="correoelectronico@gmail.com"
						fullWidth
						name="email"
						value={user.username}
						onChange={({ currentTarget: { value } }) => handleChangeForm('username', value)}
						error={validateUsername(user.username)}
						InputLabelProps={{
							style: {
								color: 'white', // Color del texto del placeholder
								opacity: 0.6, // Opacidad del placeholder
							},
						}}
						sx={{
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor: 'white', // Bordes blancos
								},
								'&:hover fieldset': {
									borderColor: 'white', // Bordes blancos cuando se pasa el cursor
								},
								'&.Mui-focused fieldset': {
									borderColor: '#3F51B5', // Bordes azules cuando está enfocado
								},
								backgroundColor: '#181818', // Fondo negro
								color: 'white', // Texto blanco
							},
							marginTop: '5%',
							'& input:-webkit-autofill, & textarea:-webkit-autofill, & select:-webkit-autofill': {
								// al activarse el autocomplete (auto-fill)
								WebkitTextFillColor: 'white', // font color
								transition: 'background-color 5000s ease-in-out 0s', // background color
							},
						}}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					sx={{
						mt: 5,
						width: { xs: '90%', sm: '80%', md: '50%' },
						margin: 'auto',
					}}>
					<FormControl
						sx={{
							borderRadius: '5px',
							backgroundColor: '#eef3f8',
							border: '1px solid white', // Borde predeterminado
							'&:focus-within': {
								borderColor: '#3F51B5', // Borde azul cuando está enfocado
							},
							marginTop: '5%',
							width: '100%',
							minWidth: '100%',
							maxWidth: '100%',
						}}
						variant="outlined">
						<InputLabel
							sx={{
								color: 'white', // Color del texto del label
								opacity: 0.6,
								'&.Mui-focused': {
									color: 'white',
									opacity: 0.6,
									backgroundColor: '#111111',
								},
							}}>
							Contraseña
						</InputLabel>
						<OutlinedInput
							placeholder="Contraseña"
							sx={{
								backgroundColor: '#181818',
								'& input': {
									color: 'white', // Color del texto
								},
							}}
							type={showPassword ? 'text' : 'password'}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										sx={{ color: 'white', opacity: 0.6 }}
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge="end">
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}
							label="Password"
							onKeyDown={(e) => keyPress(e)}
							onChange={({ currentTarget: { value } }) => handleChangeForm('password', value)}
						/>
					</FormControl>
				</Grid>
				<Grid container item justifyContent="center" sx={{ mt: 2 }}>
					<Box sx={{ margin: 'auto' }}>
						<Typography sx={{ mb: '5px', mr: '5px', color: '#FFEBEE' }}>
							¿Olvidaste tu contraseña?
							<Link to="/forgotpw" color="inherit" style={{ marginLeft: '10px', color: '#E57373' }}>
								Recupérala aquí.
							</Link>
						</Typography>
					</Box>
				</Grid>
				<Grid container item spacing={2} justifyContent="space-between" sx={{ mt: 2 }}>
					<Grid item xs={8} sm={6} sx={{ margin: 'auto', mt: '-2%' }}>
						<Button
							sx={{ width: '80%', backgroundColor: '#181818', border: '1px inset #E57373', borderRadius: '1rem' }}
							type="submit"
							variant="contained"
							onClick={sendLogin}
							disabled={isLoading}>
							{isLoading ? <CircularProgress size={24} color="inherit" /> : 'Ingresar'}
						</Button>
						{errorLogin && <Typography color="red">Error al iniciar sesión, intentelo de nuevo.</Typography>}
						{errorLogin401 && (
							<Typography marginTop=".3rem" color="#E57373">
								Error: Usuario o contraseña incorrectos.
							</Typography>
						)}
					</Grid>
				</Grid>
				<Grid container item justifyContent="center" sx={{ mb: 3 }}>
					<Box sx={{ margin: 'auto', mt: '.7rem' }}>
						<Typography sx={{ mb: '5px', color: '#FFEBEE' }}>¿No tienes una? ¡Registrate!</Typography>
						<Link to="/register" color="inherit" style={{ color: '#E57373' }}>
							Crear una cuenta
						</Link>
					</Box>
				</Grid>
				<Grid container item justifyContent="center" sx={{ mt: 3 }}></Grid>
			</Card>
		</Grid>
	);
}
