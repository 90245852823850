import { Card, Grid, Typography } from "@mui/material";

interface ProductDescriptionProps {
  text: string;
}

export const ProductDescription = (props: ProductDescriptionProps) => {
  const { text } = props;
  return (
    <Card
      sx={{
        marginTop: "5px",
        borderRadius: "3px",
        maxHeight: "fit-content",
        justifyContent: "center",
        padding: "5px",
        backgroundColor: "#111",
        boxShadow: 'none'
      }}
    >
      <Grid
        item
        display="grid"
        alignContent="center"
        sx={{ maxHeight: "auto", minHeight: "auto", height: "auto" }}
      >
        <Typography color="#f1f1f1" textAlign="initial" fontSize="25px" fontWeight='bold'>
          Descripción del producto
        </Typography>
        <Typography
          textAlign="initial"
          color="#f1f1f1"
          fontFamily="Roboto"
          fontSize="90%"
          marginTop={1}
        >
          {text}
        </Typography>
      </Grid>
    </Card>
  );
};
