import { Avatar, Card, Grid, Typography, CardContent } from '@mui/material';
import { IRecivedAsks } from '../../api/interfaces/interfaces';
interface CardContentComponentProps {
	ask?: IRecivedAsks;
}

export const AskContentComponent = (props: CardContentComponentProps) => {
	const { ask } = props;
	return (
		<Card style={{ padding: 2, margin: 2, backgroundColor: '#1c1c1c' }}>
			<CardContent>
				<Grid container spacing={2} alignItems="center">
					<Grid item>
						<Avatar alt="Foto de Usuario" />
					</Grid>
					<Grid item xs>
						<Typography variant="subtitle1">{ask?.whoAsk}</Typography>
						<Typography variant="body2" color="white">
							2:30 PM
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body1">Te ha preguntado en tu publicación</Typography>
						<Typography variant="body1">{ask?.productName}</Typography>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};
