import { Card, Grid, Typography, Button } from '@mui/material';
import { VerticalStepper } from './stepper/stepper';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { INewProduct } from '../../../api/interfaces/interfaces';
import { UUID } from 'crypto';
import { useNavigate } from 'react-router-dom';
import CancelIcon from '@mui/icons-material/Cancel';

interface NewProductProps {
	setShowComponent?: (value: boolean) => void;
	editedProduct?: INewProduct;
	editedProductId?: UUID;
}

export const NewProduct = (props: NewProductProps) => {
	const { editedProduct, editedProductId, setShowComponent } = props;
	const navigate = useNavigate();

	const handleSendForm = () => {
		if (editedProductId) setShowComponent!(false);
		else navigate('/');
	};

	return (
		<Grid
			container
			spacing={1}
			direction="column"
			alignItems="center"
			justifyContent="center"
			sx={{ minHeight: { xs: '95vh', md: '92vh' }, backgroundColor: '#111111' }}>
			<Card
				sx={{
					width: { xs: '90%', sm: '80%', md: '50%' },
					margin: { sm: 'auto' },
					mr: { xs: '-2%' },
					textAlign: 'center',
					height: '80vh',
					borderRadius: '1.5%',
					overflow: 'auto',
					'&::-webkit-scrollbar': {
						width: '12px',
					},
					'&::-webkit-scrollbar-track': {
						backgroundColor: '#ffffff',
					},
					'&::-webkit-scrollbar-thumb': {
						backgroundColor: '#111111',
						borderRadius: 0
					},
					'&::-webkit-scrollbar-thumb:hover': {
						backgroundColor: '#555',
					},
				}}>
				{editedProductId && (
					<Button
						sx={{
							marginTop: 1.3,
							backgroundColor: 'white',
							width: '10%',
							right: '44%',
							borderRadius: '25px',
							borderColor: 'thin',
						}}
						type="submit"
						variant="outlined"
						fullWidth
						onClick={() => setShowComponent!(false)}>
						<ArrowBackIcon color="action" />
					</Button>
				)}
				<Grid item xs={12}>
					<div style={{ display: 'flex', width: '100%', marginTop: '.5rem' }}>
						<Typography
							sx={{
								flexGrow: 1, // con flexgrow, el typography ocupa todo el tamaño, por ende el boton ocupa solo su tamaño natural
								fontSize: { xs: '1.7rem', sm: '2rem' },
								fontFamily: 'Roboto',
								color: '#333',
							}}>
							{!!editedProduct ? 'Editar publicación' : 'Crear nueva publicación'}
						</Typography>
						<Button
							size="small"
							variant="contained"
							sx={{
								padding: 0,
								minWidth: 'auto',
								minHeight: 'auto',
								width: '32px',
								height: '32px',
								mr: '.2rem'
							}}
							onClick={() => navigate(`/`)}>
							<CancelIcon />
						</Button>
					</div>

					<VerticalStepper
						editedProductId={editedProductId}
						editedProduct={editedProduct}
						handleSendForm={handleSendForm}
					/>
				</Grid>
			</Card>
		</Grid>
	);
};
