import axios from "axios";
import { ICountries, INotification } from "./interfaces/interfaces";
import { axiosService } from "./axiosService";
//Documentacion de API => https://restcountries.com/#endpoints-all
export const getAllCountries = async (): Promise<ICountries[]> => {
  const data = await axios
    .get(`https://restcountries.com/v3.1/all?fields=name,flags,translations`)
    .then(
      (response) => {
        const { data, status } = response;
        if (status === 200) return data;
        return [];
      },
      (err) => {
        // console.error("Error: " + err);
        return [];
      }
    );
  return data;
};

export const getUserNotifications = async (): Promise<INotification> => {
  const data = await axiosService
    .get(`/utils/userNotifications/`)
    .then(
      (response) => {
        const { data, status } = response;
        if (status === 200) return data;
        return {};
      },
      (err) => {
        // console.error("Error: " + err);
        return {};
      }
    );
  return data;
};

export const setNotificationReaded = async (commentID: string): Promise<any> => {
  const data = await axiosService
    .post(`/utils/notificationReaded`, {commentID})
    .then(
      (response) => {
        const { data, status } = response;
        if (status === 200) return data;
        return {};
      },
      (err) => {
        // console.error("Error: " + err);
        return {};
      }
    );
  return data;
};