import {
	Box,
	Button,
	Card,
	Chip,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	IconButton,
	InputAdornment,
	TextField,
	Typography,
	useMediaQuery,
} from '@mui/material';
import { useState } from 'react';
import { IAccountInformation } from '../../../../api/interfaces/interfaces';
import { deleteAccount, updateAccountInformation, updateUserPassword } from '../../../../api/userApi';
import deleteaccount from '../../../../images/deleteaccount.png';
import { UseUserContext } from '../../../../provider/UserContext';
import { useNavigate } from 'react-router-dom';
import { ChangePasswordDialogComponent } from './ChangePassword/ChangePasswordDialogComponent';
import { ChangeEmailDialogComponent } from './ChangeEmail/ChangeEmailDialogComponent';
import CloseIcon from '@mui/icons-material/Close';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface AccountTabProps {
	accountInfo: IAccountInformation;
	setAccountInformation: (value: IAccountInformation) => void;
}
export const PrivacityConfigurationTabPanel = (props: AccountTabProps) => {
	const { accountInfo, setAccountInformation } = props;
	const [showDialogPassword, setShowDialogPassword] = useState(false);
	const [showDialogEmail, setShowDialogEmail] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [showSaved, setShowSaved] = useState(false);
	const [loading, setLoading] = useState(false);

	const { logOut } = UseUserContext();
	const navigate = useNavigate();
	const handleOpenCloseDialogPassword = () => {
		setShowDialogPassword(!showDialogPassword);
	};
	const handleOpenCloseDialogEmail = () => {
		setShowDialogEmail(!showDialogEmail);
	};
	const handleOpenEliminateDialog = () => {
		setOpenDialog(true);
	};

	const handleCloseEliminateDialog = () => {
		setOpenDialog(false);
	};

	const handleDeleteAccount = async () => {
		setOpenDialog(false);
		await deleteAccount();
		logOut();
		navigate('/login');
	};

	const handleOpenCloseSaved = () => {
		setShowSaved(!showSaved);
	};

	const onSave = async () => {
		setLoading(true);
		const { status } = await updateAccountInformation(accountInfo);
		status === 200 ? handleOpenCloseSaved() : handleOpenCloseSaved();
		setLoading(false);
	};
	const isMobile = useMediaQuery('(max-width:600px)');

	const [showPassword, setShowPassword] = useState(false);
	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();
	};
	const handleClickShowPassword = () => setShowPassword((show) => !show);

	return (
		<Grid width="100%">
			<Card
				sx={{
					backgroundColor: '#111111',
					width: { md: '100%' },
					margin: 'auto',
					boxShadow: 'none',
				}}>
				<Typography
					variant="h5"
					gutterBottom
					sx={{
						color: 'white',
						fontFamily: 'Roboto',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						display: 'flex',
						justifyContent: 'center',
					}}>
					Ajustes de privacidad
				</Typography>
			</Card>
			<Card
				sx={{
					height: '40vh',
					backgroundColor: '#111111',
					display: 'flex',
					flexDirection: 'column',
					boxShadow: 'none',
				}}>
				<TextField
					InputProps={{
						endAdornment: (
							<IconButton onClick={handleOpenCloseDialogEmail} edge="end">
								<Chip sx={{ backgroundColor: '#ef2243', color: 'white' }} label="Editar" size="small" />
							</IconButton>
						),
					}}
					name="Email"
					label="Email"
					value={accountInfo?.email}
					sx={{
						marginTop: '3rem',
						width: { xs: '80%', md: '50%' },
						marginLeft: '5%',
						borderTop: 'none',
						'& .MuiOutlinedInput-root': {
							'& fieldset': {
								borderColor: 'white', // Bordes blancos
							},
							'&:hover fieldset': {
								borderColor: 'white', // Bordes blancos cuando se pasa el cursor
							},
							'&.Mui-focused fieldset': {
								borderColor: '#3F51B5', // Bordes azules cuando está enfocado
							},
							backgroundColor: '#181818', // Fondo negro
							color: 'white', // Texto blanco
						},
					}}
					InputLabelProps={{
						sx: {
							color: 'white', // Color del texto del placeholder
							opacity: 0.6, // Opacidad del placeholder
							'&.Mui-focused': {
								color: 'white',
							},
						},
					}}
					size="small"
				/>
				<TextField
					InputProps={{
						endAdornment: (<>
							<InputAdornment position="end">
								<IconButton
									sx={{ color: 'white', opacity: 0.6 }}
									onClick={handleClickShowPassword}
									onMouseDown={handleMouseDownPassword}
									edge="end"
								>
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
							<IconButton onClick={handleOpenCloseDialogPassword} edge="end">
								<Chip sx={{ backgroundColor: '#ef2243', color: 'white' }} label="Editar" size="small" />
							</IconButton>
						</>)
					}}
					name="Contraseña"
					label="Contraseña"
					type={showPassword ? "text" : "password"}

					sx={{
						marginTop: '1.5rem',
						width: { xs: '80%', md: '50%' },
						marginLeft: '5%',
						borderTop: 'none',
						'& .MuiOutlinedInput-root': {
							'& fieldset': {
								borderColor: 'white', // Bordes blancos
							},
							'&:hover fieldset': {
								borderColor: 'white', // Bordes blancos cuando se pasa el cursor
							},
							'&.Mui-focused fieldset': {
								borderColor: '#3F51B5', // Bordes azules cuando está enfocado
							},
							backgroundColor: '#181818', // Fondo negro
							color: 'white', // Texto blanco
						},
					}}
					InputLabelProps={{
						sx: {
							color: 'white', // Color del texto del placeholder
							opacity: 0.6, // Opacidad del placeholder
							'&.Mui-focused': {
								color: 'white',
							},
						},
					}}
					size="small"
				/>

				<Dialog open={showDialogPassword} onClose={handleOpenCloseDialogPassword}>
					<Card sx={{ backgroundColor: '#111111', width: { xs: 'auto', md: '25vw' }, height: '55vh', border: '1px inset #B71C1C' }}>
						{isMobile && (
							<Button
								onClick={handleOpenCloseDialogPassword}
								sx={{ backgroundColor: '#131313', padding: '.2rem', marginLeft: '-.8rem' }}>
								<CloseIcon sx={{ color: 'white', fontSize: '2rem' }} />
							</Button>
						)}
						<ChangePasswordDialogComponent accountInfo={accountInfo} />
					</Card>
				</Dialog>

				<Dialog open={showDialogEmail} onClose={handleOpenCloseDialogEmail}>
					<Card sx={{ backgroundColor: '#111111', height: '45vh', border: '1px inset #B71C1C' }}>
						{isMobile && (
							<Button
								onClick={handleOpenCloseDialogEmail}
								sx={{ backgroundColor: '#131313', padding: '.2rem', marginLeft: '-.8rem' }}>
								<CloseIcon sx={{ color: 'white', fontSize: '2rem' }} />
							</Button>
						)}
						<ChangeEmailDialogComponent accountInfo={accountInfo} setAccountInformation={setAccountInformation} />
					</Card>
				</Dialog>

				<Button
					variant="contained"
					sx={{
						backgroundColor: '#B71C1C',
						width: { xs: '45%', md: '35%' },
						mt: '.5rem',
						ml: { xs: '1rem', md: '2rem' },
						minWidth: { xs: '45%', md: '20%' },
						maxWidth: { xs: '45%', md: '20%' },
						minHeight: { xs: '5.5%', md: '5.5%' },
						maxHeight: { xs: '5.5%', md: '5.5%' },
						whiteSpace: 'nowrap',
						fontSize: { xs: '.6rem', md: '.8rem' },
					}}
					onClick={handleOpenEliminateDialog}>
					Eliminar cuenta
				</Button>
				<Dialog open={openDialog} onClose={handleCloseEliminateDialog}>
					<Card sx={{ backgroundColor: '#111111', height: '30vh', border: '1px inset #B71C1C' }}>
						{isMobile && (
							<Button
								onClick={handleCloseEliminateDialog}
								sx={{ backgroundColor: '#131313', padding: '.2rem', marginLeft: '-.8rem' }}>
								<CloseIcon sx={{ color: 'white', fontSize: '2rem' }} />
							</Button>
						)}
						<DialogTitle sx={{ textAlign: 'center', color: 'white' }}>Confirmación</DialogTitle>
						<DialogContent>
							<DialogContentText sx={{ color: 'white' }}>
								¿Estás seguro que quieres eliminar tu cuenta?
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Box
								component="img"
								src={deleteaccount}
								alt="tu_imagen"
								sx={{
									width: '6rem',
									height: '5rem',
									display: 'inline-block',
									marginRight: { xs: '0', md: '2rem' },
									marginTop: { xs: '-1.2rem', md: '-.8rem' },
								}}></Box>
							<Button
								onClick={handleCloseEliminateDialog}
								sx={{ color: 'white', display: 'inline-block', mr: { xs: '0', md: '.5rem' } }}>
								No
							</Button>
							<Button
								onClick={handleDeleteAccount}
								sx={{ color: 'white', display: 'inline-block', mr: { xs: '0', md: '.5rem' } }}>
								Sí
							</Button>
						</DialogActions>
					</Card>
				</Dialog>
				<Button
					onClick={onSave}
					// disabled={emailForm.newEmail === ''}
					sx={{
						backgroundColor: '#111111',
						borderBottom: '2px inset #B71C1C',
						color: 'white',
						width: { xs: '45%', md: '35%' },
						margin: 'auto',
						maxWidth: { xs: '70%', md: '35%' },
						maxHeight: { md: '12%' },
						minWidth: { xs: '70%', md: '35%' },
						minHeight: '8%',
						fontSize: { xs: '.6rem', md: '.8rem' },
					}}>
					{!loading ? 'Guardar' : <CircularProgress size={24} color="error" />}
				</Button>
				<Dialog open={showSaved} onClose={handleOpenCloseSaved}>
					<Card sx={{ backgroundColor: '#111111', height: '27vh', border: '1px inset #B71C1C' }}>
						<DialogTitle sx={{ textAlign: 'center', color: 'white' }}>Guardado exitosamente</DialogTitle>
						<DialogContent sx={{ textAlign: 'center' }}>
							<Typography sx={{ marginBottom: '1rem', color: 'white' }}>Tus cambios han sido guardados</Typography>
							<img
								style={{ width: '5rem', height: '5rem', filter: 'invert(90%)' }}
								alt="check"
								src="https://images.emojiterra.com/google/noto-emoji/unicode-15/bw/512px/1f468-1f527.png"
							/>
						</DialogContent>
						<Button
							onClick={handleOpenCloseSaved}
							sx={{
								backgroundColor: '#111111',
								color: 'white',
								borderBottom: '2px inset #B71C1C',
								width: { xs: '80%', md: '50%' },
								margin: 'auto',
								fontSize: { xs: '.6rem', md: '.8rem' },
								textAlign: 'center',
								display: 'block',
							}}>
							Aceptar
						</Button>
					</Card>
				</Dialog>
			</Card>
		</Grid>
	);
};
