import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import ProductImageCarousel from './ProductImageCarousel';
import { ProductInfo } from './ProductInfo';
import { IProduct } from '../../../api/interfaces/interfaces';
import { SellerDescription } from './SellerDescription';
import { ProductDescription } from './ProductDescription';
import { QuestionAnswer } from './QuestionAnswer';
import { CategoriesPath } from './CategoriesPath';
import { ImageStack } from './ImageStack';
import { Footer } from '../../footer/Footer';
import { useParams } from 'react-router-dom';
import { getProductByID } from '../../../api/productsApi';

interface ProductDetailInterface {
	productPreview?: IProduct;
	isPreview?: boolean;
}

export const ProductDetail = (props: ProductDetailInterface) => {
	const { productPreview, isPreview } = props;
	let params = useParams();
	const [selectedImage, setSelectedImage] = useState(0);
	const [product, setProduct] = useState<IProduct>();
	const [loading, setLoading] = useState<boolean>(true);

	const loadSelectedProduct = async () => {
		if (isPreview) {
			setProduct(productPreview);
			setLoading(false);
		} else {
			const productLoaded = await getProductByID(params.productID!);
			setProduct(productLoaded);
			setLoading(false);
		}
	};

	const handleImageClick = (index: number) => setSelectedImage(index);

	useEffect(() => {
		loadSelectedProduct();
	}, [params]);

	return (
		<>
			<Box
				sx={{
					flexGrow: 1,
					marginLeft: { xs: '0', lg: isPreview ? '5%' : '13%' },
					marginRight: { xs: '0', lg: isPreview ? '5%' : '13%' },
				}}>
				{!loading && (
					<>
						<Grid container spacing={2}>
							<Grid item xs={12} md={12} lg={12}>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										marginTop: '5px',
										marginBottom: '7px',
									}}>
									<CategoriesPath leafId={product?.categoryID!} />
								</Box>
							</Grid>
						</Grid>
						<Grid xs={12} container spacing={1}>
							<Grid
								item
								sx={{
									display: { xs: 'none', md: 'block' },
								}}
								md={1}
								lg={1}>
								<ImageStack
									images={product?.images!}
									selectedImage={selectedImage}
									handleImageClick={handleImageClick}
								/>
							</Grid>
							<Grid item xs={12} md={8} lg={8}>
								<ProductImageCarousel
									images={product?.images!}
									selectedImage={selectedImage}
									handleImageClick={handleImageClick}
								/>
							</Grid>
							<Grid item xs={12} md={3} lg={3}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<ProductInfo product={product!} />
									</Grid>
									{!isPreview && (
										<Grid item xs={12}>
											<SellerDescription ownerID={product?.ownerID!} />
										</Grid>
									)}
								</Grid>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							md={12}
							lg={12}
							sx={{marginLeft: {xs: 0, sm:'-1rem'}, marginTop: '1rem'}}
							//sx={{ marginLeft: { xs: '0', md: '30vh', lg: '30vh' }, marginRight: { xs: '0', md: '30vh', lg: '30vh' } }}
						>
							<ProductDescription text={product?.description!} />
							{!isPreview &&  (
								<QuestionAnswer productID={product?.id!} isOwner={product?.isOwner!} commentID={params.commentID} />
							)}
						</Grid>
					</>
				)}
			</Box>
			{!isPreview && <Footer />}
		</>
	);
};
