import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import { sendNewPassword } from '../../api/authApi';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';

export const CreateNewPassword = () => {
	const { token } = useParams();
	const navigate = useNavigate();

	const [msg, setMsg] = useState('');
	const [password, setPassword] = useState('');
	const [verificatedPassword, setVerificatedPassword] = useState('');
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const onSendRecovery = async () => {
		setIsLoading(true);
		const { status, message } = await sendNewPassword(token!, password);
		setMsg(message);
		setIsLoading(false);
		status === 200 && setTimeout(() => navigate('/login'), 1500);
	};

	const invalidateForm = () => {
		return password !== verificatedPassword || token === undefined || password.length < 7;
	};

	return (
		<Grid
			container
			spacing={1}
			direction="column"
			alignItems="center"
			justifyContent="center"
			sx={{ minHeight: { xs: '95vh', md: '92vh' }, backgroundColor: '#111111' }}>
			<Card
				sx={{
					width: { xs: '90%', sm: '80%', md: '40%' },
					margin: { sm: 'auto' },
					mr: { xs: '-2%' },
					textAlign: 'center',
					height: 'auto',
					borderRadius: '22px',
					border: '1px inset #F44336',
					backgroundColor: '#111111',
				}}>
				<Grid item xs={12}>
					<Typography
						sx={{
							marginTop: '5%',
							fontSize: '2rem',
							color: '#FFF4F4',
						}}>
						Crea una nueva contraseña
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					sx={{
						marginTop: 2,
						marginBottom: 10,
						width: { xs: '90%', sm: '80%', md: '50%' },
						margin: 'auto',
					}}>
					<TextField
						label="Nueva contraseña"
						type="password"
						fullWidth
						name="email"
						value={password}
						onChange={({ target: { value } }) => setPassword(value)}
						InputLabelProps={{
							style: {
								color: 'white', // Color del texto del placeholder
								opacity: 0.6, // Opacidad del placeholder
							},
						}}
						sx={{
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor: 'white', // Bordes blancos
								},
								'&:hover fieldset': {
									borderColor: 'white', // Bordes blancos cuando se pasa el cursor
								},
								'&.Mui-focused fieldset': {
									borderColor: '#3F51B5', // Bordes azules cuando está enfocado
								},
								backgroundColor: '#181818', // Fondo negro
								color: 'white', // Texto blanco
							},
							marginTop: '5%',
							'& input:-webkit-autofill, & textarea:-webkit-autofill, & select:-webkit-autofill': {
								// al activarse el autocomplete (auto-fill)
								WebkitTextFillColor: 'white', // font color
								transition: 'background-color 5000s ease-in-out 0s', // background color
							},
						}}
					/>
					<TextField
						label="Confirmar contraseña"
						type="password"
						fullWidth
						name="email"
						value={verificatedPassword}
						onChange={({ target: { value } }) => setVerificatedPassword(value)}
						InputLabelProps={{
							style: {
								color: 'white', // Color del texto del placeholder
								opacity: 0.6, // Opacidad del placeholder
							},
						}}
						sx={{
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor: 'white', // Bordes blancos
								},
								'&:hover fieldset': {
									borderColor: 'white', // Bordes blancos cuando se pasa el cursor
								},
								'&.Mui-focused fieldset': {
									borderColor: '#3F51B5', // Bordes azules cuando está enfocado
								},
								backgroundColor: '#181818', // Fondo negro
								color: 'white', // Texto blanco
							},
							marginTop: '5%',
							'& input:-webkit-autofill, & textarea:-webkit-autofill, & select:-webkit-autofill': {
								// al activarse el autocomplete (auto-fill)
								WebkitTextFillColor: 'white', // font color
								transition: 'background-color 5000s ease-in-out 0s', // background color
							},
						}}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					sx={{
						mt: 5,
						width: { xs: '90%', sm: '80%', md: '50%' },
						marginTop: '5%',
						marginBottom: '10%',
						marginLeft: '25%',
					}}>
					<Button
						style={{
							width: '80%',
							backgroundColor: '#181818',
							border: invalidateForm() ? '' : '1px inset #E57373',
							borderRadius: '1rem',
							color: 'white',
						}}
						type="submit"
						variant="contained"
						onClick={onSendRecovery}
						disabled={password !== verificatedPassword || token === undefined || password.length < 7}>
						{isLoading ? <CircularProgress size={24} color="inherit" /> : 'Restablecer'}
					</Button>
				</Grid>
				{
					<Grid item xs={12}>
						<Typography
							sx={{
								marginTop: '5%',
								fontSize: '1rem',
								fontWeight: 'bold',
								color: 'red',
							}}>
							{msg}
						</Typography>
					</Grid>
				}
			</Card>
		</Grid>
	);
};
