export const dateAndTimeFormat = (originalDate: string) => {
  const date = new Date(originalDate);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();
  const hour = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${day}/${month}/${year} ${hour}:${minutes}`;
};

export const dateFormat = (originalDate: string) => {
  const date = new Date(originalDate);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();

  return `${day}/${month}/${year}`;
};

export function currencyFormatter({ currency = "ARS", value }: any) {
  const formatter = new Intl.NumberFormat("es-AR", {});
  return formatter.format(value);
}

export function ensureLocation(state: string, city: string) {
  if (!state && !city) return "Sin ubicación";
  return `${city ?? "Sin especificar"} - ${state ?? "No especificar"}`;
}


