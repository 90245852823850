import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  Select,
  SelectChangeEvent,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  MenuItem,
  InputBase,
  InputAdornment,
} from "@mui/material";
import { styled } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import { Accident, CreateAccidentData } from "../../../api/interfaces/interfaces";
import { createAccident, getAccidentById, getAllAccidents } from "../../../api/insuredApi";

const StyledTableCell = styled(TableCell)({
  color: "#333",
});

const StatsBox = styled(Box)({
  backgroundColor: "#fff",
  padding: 20,
  borderRadius: 10,
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  textAlign: "center",
});

const SearchBox = styled(Paper)({
  display: "flex",
  width: "40%",
  alignItems: "center",
  marginBottom: 16,
  padding: "4px 16px",
  borderRadius: 20,
  border: "1px solid #E0E0E0",
});

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

export const SinisterView = () => {
  const [accidentList, setAccidentList] = useState<Accident[]>([]);
  const [filteredAccidents, setFilteredAccidents] = useState<Accident[]>([]); 
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState<CreateAccidentData>({
    accidentNumber: "",
    policyNumber: "",
    description: "",
    insured: "",
    status: "Pendiente",
  });
  const [activeStep, setActiveStep] = useState(0);

  const steps = ["Información Básica", "Detalles del Accidente"];

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAllAccidents();
        setAccidentList(data);
        setFilteredAccidents(data); 
      } catch (err) {
        setError("Error al cargar los accidentes");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };


  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name!]: value }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    const result = await createAccident(formData);
    setLoading(false);

    if (result) {
      alert("Accidente creado exitosamente");
      handleClose();
    } else {
      alert("Hubo un error al crear el accidente");
    }
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query) {
      const filtered = accidentList.filter((accident) =>
        accident.accidentNumber.includes(query) ||
        accident.policyNumber.includes(query) ||
        accident.status.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredAccidents(filtered);
    } else {
      setFilteredAccidents(accidentList);  
    }
  };

  const [searchQuery, setSearchQuery] = useState<string>('');

  return (
    <>
      <Button variant="contained" onClick={handleOpen} sx={{position: 'absolute', right: 300, top: 200}}>
        Nuevo Accidente
      </Button>
      <Modal open={modalOpen} onClose={handleClose}>
        <Box sx={style}>
          <Typography variant="h6" marginBottom={2}>
            Crear Nuevo Accidente
          </Typography>

          <Grid container spacing={2}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label, index) => (
                <Step key={index}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            <Box sx={{ marginTop: 2 }}>
              {activeStep === 0 ? (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Número de Accidente"
                      name="accidentNumber"
                      value={formData.accidentNumber}
                      onChange={handleTextFieldChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Número de Póliza"
                      name="policyNumber"
                      value={formData.policyNumber}
                      onChange={handleTextFieldChange}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      label="Descripción"
                      name="description"
                      value={formData.description}
                      onChange={handleTextFieldChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="ID del Asegurado"
                      name="insured"
                      value={formData.insured}
                      onChange={handleTextFieldChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      fullWidth
                      name="status"
                      value={formData.status}
                      onChange={handleSelectChange}
                    >
                      <MenuItem value="Pendiente">Pendiente</MenuItem>
                      <MenuItem value="Resuelto">Resuelto</MenuItem>
                      <MenuItem value="Cancelado">Cancelado</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              )}

              <Box sx={{ marginTop: 2 }}>
                <Button
                  onClick={handleBack}
                  disabled={activeStep === 0}
                  variant="contained"
                  color="secondary"
                >
                  Atrás
                </Button>
                {activeStep !== steps.length - 1 ? (
                  <Button
                    onClick={handleNext}
                    variant="contained"
                    sx={{ marginLeft: 2 }}
                  >
                    Siguiente
                  </Button>
                ) : (
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    sx={{ marginLeft: 2 }}
                  >
                    Crear Accidente
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
        </Box>
      </Modal>

      <Grid container spacing={2} marginTop={2}>
        <Grid item xs={12} sm={6}>
          <StatsBox>
            <Typography variant="h3" color="primary">
              {
                filteredAccidents.filter((accident) => accident.status === "Pendiente")
                  .length
              }
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Accidentes Pendientes
            </Typography>
          </StatsBox>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StatsBox>
            <Typography variant="h3" color="primary">
              {filteredAccidents.length}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Accidentes Totales
            </Typography>
          </StatsBox>
        </Grid>
      </Grid>

      <TableContainer component={Paper} sx={{ marginTop: 4, padding: 2 }}>
        <SearchBox>
          <InputBase
            placeholder="Buscar por número de accidente"
            fullWidth
            onChange={handleSearchChange}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </SearchBox>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Número de Accidente</StyledTableCell>
              <StyledTableCell>Número de Póliza</StyledTableCell>
              <StyledTableCell>Fecha</StyledTableCell>
              <StyledTableCell>Ubicación</StyledTableCell>
              <StyledTableCell>Descripción</StyledTableCell>
              <StyledTableCell>Estado</StyledTableCell>
              <StyledTableCell>Asegurado</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAccidents.map((accident, index) => (
              <TableRow key={index}>
                <TableCell>{accident.id}</TableCell>
                <TableCell>{accident.accidentNumber}</TableCell>
                <TableCell>{accident.policyNumber}</TableCell>
                <TableCell>{accident.createdAt}</TableCell>
                <TableCell>{accident.description}</TableCell>
                <TableCell>{accident.status}</TableCell>
                <TableCell>{accident.insured}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
