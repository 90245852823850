import { Grid, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import { verifyEmail } from '../../api/authApi';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

export const VerifyEmail = () => {
	const { token } = useParams();
	const [ok, setOk] = useState(false);

	const verify = async () => {
		const { status } = await verifyEmail(token!);
		status === 201 ? setOk(true) : setOk(false);
	};

	useEffect(() => {
		verify();
	}, []);

	return (
		<Grid
			container
			spacing={1}
			direction="column"
			alignItems="center"
			justifyContent="center"
			sx={{ minHeight: { xs: '95vh', md: '92vh' }, backgroundColor: '#111111' }}>
			<Card
				sx={{
					width: { xs: '90%', sm: '80%', md: '40%' },
					margin: { sm: 'auto' },
					mr: { xs: '-2%' },
					textAlign: 'center',
					height: 'auto',
					borderRadius: '22px',
					border: '1px inset #F44336',
					backgroundColor: '#111111',
				}}>
				<Grid item xs={12}>
					<Typography
						sx={{
							marginTop: '5%',
							fontSize: '2rem',
							color: '#FFF4F4',
							padding: 5,
						}}>
						Resultado verificación de correo electrónico
					</Typography>
				</Grid>
				<Grid item xs={12}>
					{ok ? (
						<Typography
							sx={{
								fontSize: '2rem',
								color: 'green',
								padding: 5,
							}}>
							¡Email verificado exitosamente!
						</Typography>
					) : (
						<Typography
							sx={{
								fontSize: '2rem',
								color: 'red',
								padding: 5,
							}}>
							¡Ha ocurrido un error!
						</Typography>
					)}
				</Grid>
			</Card>
		</Grid>
	);
};
