import {
	Autocomplete,
	Box,
	Button,
	Card,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
	Typography,
} from '@mui/material';
import { FileInput } from '../../utils/FileInput';
import { IImage, IPersonalInformation, ISellerProfile, IState } from '../../../api/interfaces/interfaces';
import { useEffect, useState } from 'react';
import { updateSellerProfile } from '../../../api/userApi';
import { getMunicipalitiesByState } from '../../../api/argentinaGobApi';
import { UseUserContext } from '../../../provider/UserContext';
import deleteaccount from '../../../images/deleteaccount.png';
import persona from '../../../images/persona.png';

interface SellerTabProps {
	states: IState[];
	sellerProfile: ISellerProfile;
	handleChangeSellerForm: (fieldName: keyof ISellerProfile, value: string | number | IImage | null) => void;
	personalData: IPersonalInformation;
	setPersonalInformation: (value: IPersonalInformation) => void;
	handlePreview: (personalData: IPersonalInformation, sellerData: ISellerProfile) => void;
}
export const SellerProfileTabPanel = (props: SellerTabProps) => {
	const { sellerProfile, handleChangeSellerForm, states, personalData, handlePreview } = props;
	const [showSaved, setShowSaved] = useState(false);
	const [errorDetected, setErrorDetected] = useState(false);
	const [loadingMunicipalities, setLoadingMunicipalities] = useState<boolean>(true);
	const [municipalities, setMunicipalities] = useState<IState[]>([]);
	const [selectedState, setSelectedState] = useState<string>('');
	const [showInputText, setShowInputText] = useState<boolean>(false);
	const [loading, setLoading] = useState(false);
	const [showSpinner, setShowSpinner] = useState(false);
	const [showPreview, setShowPreview] = useState(false);
	const [loadingProvinceField, setLoadingProvinceField] = useState(false);
	const { setUserImage } = UseUserContext();

	const handleMunicipalitySelected = (value: string) => {
		value !== 'Otro' && municipalities.some((e) => e.nombre === sellerProfile.city || e.nombre === selectedState)
			? setShowInputText(false)
			: setShowInputText(true);
		handleChangeSellerForm('city', value);
	};
	const handleStateSelected = (value: string) => {
		console.log(value);
		setSelectedState(value);
		handleChangeSellerForm('state', value);
	};

	const handleCloseEditDialog = () => {
		setShowSaved(false);
		setErrorDetected(false);
	};

	const loadMunicipalities = async (value: string) => {
		const muni = await getMunicipalitiesByState(value);
		// Ordenar los estados alfabeticamente
		const sortedMunicipalities = muni.sort((a, b) => a.nombre.localeCompare(b.nombre));
		setMunicipalities([...sortedMunicipalities, { id: '00', nombre: 'Otro' }]);
		setLoadingMunicipalities(false);
	};

	const loadAndClearMunicipalities = (value: string) => {
		setLoadingMunicipalities(true);
		loadMunicipalities(value);
		handleChangeSellerForm('city', value);
		setLoadingProvinceField(true);
		setShowInputText(false);
	};

	const [errors, setErrors] = useState({
		sellerName: '',
		sellerPhone: '',
	});

	const validateSellerName = (name: string) => {
		if (!name) return 'El nombre es obligatorio';
		if (name && name.length <= 4) return 'El nombre debe tener 4 o más caracteres';
		return '';
	};

	const validateSellerPhone = (cellphone: number) => {
		if (!cellphone) return 'El teléfono es obligatorio';
		if (cellphone && cellphone.toString().length <= 6) return 'El teléfono debe tener 6 o más caracteres';
		return '';
	};

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const newErrors = {
			sellerName: validateSellerName(sellerProfile.sellerName),
			sellerPhone: validateSellerPhone(sellerProfile.sellerPhone),
		};

		setErrors(newErrors);

		// validando si existen errores
		const isValid = Object.values(newErrors).every((error) => !error);
		//// aca deberia implementar que no se envie la petición en caso de existir errores
		if (isValid) {
			// traje el onSave aqui dentro para que no se disparen peticiones a menos que esté check
			setLoading(true);
			const rta = await updateSellerProfile(sellerProfile!);
			rta.status === 201 && setShowSaved(true);
			if (rta.status === 500) {
				setErrorDetected(true);
			}
			sellerProfile.image?.imageData && setUserImage(sellerProfile.image?.imageData);
			setLoading(false);
		} else {
			console.log('Form has errors');
		}
	};

	useEffect(() => {
		sellerProfile.state != null && loadMunicipalities(sellerProfile.state);
		if (loadingMunicipalities)
			sellerProfile.state != null &&
				setShowInputText(
					(!municipalities.some((e) => e.nombre === sellerProfile.city || e.nombre === selectedState) &&
						municipalities.length > 0) ||
						loadingMunicipalities,
				);
		else
			sellerProfile.state != null &&
				setShowInputText(
					!municipalities.some((e) => e.nombre === sellerProfile.city || e.nombre === selectedState) &&
						municipalities.length > 0,
				);
	}, [loadingMunicipalities, selectedState]);

	// encargado del render del spinner
	useEffect(() => {
		console.log(sellerProfile.state);
	}, [sellerProfile.state]);
	useEffect(() => {
		if (loadingProvinceField) {
			setShowSpinner(true);
			const timer = setTimeout(() => {
				setShowSpinner(false);
			}, 1000);
			return () => clearTimeout(timer); // Cleanup the timer on component unmount or if loadingProvinceField changes
		}
	}, [loadingProvinceField]);

	return (
		<Grid width="100%">
			<Card
				sx={{
					backgroundColor: '#111111',
					width: { md: '100%' },
					margin: 'auto',
					boxShadow: 'none',
				}}>
				<Typography
					variant="h5"
					gutterBottom
					sx={{
						color: 'white',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						fontFamily: 'Roboto',
						display: 'flex',
						justifyContent: 'center',
					}}>
					Configurando perfil de ventas
				</Typography>
			</Card>

			<form onSubmit={handleSubmit}>
				{!showPreview && (
					<Card
						sx={{
							height: { xs: '65vh', md: '58vh' },
							backgroundColor: '#111111',
							display: 'flex',
							flexDirection: 'column',
							boxShadow: 'none',
							overflowY: 'auto',
							'&::-webkit-scrollbar': {
								width: 0, // Ancho cero para hacerla invisible
							},
						}}>
						<Grid container spacing={2}>
							{/* Primera mitad de la pantalla para los texfield */}
							<Grid item xs={12} md={6}>
								<TextField
									name="name"
									label="Nombre del vendedor"
									error={!!errors.sellerName}
									helperText={errors.sellerName}
									defaultValue={sellerProfile?.sellerName}
									onChange={({ target: { value } }) =>
									handleChangeSellerForm('sellerName', value)
									}
									sx={{
										marginTop: '1.5rem',
										marginBottom: '.5rem',
										width: { xs: '80%', md: '80%' },
										marginLeft: '5%',
										borderTop: 'none',
										'& .MuiOutlinedInput-root': {
											'& fieldset': {
												borderColor: 'white', // Bordes blancos
											},
											'&:hover fieldset': {
												borderColor: 'white', // Bordes blancos cuando se pasa el cursor
											},
											'&.Mui-focused fieldset': {
												borderColor: '#3F51B5', // Bordes azules cuando está enfocado
											},
											backgroundColor: '#181818', // Fondo negro
											color: 'white', // Texto blanco
										},
									}}
									InputLabelProps={{
										sx: {
											color: 'white', // Color del texto del placeholder
											opacity: 0.6, // Opacidad del placeholder
											'&.Mui-focused': {
												color: 'white',
											},
										},
									}}
									size="small"
								/>

								<TextField
									name="sellerCellphone"
									type="tel"
									label="Teléfono de ventas"
									error={!!errors.sellerPhone}
									helperText={errors.sellerPhone}
									defaultValue={sellerProfile?.sellerPhone}
									sx={{
										marginTop: '.5rem',
										width: { xs: '80%', md: '80%' },
										marginLeft: '5%',
										borderTop: 'none',
										'& .MuiOutlinedInput-root': {
											'& fieldset': {
												borderColor: 'white', // Bordes blancos
											},
											'&:hover fieldset': {
												borderColor: 'white', // Bordes blancos cuando se pasa el cursor
											},
											'&.Mui-focused fieldset': {
												borderColor: '#3F51B5', // Bordes azules cuando está enfocado
											},
											backgroundColor: '#181818', // Fondo negro
											color: 'white', // Texto blanco
										},
									}}
									InputLabelProps={{
										sx: {
											color: 'white', // Color del texto del placeholder
											opacity: 0.6, // Opacidad del placeholder
											'&.Mui-focused': {
												color: 'white',
											},
										},
									}}
									size="small"
									onChange={({ target: { value } }) =>
									handleChangeSellerForm('sellerPhone', value)
									}
								/>
								<Autocomplete
									id="combo-box-demo2"
									options={states.map((e) => e.nombre)}
									getOptionLabel={(state) => state}
									// isOptionEqualToValue={(option, value) => option === value}
									value={selectedState || sellerProfile.state} // Establecer el valor del Autocomplete como el estado seleccionado
									onChange={(event, newValue) => {
										handleStateSelected(newValue!); // Manejar los cambios en la selección del estado
										loadAndClearMunicipalities(newValue!); // Cargar y limpiar los municipios según el estado seleccionado
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											size="small"
											sx={{
												width: { xs: '87%', lg: '84%' },
												ml: { xs: '-.3rem', xl: '.1rem' },
												borderTop: 'none',
												'& .MuiOutlinedInput-root': {
													'& fieldset': {
														borderColor: 'white', // Bordes blancos
													},
													'&:hover fieldset': {
														borderColor: 'white', // Bordes blancos cuando se pasa el cursor
													},
													'&.Mui-focused fieldset': {
														borderColor: '#3F51B5', // Bordes azules cuando está enfocado
													},
													backgroundColor: '#181818', // Fondo negro
													color: 'white', // Texto blanco
													'& .MuiSvgIcon-root': {
														fill: 'white', // Color del icono
													},
												},
											}}
											InputLabelProps={{
												sx: {
													color: 'white', // Color del texto del placeholder
													opacity: 0.6, // Opacidad del placeholder
													'&.Mui-focused': {
														color: 'white',
													},
												},
											}}
											label="Provincia"
										/>
									)}
									sx={{ ml: '1rem', mt: '1rem' }}
								/>

								{!loadingMunicipalities && (
									<Autocomplete
										id="combo-box-demo"
										options={municipalities}
										getOptionLabel={(municipality) => municipality.nombre}
										loading={loadingMunicipalities}
										loadingText="Cargando..."
										value={municipalities.find((municipality) => {
											if (showInputText) return municipality.nombre === { id: '00', name: 'Otro' }.name;
											else return municipality.nombre === sellerProfile?.city;
										})}
										onChange={(event, newValue) => handleMunicipalitySelected(newValue?.nombre!)}
										disabled={loadingMunicipalities}
										renderInput={(params) => (
											<TextField
												{...params}
												size="small"
												label="Ciudad"
												key={'key2'}
												sx={{
													marginBottom: '1rem',
													width: { xs: '87%', md: '84%' },
													ml: { xs: '-.3rem', md: '.1rem' },
													borderTop: 'none',
													'& .MuiOutlinedInput-root': {
														'& fieldset': {
															borderColor: 'white', // Bordes blancos
														},
														'&:hover fieldset': {
															borderColor: 'white', // Bordes blancos cuando se pasa el cursor
														},
														'&.Mui-focused fieldset': {
															borderColor: '#3F51B5', // Bordes azules cuando está enfocado
														},
														backgroundColor: '#181818', // Fondo negro
														color: 'white', // Texto blanco
														'& .MuiSvgIcon-root': {
															fill: 'white', // Color del icono
														},
													},
												}}
												InputLabelProps={{
													sx: {
														color: 'white', // Color del texto del placeholder
														opacity: 0.6, // Opacidad del placeholder
														'&.Mui-focused': {
															color: 'white',
														},
													},
												}}
												InputProps={{
													...params.InputProps,
													endAdornment: (
														<>
															{loadingMunicipalities ? <CircularProgress size={24} color="inherit" /> : null}
															{params.InputProps.endAdornment}
														</>
													),
												}}
											/>
										)}
										sx={{ ml: '1rem', mt: '1rem' }}
									/>
								)}
								{loadingProvinceField && showSpinner && (
									<Grid
										container
										justifyContent="center" // REVISAR, CUANDO EL USER NO CARGA CIUDAD QUEDA CARGANDO EL SPINNER
										alignItems="center">
										<CircularProgress
											color="error"
											size={'3rem'}
											sx={{ padding: '.5rem', ml: { xs: '-2rem', md: '-3rem' } }}
										/>
									</Grid>
								)}

								{showInputText && !loadingMunicipalities && (
									<TextField
										key={'key'}
										label="Especificar"
										size="small"
										sx={{
											marginBottom: '1rem',
											width: { xs: '80%', md: '80%' },
											ml: '5%',
											borderTop: 'none',
											'& .MuiOutlinedInput-root': {
												'& fieldset': {
													borderColor: 'white', // Bordes blancos
												},
												'&:hover fieldset': {
													borderColor: 'white', // Bordes blancos cuando se pasa el cursor
												},
												'&.Mui-focused fieldset': {
													borderColor: '#3F51B5', // Bordes azules cuando está enfocado
												},
												backgroundColor: '#181818', // Fondo negro
												color: 'white', // Texto blanco
												'& .MuiSvgIcon-root': {
													fill: 'white', // Color del icono
												},
											},
										}}
										InputLabelProps={{
											sx: {
												color: 'white', // Color del texto del placeholder
												opacity: 0.6, // Opacidad del placeholder
												'&.Mui-focused': {
													color: 'white',
												},
											},
										}}
										onChange={({ target: { value } }) =>
										handleChangeSellerForm('city', value)
											
										}
										defaultValue={sellerProfile?.city !== 'Otro' ? sellerProfile?.city : ''}
									/>
								)}

								<TextField
									name="description"
									variant="outlined"
									defaultValue={sellerProfile?.sellerDescription}
									multiline
									rows={2}
									label="Descripción/Mensaje de bienvenida"
									sx={{
										marginTop: '.8rem',
										marginBottom: { xs: '-1rem', md: '.8rem' },
										width: { xs: '80%', md: '80%' },
										marginLeft: '5%',
										borderTop: 'none',
										'& .MuiOutlinedInput-root': {
											'& fieldset': {
												borderColor: 'white', // Bordes blancos
											},
											'&:hover fieldset': {
												borderColor: 'white', // Bordes blancos cuando se pasa el cursor
											},
											'&.Mui-focused fieldset': {
												borderColor: '#3F51B5', // Bordes azules cuando está enfocado
											},
											backgroundColor: '#181818', // Fondo negro
											color: 'white', // Texto blanco
										},
									}}
									InputLabelProps={{
										sx: {
											color: 'white', // Color del texto del placeholder
											opacity: 0.6, // Opacidad del placeholder
											'&.Mui-focused': {
												color: 'white',
											},
										},
									}}
									onChange={({ target: { value } }) =>
									handleChangeSellerForm('sellerDescription', value)
										
									}
								/>
							</Grid>
							{/* Segunda mitad de la pantalla para la imagen */}
							<Grid
								item
								xs={12}
								md={6}
								sx={{ mt: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
								direction={'column'}>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
										width: { xs: '45vw', md: '15vw', lg: '15vw' }, // Establece el ancho del contenedor
										height: { xs: '25vh', md: '2	5vh', lg: '15vw' }, // Establece la altura del contenedor
										overflow: 'hidden', // Asegura que la imagen no se desborde del contenedor
										borderRadius: 1, // Opcional: aplica bordes redondeados al contenedor
									}}>
									{sellerProfile.image?.imageData === undefined ? (
										<img
											style={{
												width: '100%',
												height: '100%',
												objectFit: 'cover',
												backgroundColor: 'white',
												borderRadius: '1rem',
											}} // Aplica objectFit: 'cover'
											alt="default"
											src={persona}
										/>
									) : (
										<img
											style={{ width: '100%', height: '100%', objectFit: 'cover' }} // Aplica objectFit: 'cover'
											src={sellerProfile.image?.imageData!}
											alt={sellerProfile.image?.name!}
										/>
									)}
								</Box>
								<FileInput
									handleImages={(value) =>
										handleChangeSellerForm('image', {...value.findLast(()=> true)})
									}

									addedImages={[sellerProfile.image!]}
									renderStack={false}
								/>
							</Grid>
						</Grid>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								marginTop: { xs: '1.5rem', md: '1.2rem', lg: '1.2rem' },
							}}>
							<Button
								onClick={() => handlePreview(personalData, sellerProfile)}
								variant="contained"
								sx={{
									backgroundColor: '#111111',
									color: 'white',
									borderBottom: '2px inset #B71C1C',
									width: { xs: '80%', md: '35%' },
									margin: 'auto',
									mr: { xs: '1rem', md: '-1rem' },
									overflow: 'hidden',
									whiteSpace: 'nowrap',
									fontSize: { xs: '.6rem', md: '.8rem' },
								}}>
								Vista previa
							</Button>
							<Button
								type="submit"
								variant="contained"
								sx={{
									backgroundColor: '#111111',
									color: 'white',
									borderBottom: '2px inset #B71C1C',
									width: { xs: '80%', md: '35%' },
									margin: 'auto',
									fontSize: { xs: '.6rem', md: '.8rem' },
								}}>
								{!loading ? 'Guardar' : <CircularProgress size={24} color="error" />}
							</Button>
							<Dialog open={errorDetected} onClose={handleCloseEditDialog}>
								<Card sx={{ backgroundColor: '#111111', height: '37vh', border: '1px inset #B71C1C' }}>
									<DialogTitle sx={{ textAlign: 'center', color: 'white' }}>Error al guardar</DialogTitle>
									<DialogContent sx={{ textAlign: 'center' }}>
										<Box
											component="img"
											src={deleteaccount}
											alt="tu_imagen"
											sx={{
												width: '6rem',
												height: '5rem',
												display: 'inline-block',
											}}
										/>
										<Typography sx={{ marginTop: '1rem', color: 'white' }}>
											No hemos podido guardar tus cambios
										</Typography>
									</DialogContent>
									<Button
										onClick={handleCloseEditDialog}
										sx={{
											backgroundColor: '#111111',
											color: 'white',
											borderBottom: '2px inset #B71C1C',
											width: { xs: '80%', md: '50%' },
											margin: 'auto',
											fontSize: { xs: '.6rem', md: '.8rem' },
											textAlign: 'center',
											display: 'block',
										}}>
										Aceptar
									</Button>
								</Card>
							</Dialog>
							<Dialog open={showSaved} onClose={handleCloseEditDialog}>
								<Card sx={{ backgroundColor: '#111111', height: '27vh', border: '1px inset #B71C1C' }}>
									<DialogTitle sx={{ textAlign: 'center', color: 'white' }}>Guardado exitosamente</DialogTitle>
									<DialogContent sx={{ textAlign: 'center' }}>
										<Typography sx={{ marginBottom: '1rem', color: 'white' }}>
											Tus cambios han sido guardados
										</Typography>
										<img
											style={{ width: '5rem', height: '5rem', filter: 'invert(90%)' }}
											alt="check"
											src="https://images.emojiterra.com/google/noto-emoji/unicode-15/bw/512px/1f468-1f527.png"
										/>
									</DialogContent>
									<Button
										onClick={handleCloseEditDialog}
										sx={{
											backgroundColor: '#111111',
											color: 'white',
											borderBottom: '2px inset #B71C1C',
											width: { xs: '80%', md: '50%' },
											margin: 'auto',
											fontSize: { xs: '.6rem', md: '.8rem' },
											textAlign: 'center',
											display: 'block',
										}}>
										Aceptar
									</Button>
								</Card>
							</Dialog>
						</Box>
					</Card>
				)}
			</form>
		</Grid>
	);
};
