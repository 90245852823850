import { IAsk } from '../../../api/interfaces/interfaces';
import { Badge, Button, CardContent, Grid, TextField, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { useState } from 'react';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import { useNavigate } from 'react-router-dom';
interface CommentCardProps {
	ask: IAsk;
	isSendingResponse: boolean;
	sendResponse: (commentID: string, comment: string) => void;
}
export const CommentCard = (props: CommentCardProps) => {
	const { ask, isSendingResponse, sendResponse } = props;
	const [answerText, setAnswerText] = useState<string>('');
	const navigate = useNavigate();
	return (
		<Grid item sx={{ marginBottom: '1.3rem', backgroundColor: '#131313', borderRadius: '17px', }}>
			<CardContent sx={{ flexDirection: 'column', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', borderRadius: '17px', }}>
				<Typography color={'white'} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', fontSize: '.85rem' }}>
				Usuario:
				<Button sx={{display: 'flex', justifyContent: 'flex-start',}}
					onClick={() => navigate(`/sellerProfile/${ask.commentOwnerId}`)}> 
				<Typography color={'white'} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', fontSize: '.85rem' }}>
				{ask.commentOwnerName}
				</Typography>
				</Button>
					</Typography>
				<Typography color={'white'} sx={{ display: 'flex', alignItems: 'center', fontSize: '.85rem' }}>
				<RecordVoiceOverIcon sx={{ marginRight: '0.5rem', fontSize:'1.3rem' }} />
					- {ask.comment}
				</Typography>
				<TextField
					name="description"
					variant="outlined"
					placeholder='Respuesta...'
					defaultValue={answerText}
					multiline
					rows={2}
					label="Escribe una respuesta..."
					sx={{
						marginTop: '.5rem',
						marginBottom: { xs: '.5rem', lg: '.8rem' },
						width: { xs: '80%', md: '80%' },
						borderTop: 'none',

						'& .MuiOutlinedInput-root': {
							'& fieldset': {
								borderColor: 'white', // Bordes blancos
							},
							'&:hover fieldset': {
								borderColor: 'white', // Bordes blancos cuando se pasa el cursor
							},
							'&.Mui-focused fieldset': {
								borderColor: '#3F51B5', // Bordes azules cuando está enfocado
							},
							backgroundColor: '#181818', // Fondo negro
							borderRadius: '17px',
							color: 'white', // Texto blanco
						},
					}}
					InputLabelProps={{
						sx: {
							color: 'white', // Color del texto del placeholder
							opacity: 0.6, // Opacidad del placeholder
							'&.Mui-focused': {
								color: 'white',
							},
						},
					}}
					onChange={({ target: { value } }) => setAnswerText(value)}
				/>
				<Grid sx={{
					display: 'flex',
					justifyContent: 'center', width: '100%'
				}}>

					<Button
						disabled={isSendingResponse || answerText === ""}
						onClick={() => {
							sendResponse(ask.commentID, answerText);
							setAnswerText('');
						}}
						sx={{
							'&.Mui-disabled': {
								bgcolor: '#111',
								color: 'white',
								opacity: 0.5, // Ajusta esto según prefieras
							},
							color: 'white',
							borderRadius: '8px',
							borderBottom: 'thin solid red',
							width: { xs: 'auto', lg: '7vw' },
							height: { xs: '3vh', lg: '3.5vh' },
							fontSize: { xs: '.8rem', lg: '.75rem' },
							"&:hover": { bgcolor: "#424242" },
						}}
					>
						Responder
					</Button>
				</Grid>
			</CardContent>

		</Grid>

	);
};
