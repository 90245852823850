import { Card, Typography } from "@mui/material"
import deleteaccount from '../../../images/deleteaccount.png'
import { Link } from "react-router-dom"

export const NoCommentAvailable = () => {
  return (
    <Card
    sx={{
        marginTop: '1rem',
        borderRadius: '0.5rem',
        backgroundColor: '#111111',
    }}>
    <Typography
        variant="h6"
        sx={{
            color: 'white',
            fontFamily: 'Arial',
        }}>
        Lo sentimos...
    </Typography>
    <img
        src={deleteaccount}
        alt="Hombre con coche roto"
        style={{ width: '100%', maxWidth: '250px', borderRadius: '22px', marginTop:'1rem' }}
    />
    <Typography
        variant="h6"
        sx={{
            color: 'white',
            fontFamily: 'Arial',
            marginTop:'1rem'
        }}>
        No has recibido preguntas en tus publicaciones hasta el momento.
    </Typography>
    <Typography
        variant="body1"
        sx={{
            color: 'white',
            fontFamily: 'Arial',
            marginTop: { xs: '1.5rem', md: '2rem' }
        }}>
        Sigue publicando! puedes hacerlo clickeando <Link to='/publish-product' style={{ color: '#F44336' }}>aquí</Link>
    </Typography>
</Card>
  )
}
