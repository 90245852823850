import { Card, Skeleton } from "@mui/material";

export const ProductListCarouselSkeleton = () => {
  return (
    <Card
    sx={{
      display: 'flex',
      justifyContent: 'center',
      width: { md: '15vw', sm: '60vw', xs: '60vw' },
      margin: -2, marginLeft: {xs: '4rem', md: '0rem'}, 
      height: 'auto',
      backgroundColor: '#111111',
      borderRadius: '12rem',
    }}
    >
      <Skeleton
        sx={{
          backgroundColor: "#050505",
          borderLeft: "solid 1.5px grey",
          borderBottom: "solid .5px grey",
          bgcolor: "white",
          marginTop: '1rem',
          height: '40vh'
        }}
        variant="rectangular"
      />
      <Skeleton width="80%" sx={{ bgcolor: "white" }} />
    </Card  >
  );
};
