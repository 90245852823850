import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";

interface CommentFormProps {
  value: string;
  isLoading: boolean;
  handleInputChange: (value: string) => void;
  handleSendValue: () => void;
  title: string;
  descriptionButton: string;
  placeholder: string;
}

export const CommentForm = (props: CommentFormProps) => {
  const {
    value,
    isLoading,
    handleInputChange,
    handleSendValue,
    title,
    descriptionButton,
    placeholder,
  } = props;

  return (
    <Grid
      container
      sx={{
        flexDirection: "column",
        justifyContent: "initial",

      }}
    >
      <Typography
        sx={{
          color: "#f1f1f1",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {title}
      </Typography>
      <TextField
        value={value}
        placeholder={placeholder}
        variant="outlined"
        multiline
        rows={2}
        sx={{ margin: 1, bgcolor: "white", borderRadius: '10px' }}
        onChange={({ target: { value: valueTextField } }) =>
          handleInputChange(valueTextField)
        }
        InputProps={{
          inputProps: { maxLength: 250 },
        }}
        helperText={`${value.length}/250`}
        error={value.length > 250}
      />
      <Button
        sx={{
          margin: "auto",
          bgcolor: "#111",
          borderBottom: 'thin solid red',
          borderRadius: '5px',
          "&:hover": { bgcolor: "grey" },
          width: "fit-content",
          
        }}
        onClick={() => handleSendValue()}
        disabled={isLoading || value === ""}
      >
        {isLoading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          <Typography color="#f1f1f1" variant="body2">{descriptionButton}</Typography>
        )}
      </Button>
      
    </Grid>
  );
};
