import { ICommentWithoutResponse, IProductComment } from './interfaces/interfaces';
import { axiosService } from './axiosService';

export const getQuestionsWithResponses = async (
	productID: string,
	limit: number,
	offset: number,
): Promise<IProductComment> => {
	const data = await axiosService
		.get(`/productsComments/commetsWithResponsesByProductId?productID=${productID}&limit=${limit}&offset=${offset}`)
		.then(
			(response) => {
				if (response.status === 200) return response.data;
				else {
					console.warn(response);
					return [{}];
				}
			},
			(err) => {
				console.error('Error: ' + err);
			},
		);

	return data ?? [];
};

export const getCommetsWithoutResponseByProductOwner = async (): Promise<ICommentWithoutResponse[]> => {
	const data = await axiosService.get(`/productsComments/commetsWithoutResponseByProductOwner`).then(
		(response) => {
			if (response.status === 200) return response.data;
			else {
				console.warn(response);
				return [];
			}
		},
		(err) => {
			console.error('Error: ' + err);
		},
	);

	return data ?? [];
};

export const getCommetsCountWithoutResponseByProductOwner = async (): Promise<number> => {
	const data = await axiosService.get(`/productsComments/commnetsCountWithoutResponseByOwner`).then(
		(response) => {
			if (response.status === 200) return response.data;
			else {
				console.warn(response);
				return 0;
			}
		},
		(err) => {
			console.error('Error: ' + err);
		},
	);

	return data ?? 0;
};

export const sendComment = async (productId: string, question: string): Promise<{}> => {
	const body = {
		productID: productId,
		comment: question,
	};
	const data = await axiosService.post('/productsComments/addComment', body).then(
		(response) => {
			if (response.status === 200) return { result: true };
			else {
				console.warn(response);
				return { result: false };
			}
		},
		(err) => {
			console.error('Error: ' + err);
		},
	);
	return data ?? false;
};

export const sendCommentResponse = async (commentID: string, comment: string): Promise<any> => {
	const data = await axiosService.post('/commentsReponses/addCommentResponse', { commentID, comment }).then(
		(response) => {
			if (response.status === 200) return response;
			else {
				// console.warn(response);
				return response;
			}
		},
		(err) => {
			// console.error("Error: " + err);
			return { status: 500 };
		},
	);
	return data ?? false;
};
