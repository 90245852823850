import { Box, Button, Card, CardContent, CircularProgress, TextField } from '@mui/material';
import { useState } from 'react';

interface ResponseCommentProps {
	commentID: string;
	onSendResponse: (commentID: string, comment: string) => void;
	isLoading: boolean;
}
export const ResponseCommentForm = (props: ResponseCommentProps) => {
	const { commentID, onSendResponse, isLoading } = props;
	const [inputText, setInputText] = useState<string>('');
	return (
		<Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
			<Card
				sx={{
					display: 'flex',
					flexDirection: 'column',
					width: {xs:'80%',sm:'60%'},
					backgroundColor: '#111',
					// padding:5
				}}>
				<CardContent>
					<TextField
						value={inputText}
						placeholder={'Escribe una respuesta..'}
						variant="outlined"
						multiline
						rows={2}
						sx={{  bgcolor: 'white', borderRadius: '10px' }}
						onChange={({ target: { value: valueTextField } }) => setInputText(valueTextField)}
						InputProps={{
							inputProps: { maxLength: 250 },
						}}
						helperText={`${inputText.length}/250`}
						error={inputText.length > 250}
						style={{ width: '80%', minWidth: '80%', maxWidth: '80%' }}
					/>
					<Button
						sx={{
							bgcolor: '#111',
							color: 'white',	
							'&:hover': { bgcolor: 'grey' },
							width: 'fit-content',
							borderBottom: 'thin solid red',
							borderRadius: '5px',
							mt: {xs: 0,sm:'4rem'},
							ml: '.5rem',
							'&.Mui-disabled': {
								bgcolor: '#111',
								color: 'white',
								opacity: 0.5, // Ajusta esto según prefieras
							}
						}}
						onClick={() => onSendResponse(commentID, inputText)}
						disabled={isLoading || inputText === ""}>
						{isLoading ? <CircularProgress size={24} color="inherit" /> : 'Enviar'}
					</Button>
				</CardContent>
			</Card>
		</Box>
	);
};
