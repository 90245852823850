import { axiosService } from "./axiosService";
import { INewImage } from "./interfaces/interfaces";

export const getAllImages = async (): Promise<any[]> => {
  const data = await axiosService.get("images/").then((response)=> {
    if(response.status === 200)
      return response.data;
    else{
      console.warn(response);
      return {};
    }
  }, (err)=> {console.error('Error: ' + err)});
  return data;
};

export const sendImage = async (image: INewImage): Promise<any> => {
  const data = await axiosService.post("images/saveOneImage", image).then((response)=> {
    if(response.status === 201)
      return response.data;
    else{
      console.warn(response);
      return {};
    }
  }, (err)=> {console.error('Error: ' + err)});
  return data;
};

export const deleteImageById = async (imageID: string): Promise<any[]> => {
  const data = await axiosService.delete(`images/deleteImageById/${imageID}`).then((response)=> {
    if(response.status === 204)
      return response.data;
    else{
      console.warn(response);
      return {};
    }
  }, (err)=> {console.error('Error: ' + err)});
  return data;
};