import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies(null, { path: "/" });
const ACCESS_TOKEN = cookies.get("token");
const REFRESH_TOKEN = cookies.get("refreshToken");

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

if (ACCESS_TOKEN)
  axios.defaults.headers.common["Authorization"] = `Bearer ${ACCESS_TOKEN}`;
if (REFRESH_TOKEN)
  axios.defaults.headers.common["x-auth-token"] = `${REFRESH_TOKEN}`;
axios.defaults.headers.post["Content-Type"] = "application/json";

// Add a response interceptor
//Evaluar interceptar las request en lugar de las response.
// axios.interceptors.response.use(function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     return response;
//   }, function (error) {
//     const {response:{status}, request} = error;
//     if(status === 401){
//         if(request.responseURL.includes("auth/generateNewToken")){
//             cookies.set("token", null);
//             cookies.set("refreshToken", null);
//         }else{
//             // refreshToken();
//             // logout();
//         }

//     }
//     console.log(error);
//     return Promise.reject(error);
//   });

export const axiosService = axios;
