import { Card, Typography } from "@mui/material"
import { Link } from "react-router-dom"

export const NoProductAvailable = () => {
  return (
    <Card
    sx={{
        marginTop: '2rem',
        borderRadius: '0.5rem',
        backgroundColor: '#111111',
    }}>
    <Typography
        variant="h6"
        sx={{
            color: 'white',
            fontFamily: 'Arial',
        }}>
        Lo sentimos...
    </Typography>
    <img
        src="https://media.istockphoto.com/id/667707884/es/vector/hombre-de-pie-junto-a-un-coche-roto.jpg?s=612x612&w=0&k=20&c=r2s0RcOVKn-E-UvaayWIc28vvIcAJ9GUWPqWVm_B184="
        alt="Hombre con coche roto"
        style={{ width: '100%', maxWidth: '330px', borderRadius: '22px' }}
    />
    <Typography
        variant="h6"
        sx={{
            color: 'white',
            fontFamily: 'Arial',
        }}>
        No tienes productos publicados para mostrar por el momento.
    </Typography>
    <Typography
        variant="body1"
        sx={{
            color: 'white',
            fontFamily: 'Arial',
            marginTop: { xs: '1.5rem', md: '2rem' }
        }}>
        Crea tu primer publicación desde <Link to='/publish-product' style={{ color: '#F44336' }}>aquí</Link>
    </Typography>
</Card>
  )
}
