import { Card, Skeleton } from "@mui/material";

export const ProductPreviewSkeleton = () => {
  return (
    <Card
      sx={{
        maxWidth: 545,
        height: "100%",
        backgroundColor: "#050505",
        borderLeft: "solid 1.5px grey",
        borderBottom: "solid .5px grey",
        paddingTop: '10%'
      }}
      elevation={22}
    >
      <Skeleton width="100%" sx={{ bgcolor: "white" }} />
      <Skeleton
        sx={{
          maxWidth: 545,
          height: 200,
          backgroundColor: "#050505",
          borderLeft: "solid 1.5px grey",
          borderBottom: "solid .5px grey",
          bgcolor: "white",
        }}
        variant="rectangular"
      />
      <Skeleton width="60%" sx={{ bgcolor: "white" }} />
      <Skeleton width="80%" sx={{ bgcolor: "white" }} />
    </Card>
  );
};
