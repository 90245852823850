import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from '../components/login/LoginPage';
import { Layout } from '../layout/Layout';
import { PrivateRoutes } from './ProtectedRoutes';
import { ProductManagement } from '../components/user/productManagement/ProductManagement';
import { NewProduct } from '../components/products/newProduct/NewProduct';
import { ProductDetail } from '../components/products/productDetail/ProductDetail';
import { PublicRoutes } from './PublicRoutes';
import { RegisterPage } from '../components';
import { EditUserProfile } from '../components/user/editUserProfile/EditUserProfile';
import { CommentManagement } from '../components/user/commentManagement/CommentManagement';
import { RecoverPassword } from '../components/forgotPassword/RecoverPassword';
import { UserProfile } from '../components/user/UserProfile';
import { CreateNewPassword } from '../components/forgotPassword/CreateNewPassword';
import { VerifyEmail } from '../components/register/VerifyEmail';
import { OfficeView } from '../components/user/userOffice/OfficeView';

const RouterRoutes = () => {
	return (
		<Router>
			<Routes>
				<Route path="/login" element={<PublicRoutes element={<LoginPage />} />} />
				<Route path="/register" element={<PublicRoutes element={<RegisterPage />} />} />
				<Route path="/verify-email/:token" element={<PublicRoutes element={<VerifyEmail />} />} />
				<Route path="/forgotpw" element={<PublicRoutes element={<RecoverPassword />} />} />
				<Route path="/recovery/:token" element={<PublicRoutes element={<CreateNewPassword />} />} />
				<Route path="/" element={<PrivateRoutes element={<Layout key={'Layout'}/>} />} />
				<Route path="/manage-products" element={<PrivateRoutes element={<ProductManagement key={'ProductManagement'}/>} />} />
				<Route path="/profile" element={<PrivateRoutes element={<EditUserProfile key={'EditUserProfile'}/>} />} />
				<Route path="/office" element={<PrivateRoutes element={<OfficeView key={'UserOffice'}/>} />} />
				<Route path="/sellerProfile/:sellerID" element={<PrivateRoutes element={<UserProfile key={'UserProfile'}/>} />} />
				<Route path="/publish-product" element={<PrivateRoutes element={<NewProduct key={'NewProduct'}/>} />} />
				<Route path="/product-detail/:productID/:commentID?" element={<PrivateRoutes element={<ProductDetail key={'ProductDetail'}/>} />} />
				<Route path="/manage-asks" element={<PrivateRoutes element={<CommentManagement key={'CommentManagement'}/>} />} />
			</Routes>
		</Router>
	);
};

export default RouterRoutes;
