import { Button, Grid } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';

interface CommentPaginationProps {
	totalPages: number;
	handleBack: () => void;
	handleNext: () => void;
	handlePageClick: (index: number) => void;
	selectedPage: number;
}

export const ManagementPagination = (props: CommentPaginationProps) => {
	const theme = useTheme();
	const { totalPages, handleBack, handleNext, handlePageClick, selectedPage } = props;

	const [values, setValues] = useState<number[]>([]);
	useEffect(() => {
		const renderPaginateNumberDynamic = (index: number, count: number) => {
			// Generate array of pages starting from 1 to totalPages
			const pages = Array.from({ length: totalPages }, (_, index) => index + 1);
			const half = Math.floor(count / 2);

			let start = Math.max(index - half, 1);
			let end = Math.min(index + half, totalPages);

			// Adjust start and end if they are out of bounds
			if (index - half < 1) {
				end = Math.min(end + (1 - (index - half)), 5);
			}

			if (index + half > totalPages) {
				start = Math.max(start - (index + half - totalPages), 1);
			}

			return pages.slice(start - 1, end);
		};

		setValues(renderPaginateNumberDynamic(selectedPage, 4));
	}, [totalPages, selectedPage]);

	return (
		<Grid
			container
			direction="row"
			justifyContent="center"
			alignItems="center"
			sx={{
				borderBottom: '.5px inset #B71C1C',
				paddingBottom: '.3rem',
				backgroundColor: '#111111',
				width: { xs: '95%', sm: '60%' },
				margin: 'auto',
			}}>
			<Grid item xs>
				<Button
					sx={{ color: 'white', padding: 0, width: '100%' }}
					size="small"
					onClick={() => {
						handleBack();
					}}
					disabled={values.length === 1 || selectedPage === 1}>
					{theme.direction === 'rtl' ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}
				</Button>
			</Grid>
			{values.map((value, index) => (
				
				<Grid item xs key={index}>
					<Button
						sx={{
							width: '40%',
							color: 'white',
							border: selectedPage === value ? '1px inset #B71C1C' : 'solid transparent',
							cursor: 'pointer',
							padding: 0.2,
						}}
						size="small"
						onClick={() => {
							handlePageClick(value);
						}}>
						{value}
					</Button>
				</Grid>
			))}
			<Grid item xs>
				<Button
					size="small"
					onClick={() => {
						handleNext();
					}}
					sx={{ color: 'white', padding: 0, width: '100%' }}
					disabled={values.length === 1 || selectedPage === totalPages}>
					{theme.direction === 'rtl' ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
				</Button>
			</Grid>
		</Grid>
	);
};
