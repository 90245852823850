import { Avatar, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { IProductPreview } from '../../api/interfaces/interfaces';
import Badge from '@mui/material/Badge';
import persona from '../../../src/images/persona.png';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ensureLocation } from '../../functions/CommonFunctions';
import { useNavigate } from 'react-router-dom';
import PushPinIcon from '@mui/icons-material/PushPin';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

interface productCard {
	product: IProductPreview;
}

export const ProductCard = (props: productCard) => {
	const { product } = props;
	const navigate = useNavigate();
	function currencyFormatter({ currency = 'ARS', value }: any) {
		const formatter = new Intl.NumberFormat('es-AR', {});
		return formatter.format(value);
	}

	const theme = createTheme({
		palette: {
			warning: {
				// reparado
				main: '#ffffff',
				contrastText: 'orange',
			},
			info: {
				main: '#ffffff', // usado
				contrastText: 'red',
			},
			success: {
				main: '#ffffff', // nuevo
				contrastText: 'green',
			},
		},
	});

	/// funcion para cuando el cliente ingrese productos SOLO EN MAYUSCULAS
	const formatWord = (word: string) => {
		const hasMultipleUppercase = word.split('').filter(char => char === char.toUpperCase()).length > 1;
		if (hasMultipleUppercase) {
		  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
		}
		return word;
	  };
	  
	  // Función para formatear todo el texto
	  const formatText = (text: string | undefined) => {
		if (!text) return '';
		return text
		  .split(' ')
		  .map(formatWord)
		  .join(' ');
	  }; 
	  const formatProductName = formatText(product.name);
	  
	  

	return (
		<Card
			sx={{
				backgroundColor: '#111111',
				transition: 'transform .5s ease',
				'&:hover': {
					transform: 'scale(1.05)', // Cambia el tamaño cuando el cursor está sobre el elemento
					filter: 'brightness(72%)',
				},
			}}
			onClick={() => navigate(`/product-detail/${product.id}`)}>
			<ThemeProvider theme={theme}>
				<Badge
					badgeContent={product.condition}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
					slotProps={{
						badge: {
							style: {
								border: `2px solid ${product.condition === 'Nuevo' ? 'green' : product.condition === 'Usado' ? 'red' : 'yellow'}`,
							},
						},
					}}
					sx={{ marginLeft: '40px', marginTop: '1rem', color: 'white' }}></Badge>
			</ThemeProvider>
			<CardMedia
				component="img"
				height="194"
				src={product.images && product.images[0]?.imageData!}
				alt="error"
				sx={{
					// marginLeft: "5%",
					borderRadius: '1.2rem',
					boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
					padding: '10px',
					backgroundColor: '#111111',
					fontSize: '16px',
					color: '#333',
					display: 'flex',
					alignItems: 'center',
					cursor: 'pointer',
					// transition: 'transform .5s ease',
					// '&:hover': {
					// 	transform: 'scale(1.05)', // Cambia el tamaño cuando el cursor está sobre el elemento
					// 	filter: 'brightness(72%)',
					// },
				}}
			/>
			<CardContent
				sx={{
					marginTop: -2,
					backgroundColor: '#111111',
					// '&:hover': {
					// 	transform: 'scale(1.05)', // Cambia el tamaño cuando el cursor está sobre el elemento
					// 	filter: 'brightness(72%)',
					// },
				}}>
				<Grid container spacing={1} alignItems="center">
					<Grid item sx={{ height: '100%', display: 'flex', alignContent: 'flex-start' }}>
						<Avatar
							src={product?.userOwner?.image?.imageData ?? persona}
							sx={{
								backgroundColor: '#F5F5F5',
								width: 40,
								height: 40,
								marginRight: '.6rem', // toque aca	
							}}
						/>
					</Grid>
					<Grid item xs={9}>
						<Typography
							sx={{
								cursor: 'pointer',
								fontFamily: 'Roboto',
								color: '#FFFFF4',
								marginLeft: '-.5rem',
								fontSize: '1.1rem',
								marginBottom: '2px',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}>
							{formatProductName}
						</Typography>
						<Typography color="#FFFFF4" sx={{ fontSize: '1rem', verticalAlign: 'top', marginLeft: '-1rem', marginBottom: '.1rem' }}>
							<AttachMoneyIcon
								style={{
									marginRight: '-.2rem',
									marginBottom: '-.1rem',
									color: '#FFFFF4',
									fontSize: '1.4rem',
									overflow: 'hidden',
									whiteSpace: 'nowrap',
									textOverflow: 'ellipsis',
									verticalAlign: 'text-bottom'
								}}
							/>
							{currencyFormatter({ value: product.price })}
						</Typography>

					</Grid>
					<Grid container  alignItems="center" sx={{ marginLeft: '1rem' }}>
						<Typography
							sx={{
								fontFamily: 'Roboto',
								color: '#FFFFF4',
								fontSize: '0.7rem',
								marginBottom: '4px',
								display: 'flex',
								alignItems: 'center',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
							}}>
							<PushPinIcon sx={{ fontSize: '1rem' }} />
							{ensureLocation(product.city!, product.state!)}
						</Typography>
						<Grid xs={9}>
							<Typography
								sx={{
									display: 'flex',
									fontFamily: 'Roboto',
									color: '#FFFFF4',
									fontSize: '0.8rem',
									alignItems: 'center',
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
								}}>
								<CalendarMonthIcon sx={{ fontSize: '1rem', marginRight: '.2rem' }} />
								{product.publishedAt}
							</Typography>
						</Grid>
					</Grid>


				</Grid>
			</CardContent>
		</Card>
	);
};
