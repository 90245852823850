import {
	IAccountInformation,
	IPersonalInformation,
	IRelevantSellerData,
	ISellerProfile,
} from './interfaces/interfaces';
import { axiosService } from './axiosService';

export const getPersonalInformation = async (): Promise<IPersonalInformation> => {
	const data = await axiosService.get(`profile/personalInformation`).then(
		(response) => {
			if (response.status === 200) return response.data;
			else {
				console.warn(response);
				return {};
			}
		},
		(err) => {
			console.log('Error: ' + err);
		},
	);
	return data;
};

export const getAccountInformation = async (): Promise<IAccountInformation> => {
	const data = await axiosService.get(`profile/accountInformation`).then(
		(response) => {
			if (response.status === 200) return response.data;
			else {
				console.warn(response);
				return {};
			}
		},
		(err) => {
			console.log('Error: ' + err);
		},
	);
	return data;
};

export const getSellerProfile = async (): Promise<ISellerProfile> => {
	const data = await axiosService.get(`profile/sellerProfile`).then(
		(response) => {
			if (response.status === 200) return response.data;
			else {
				console.warn(response);
				return {};
			}
		},
		(err) => {
			console.log('Error: ' + err);
		},
	);
	return data;
};

export const getSellerProfileByID = async (userID: string): Promise<ISellerProfile> => {
	const data = await axiosService.get(`profile/sellerProfile/${userID}`).then(
		(response) => {
			if (response.status === 200) return response.data;
			else {
				console.warn(response);
				return {};
			}
		},
		(err) => {
			console.log('Error: ' + err);
		},
	);
	return data;
};

export const updateAccountInformation = async (accountData: IAccountInformation): Promise<any> => {
	const data = await axiosService.patch(`profile/updateAccountInformation`, accountData).then(
		(response) => {
			return response;
		},
		(err) => {
			return { status: 500 };
		},
	);
	return data;
};

export const updatePersonalInformation = async (personalData: IPersonalInformation): Promise<any> => {
	const data = await axiosService.patch(`profile/updatePersonalInformation`, personalData).then(
		(response) => {
			return response;
		},
		(err) => {
			return { status: 500 };
		},
	);
	return data;
};

export const updateSellerProfile = async (sellerData: ISellerProfile): Promise<any> => {
	const sellerDataUpdated = {
		sellerName: sellerData.sellerName,
		sellerDescription: sellerData.sellerDescription,
		sellerPhone: sellerData.sellerPhone,
		state: sellerData.state,
		city: sellerData.city,
		image: sellerData.image,
	};
	const data = await axiosService.patch(`profile/updateSellerInformation`, sellerDataUpdated).then(
		(response) => {
			return response;
		},
		(err) => {
			return { status: 500 };
		},
	);
	return data;
};

export const updateUserPassword = async (actualPassword: string, newPassword: string): Promise<any> => {
	const data = await axiosService.patch(`users/updatePassword`, { actualPassword, newPassword }).then(
		(response) => {
			return response;
		},
		(err) => {
			return { status: 500 };
		},
	);
	return data;
};

export const getRelevantSellerDataByID = async (productOwnerID: string): Promise<IRelevantSellerData> => {
	const data = await axiosService.get(`profile/relevantSellerData/${productOwnerID}`).then(
		(response) => {
			if (response.status === 200) return response.data;
			else {
				console.warn(response);
				return {};
			}
		},
		(err) => {
			console.log('Error: ' + err);
		},
	);
	return data;
};

export const deleteAccount = async (): Promise<any> => {
	const data = await axiosService.delete(`users/deleteAccount`).then(
		(response) => {
			return response;
		},
		(err) => {
			return { status: 500 };
		},
	);
	return data;
};
