import { register } from './../../api/authApi';
import { Link, useNavigate } from 'react-router-dom';
import {
	Button,
	Grid,
	TextField,
	Typography,
	Card,
	CircularProgress,
	FormControl,
	InputLabel,
	OutlinedInput,
	InputAdornment,
	IconButton,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { IAlert, INewUser } from '../../api/interfaces/interfaces';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box } from '@mui/system';
import { CustomAlert } from '../utils/CustomAlert';

export const RegisterPage = () => {
	const navigate = useNavigate();
	const [password2, setPassword2] = useState<string>('');
	const [showError, setShowError] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [newUser, setNewUser] = useState<INewUser>({
		email: '',
		password: '',
		name: '',
		lastname: '',
	});
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		type: 'info',
		message: '',
		show: false,
	});

	const isEmailValid = (email: string) => {
		const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return regexEmail.test(email);
	};
	const doesFieldOnlyContainStrings = (field: string) => {
		const regex = /^[a-zA-Z\s]+$/;
		return regex.test(field);
	};

	const isFormValid = () => {
		return (
			doesFieldOnlyContainStrings(newUser.lastname) &&
			doesFieldOnlyContainStrings(newUser.name) &&
			isEmailValid(newUser.email) &&
			newUser.password === password2 &&
			newUser.password !== '' &&
			password2 !== '' &&
			newUser.password.length >= 8 &&
			password2.length >= 8
		);
	};
	const handleChangeForm = useCallback((fieldName: keyof INewUser, value: string) => {
		setNewUser((prevProduct) => ({
			...prevProduct,
			[fieldName]: value,
		}));
	}, []);
	const handleConfirmPassword = useCallback((value: string) => {
		setPassword2(value);
	}, []);

	//const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleClickShowPassword = useCallback((value: number) => {
		console.log(value);
		if (value === 1) {
			console.log('entrePor el fi');
			setShowPassword((show) => !show);
		} else {
			console.log('entre por el else');
			setShowConfirmPassword((show) => !show);
		}
	}, []);

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	const keyPress = (e: any) => {
		if (e.keyCode === 13) sendRegister();
	};

	const sendRegister = async () => {
		setIsLoading(true);
		const rta = await register(newUser);
		setIsLoading(false);
		if (rta.status !== 201) {
			if (rta.response) {
				const { response } = rta;
				if (response.status === 409) {
					const {
						data: {
							payload: { message },
						},
					} = response;
					setErrorMessage(message);
				}
			} else setErrorMessage('Error en servidor.');
			setShowError(true);
		} else {
			setAlert({
				type: 'success',
				message: 'Usuario registrado exitosamente!',
				show: true,
			});
			setTimeout(() => {
				navigate('/login');
			}, 1500);
		}
	};

	return (
		<Grid
			container
			spacing={1}
			direction="column"
			alignItems="center"
			justifyContent="center"
			sx={{ minHeight: { xs: '95vh', md: '92vh' }, backgroundColor: '#111111' }}>
			<Card
				sx={{
					width: { xs: '90%', sm: '80%', md: '40%' },
					margin: { sm: 'auto' },
					mr: { xs: '-2%' },
					textAlign: 'center',
					height: 'auto',
					borderRadius: '22px',
					border: '1px inset #F44336',
					backgroundColor: '#111111',
				}}>
				<Grid item xs={12}>
					<Typography
						sx={{
							marginTop: '5%',
							fontSize: '2rem',
							color: '#FFF4F4',
						}}>
						Registrarse
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					sx={{
						marginTop: 2,
						marginBottom: 10,
						width: { xs: '90%', sm: '80%', md: '50%' },
						margin: 'auto',
					}}>
					<TextField
						label="Nombre"
						type="text"
						fullWidth
						name="name"
						value={newUser.name}
						onChange={({ currentTarget: { value } }) => handleChangeForm('name', value)}
						InputLabelProps={{
							style: {
								color: 'white', // Color del texto del placeholder
								opacity: 0.6, // Opacidad del placeholder
							},
						}}
						sx={{
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor: 'white', // Bordes blancos
								},
								'&:hover fieldset': {
									borderColor: 'white', // Bordes blancos cuando se pasa el cursor
								},
								'&.Mui-focused fieldset': {
									borderColor: '#3F51B5', // Bordes azules cuando está enfocado
								},
								backgroundColor: '#181818', // Fondo negro
								color: 'white', // Texto blanco
							},
							marginTop: '5%',
							'& input:-webkit-autofill, & textarea:-webkit-autofill, & select:-webkit-autofill': {
								// al activarse el autocomplete (auto-fill)
								WebkitTextFillColor: 'white', // font color
								transition: 'background-color 5000s ease-in-out 0s', // background color
							},
						}}
					/>
					{newUser.name !== '' && !doesFieldOnlyContainStrings(newUser.name) && (
						<Typography color="red">Se deben ingresar caracteres alfabéticos</Typography>
					)}
					<TextField
						label="Apellido"
						type="text"
						placeholder=""
						fullWidth
						name="lastname"
						value={newUser.lastname}
						onChange={({ currentTarget: { value } }) => handleChangeForm('lastname', value)}
						InputLabelProps={{
							style: {
								color: 'white', // Color del texto del placeholder
								opacity: 0.6, // Opacidad del placeholder
							},
						}}
						sx={{
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor: 'white', // Bordes blancos
								},
								'&:hover fieldset': {
									borderColor: 'white', // Bordes blancos cuando se pasa el cursor
								},
								'&.Mui-focused fieldset': {
									borderColor: '#3F51B5', // Bordes azules cuando está enfocado
								},
								backgroundColor: '#181818', // Fondo negro
								color: 'white', // Texto blanco
							},
							marginTop: '5%',
							'& input:-webkit-autofill, & textarea:-webkit-autofill, & select:-webkit-autofill': {
								// al activarse el autocomplete (auto-fill)
								WebkitTextFillColor: 'white', // font color
								transition: 'background-color 5000s ease-in-out 0s', // background color
							},
						}}
					/>
					{newUser.lastname !== '' && !doesFieldOnlyContainStrings(newUser.lastname) && (
						<Typography color="red">Se deben ingresar caracteres alfabéticos</Typography>
					)}
				</Grid>
				<Grid
					item
					xs={12}
					sx={{
						marginTop: 2,
						marginBottom: 10,
						width: { xs: '90%', sm: '80%', md: '50%' },
						margin: 'auto',
					}}>
					<TextField
						label="Correo Electrónico"
						type="email"
						placeholder="correoelectronico@gmail.com"
						fullWidth
						name="email"
						value={newUser.email}
						onChange={({ currentTarget: { value } }) => handleChangeForm('email', value)}
						error={newUser.email !== '' && !isEmailValid(newUser.email)}
						InputLabelProps={{
							style: {
								color: 'white', // Color del texto del placeholder
								opacity: 0.6, // Opacidad del placeholder
							},
						}}
						sx={{
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor: 'white', // Bordes blancos
								},
								'&:hover fieldset': {
									borderColor: 'white', // Bordes blancos cuando se pasa el cursor
								},
								'&.Mui-focused fieldset': {
									borderColor: '#3F51B5', // Bordes azules cuando está enfocado
								},
								backgroundColor: '#181818', // Fondo negro
								color: 'white', // Texto blanco
							},
							marginTop: '5%',
							'& input:-webkit-autofill, & textarea:-webkit-autofill, & select:-webkit-autofill': {
								// al activarse el autocomplete (auto-fill)
								WebkitTextFillColor: 'white', // font color
								transition: 'background-color 5000s ease-in-out 0s', // background color
							},
						}}
					/>
					{newUser.email !== '' && !isEmailValid(newUser.email) && (
						<Typography color="red">Formato del email ingresado no válido</Typography>
					)}
				</Grid>
				<Grid
					item
					xs={12}
					sx={{
						mt: 5,
						width: { xs: '90%', sm: '80%', md: '50%' },
						margin: 'auto',
					}}>
					<FormControl
						sx={{
							borderRadius: '5px',
							backgroundColor: '#eef3f8',
							border: '1px solid white', // Borde predeterminado
							'&:focus-within': {
								borderColor: '#3F51B5', // Borde azul cuando está enfocado
							},
							marginTop: '5%',
							width: '100%',
							minWidth: '100%',
							maxWidth: '100%',
						}}
						variant="outlined">
						<InputLabel
							sx={{
								color: 'white', // Color del texto del label
								opacity: 0.6,
								'&.Mui-focused': {
									color: 'white',
									opacity: 0.6,
									backgroundColor: '#111111',
								},
							}}>
							Contraseña
						</InputLabel>
						<OutlinedInput
							placeholder="Contraseña"
							sx={{
								backgroundColor: '#181818',
								'& input': {
									color: 'white', // Color del texto
								},
							}}
							type={showPassword ? 'text' : 'password'}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										sx={{ color: 'white', opacity: 0.6 }}
										onClick={() => handleClickShowPassword(1)}
										onMouseDown={handleMouseDownPassword}
										edge="end">
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}
							label="Password"
							onKeyDown={(e) => keyPress(e)}
							onChange={({ currentTarget: { value } }) => handleChangeForm('password', value)}
						/>
					</FormControl>
					{newUser.password.length < 8 && newUser.password.length !== 0 && (
						<Typography color="red">La contraseña debe tener al menos 8 caracteres</Typography>
					)}
				</Grid>
				<Grid
					item
					xs={12}
					sx={{
						mt: 5,
						width: { xs: '90%', sm: '80%', md: '50%' },
						margin: 'auto',
					}}>
					<FormControl
						sx={{
							borderRadius: '5px',
							backgroundColor: '#eef3f8',
							border: '1px solid white', // Borde predeterminado
							'&:focus-within': {
								borderColor: '#3F51B5', // Borde azul cuando está enfocado
							},
							marginTop: '5%',
							width: '100%',
							minWidth: '100%',
							maxWidth: '100%',
						}}
						variant="outlined">
						<InputLabel
							sx={{
								color: 'white', // Color del texto del label
								opacity: 0.6,
								'&.Mui-focused': {
									color: 'white',
									opacity: 0.6,
									backgroundColor: '#111111',
								},
							}}>
							Confirmar contraseña
						</InputLabel>
						<OutlinedInput
							placeholder="Confirmar Contraseña"
							sx={{
								backgroundColor: '#181818',
								'& input': {
									color: 'white', // Color del texto
								},
							}}
							type={showConfirmPassword ? 'text' : 'password'}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										sx={{ color: 'white', opacity: 0.6 }}
										onClick={() => handleClickShowPassword(2)}
										onMouseDown={handleMouseDownPassword}
										edge="end">
										{showConfirmPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}
							label="password2"
							onKeyDown={(e) => keyPress(e)}
							onChange={({ currentTarget: { value } }) => handleConfirmPassword(value)}
						/>
					</FormControl>
					{newUser.password !== password2 && newUser.password !== '' && password2 !== '' && (
						<Typography color="red" margin={0}>
							Las contraseñas no coinciden
						</Typography>
					)}
				</Grid>
				<Grid container item spacing={2} justifyContent="space-between" sx={{ mt: 2 }}>
					<Grid
						item
						xs={12}
						sm={6}
						sx={{
							mt: 5,
							width: { xs: '90%', sm: '80%', md: '50%' },
							margin: 'auto',
						}}>
						<Button
							sx={{
								width: '80%',
								backgroundColor: '#181818',
								border: '1px inset #E57373',
								borderRadius: '1rem',
								color: 'white',
								'&.Mui-disabled': {
									bgcolor: '#111',
									color: 'white',
									opacity: 0.5
								}
							}}
							type="submit"
							variant="contained"
							onClick={sendRegister}
							disabled={isLoading || !isFormValid()}>
							{isLoading ? <CircularProgress size={24} color="inherit" /> : 'Registrarse'}
						</Button>
						{showError && <Typography>{errorMessage}</Typography>}
					</Grid>
				</Grid>
				<Grid container item justifyContent="center" sx={{ mb: 3 }}>
					<Box sx={{ margin: 'auto', mt: '.7rem' }}>
						<Typography sx={{ mb: '5px', mr: '5px', color: '#FFEBEE' }}>¿Ya tenes cuenta? ¡Ingresa!</Typography>
						<Link to="/login" color="inherit" style={{ marginLeft: '10px', color: '#E57373' }}>
							Ingresar
						</Link>
					</Box>
				</Grid>
				{alert.show && <CustomAlert message={alert.message} setAlert={setAlert} type={alert.type} />}
			</Card>
		</Grid>
	);
};
