import { ICategory } from "./interfaces/interfaces";
import { axiosService } from "./axiosService";

export const getCategoriesLeaves = async (): Promise<ICategory[]> => {
  const data = await axiosService.get(`categories/categoriesLeaves`).then((response)=> {
    if(response.status === 200)
      return response.data;
    else{
      console.warn(response);
      return [];
    }
  }, (err)=> {console.log('Error: ' + err)});
  return data;
};

export const getCategoryTreeByLeaf = async (leafID: string): Promise<ICategory[]> => {
  const data = await axiosService.get(`categories/categoryTreeByLeaf/${leafID}`).then((response)=> {
    if(response.status === 200)
      return response.data;
    else{
      console.warn(response);
      return [];
    }
  }, (err)=> {console.log('Error: ' + err)});
  return data;
};