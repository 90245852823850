import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface CustomDialogProps {
  handleOpenClose: () => void;
  handleConfirm: () => void;
  open: boolean;
  title?: string;
  body?: string;
}
export default function CustomDialog(props: CustomDialogProps) {
  const { handleConfirm, handleOpenClose, open, title, body } = props;

  return (
    <>
      <Dialog
        hideBackdrop={true}
        fullScreen={false}
        open={open}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: {
            boxShadow:
              "0px 3px 15px 2px rgba(0,0,0,0.1),5px 5px 100vh 100vh rgba(0,0,0,0.06),0px 0px 0px 0px rgba(0,0,0,0.0)",
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{body}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleOpenClose}>
            Cancelar
          </Button>
          <Button onClick={handleConfirm} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
