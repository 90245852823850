import axios from "axios";
import { IState } from "./interfaces/interfaces";

export const getArgentinianStates = async (
  fields: string
): Promise<IState[]> => {
  const data = await axios
    .get(`https://apis.datos.gob.ar/georef/api/provincias?campos=${fields}`)
    .then(
      (response) => {
        const { data, status } = response;
        if (status === 200) return data.provincias;
        return [];
      },
      (err) => {
        console.error("Error: " + err);
        return [];
      }
    );
  return data;
};

export const getMunicipalitiesByState = async (
  stateID: string
): Promise<IState[]> => {
  const data = await axios
    .get(
      `https://apis.datos.gob.ar/georef/api/municipios?provincia=${stateID}&campos=id,nombre&max=300`
    )
    .then(
      (response) => {
        const { data, status } = response;
        if (status === 200) return data.municipios;
        return [];
      },
      (err) => {
        console.error("Error: " + err);
        return [];
      }
    );
  return data;
};