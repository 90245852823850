import { createContext, useContext} from "react";

export type GlobalContextType = {searchedText: string, setSearchedText: (value: string)=> void, executeSearch: boolean, setExecuteSearch: ()=> void};

export const GlobalContext = createContext<GlobalContextType>({
  searchedText: '',
  executeSearch: false,
  setSearchedText: ()=> {},
  setExecuteSearch: ()=>{}
});


export const UseGlobalContext = () => useContext(GlobalContext);