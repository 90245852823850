import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  InputBase,
  Paper,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  InputAdornment,
  TextField,
  MenuItem,
  Select,
  Modal,
  SelectChangeEvent,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import { styled } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import { CreateInsuredData, Insured } from '../../../api/interfaces/interfaces';
import { createInsured, getAllInsured } from '../../../api/insuredApi';


const StyledTableCell = styled(TableCell)({
    color: '#333',
  });
  
  const StatsBox = styled(Box)({
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
  });
  
  const SearchBox = styled(Paper)({
    display: 'flex',
    width: '40%',
    alignItems: 'center',
    marginBottom: 16,
    padding: '4px 16px',
    borderRadius: 20,
    border: '1px solid #E0E0E0',
  });

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };

export const InsuredView = () => {

    const [tabIndex, setTabIndex] = React.useState(0);
    const [insuredList, setInsuredList] = useState<Insured[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [formData, setFormData] = useState<CreateInsuredData>({
      firstname: "",
      lastname: "",
      email: "",
      personType: "Física",
      policyNumber: "",
      docType: "DNI",
      cuit: "",
      country: "",
      province: "",
      city: "",
      street: "",
      cellphone: "",
      typeIVA: "",
      birthday: "",
      maritalStatus: "",
      nacionality: "",
      zipcode: "",
    });
    const [activeStep, setActiveStep] = useState(0);
  
    const steps = ["Datos Personales", "Dirección y Datos Adicionales"];
    const handleOpen = () => setModalOpen(true);
    const handleClose = () => setModalOpen(false);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const data = await getAllInsured();
          setInsuredList(data);
        } catch (err) {
          setError('Error al cargar los asegurados');
          console.error(err);
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }, []);
  
    const activeInsured = insuredList.map(a => a.status === "Activo")
   
  
    const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
      setTabIndex(newValue);
    };
  
    const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setFormData((prev) => ({ ...prev, [name]: value }));
    };
  
    const handleSelectChange = (e: SelectChangeEvent<string>) => {
      const { name, value } = e.target;
      setFormData((prev) => ({ ...prev, [name!]: value }));
    };
  
    const handleSubmit = async () => {
      setLoading(true);
      const result = await createInsured(formData);
      setLoading(false);
  
      if (result) {
        alert("Asegurado creado exitosamente");
        handleClose();
      } else {
        alert("Hubo un error al crear el asegurado");
      }
    };
  
    const handleNext = () => {
      setActiveStep((prevStep) => prevStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prevStep) => prevStep - 1);
    };




  return (
    <>
     <Button variant="contained" onClick={handleOpen}  sx={{position:  'absolute', right: 300, top: 200}}>
          Nuevo Asegurado
        </Button>
        <Modal open={modalOpen} onClose={handleClose}>
          <Box sx={style}>
            <Typography variant="h6" marginBottom={2}>
              Crear Nuevo Asegurado
            </Typography>

            <Grid container spacing={2}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={index}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              <Box sx={{ marginTop: 2 }}>
                {activeStep === 0 ? (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Nombre"
                        name="firstname"
                        value={formData.firstname}
                        onChange={handleTextFieldChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Apellido"
                        name="lastname"
                        value={formData.lastname}
                        onChange={handleTextFieldChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleTextFieldChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Número de Póliza"
                        name="policyNumber"
                        value={formData.policyNumber}
                        onChange={handleTextFieldChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        fullWidth
                        name="personType"
                        value={formData.personType}
                        onChange={handleSelectChange}
                        displayEmpty
                      >
                        <MenuItem value="" disabled>
                          Tipo de Persona
                        </MenuItem>
                        <MenuItem value="Fisica">Física</MenuItem>
                        <MenuItem value="Juridica">Jurídica</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        fullWidth
                        name="docType"
                        value={formData.docType}
                        onChange={handleSelectChange}
                        displayEmpty
                      >
                        <MenuItem value="" disabled>
                          Tipo de Documento
                        </MenuItem>
                        <MenuItem value="DNI">DNI</MenuItem>
                        <MenuItem value="CUIT">CUIT</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="CUIT"
                        name="cuit"
                        value={formData.cuit}
                        onChange={handleTextFieldChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        type="date"
                        label="Fecha de Nacimiento"
                        name="birthday"
                        value={formData.birthday}
                        onChange={handleTextFieldChange}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Estado Civil"
                        name="maritalStatus"
                        value={formData.maritalStatus}
                        onChange={handleTextFieldChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Nacionalidad"
                        name="nacionality"
                        value={formData.nacionality}
                        onChange={handleTextFieldChange}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="País"
                        name="country"
                        value={formData.country}
                        onChange={handleTextFieldChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Provincia"
                        name="province"
                        value={formData.province}
                        onChange={handleTextFieldChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Ciudad"
                        name="city"
                        value={formData.city}
                        onChange={handleTextFieldChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Calle"
                        name="street"
                        value={formData.street}
                        onChange={handleTextFieldChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Celular"
                        name="cellphone"
                        value={formData.cellphone}
                        onChange={handleTextFieldChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        fullWidth
                        name="typeIVA"
                        value={formData.typeIVA}
                        onChange={handleSelectChange}
                      >
                        <MenuItem value="Responsable Inscripto">Responsable Inscripto</MenuItem>
                        <MenuItem value="Monotributista">Monotributista</MenuItem>
                        <MenuItem value="Exento">Exento</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Código Postal"
                        name="zipcode"
                        value={formData.zipcode}
                        onChange={handleTextFieldChange}
                      />
                    </Grid>
                  </Grid>
                )}

                <Box sx={{ marginTop: 2 }}>
                  <Button onClick={handleBack} disabled={activeStep === 0} variant="contained" color="secondary">
                    Atras
                  </Button>
                  {
                    activeStep !== steps.length - 1 &&
                    <Button onClick={handleNext} variant="contained" sx={{ marginLeft: 2 }}>
                    Siguiente
                  </Button>
                  }
                  {activeStep === steps.length - 1 && (
                    <Button onClick={handleSubmit} variant="contained" sx={{ marginLeft: 2 }}>
                      Crear Asegurado
                    </Button>
                  )}
                </Box>
              </Box>
            </Grid>
          </Box>
        </Modal>
    <Grid container spacing={2} marginTop={2}>
    <Grid item xs={12} sm={6}>
      <StatsBox>
        <Typography variant="h3" color="primary">
          {activeInsured.length}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Asegurados activos
        </Typography>
      </StatsBox>
    </Grid>
    <Grid item xs={12} sm={6}>
      <StatsBox>
        <Typography variant="h3" color="primary">
        {insuredList.length}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Asegurados totales
        </Typography>
      </StatsBox>
    </Grid>
  </Grid>

  <TableContainer component={Paper} sx={{ marginTop: 4, padding: 2 }}>

    <SearchBox>
      <InputBase
        placeholder="Buscar siniestro, póliza o estado"
        fullWidth
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />
    </SearchBox>
    <Table>
      <TableHead>
        <TableRow>
          <StyledTableCell>ID</StyledTableCell>
          <StyledTableCell>Tipo de persona</StyledTableCell>
          <StyledTableCell>Apellido</StyledTableCell>
          <StyledTableCell>Nombre</StyledTableCell>
          <StyledTableCell>Tipo</StyledTableCell>
          <StyledTableCell>CUIT</StyledTableCell>
          <StyledTableCell>País</StyledTableCell>
          <StyledTableCell>Provincia</StyledTableCell>
          <StyledTableCell>Ciudad</StyledTableCell>
          <StyledTableCell>Calle</StyledTableCell>
          <StyledTableCell>Número</StyledTableCell>
          <StyledTableCell>Celular</StyledTableCell>
          <StyledTableCell>IVA</StyledTableCell>
          <StyledTableCell>Correo</StyledTableCell>
          <StyledTableCell>Fecha de nac</StyledTableCell>
          <StyledTableCell>Estado civil</StyledTableCell>
          <StyledTableCell>Estado</StyledTableCell>
          <StyledTableCell>CP</StyledTableCell>
          <StyledTableCell>Nacionalidad</StyledTableCell>
          <StyledTableCell>Creado</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {insuredList.map((insured, index) => (
          <TableRow key={index}>
            <TableCell>{insured.id}</TableCell>
            <TableCell>{insured.personType}</TableCell>
            <TableCell>{insured.lastname}</TableCell>
            <TableCell>{insured.firstname}</TableCell>
            <TableCell>{insured.docType}</TableCell>
            <TableCell>{insured.cuit}</TableCell>
            <TableCell>{insured.country}</TableCell>
            <TableCell>{insured.province}</TableCell>
            <TableCell>{insured.city}</TableCell>
            <TableCell>{insured.street}</TableCell>
            <TableCell>{insured.floor}</TableCell>
            <TableCell>{insured.cellphone}</TableCell>
            <TableCell>{insured.typeIVA}</TableCell>
            <TableCell>{insured.email}</TableCell>
            <TableCell>{insured.birthday}</TableCell>
            <TableCell>{insured.maritalStatus}</TableCell>
            <TableCell>{insured.status}</TableCell>
            <TableCell>{insured.zipcode}</TableCell>
            <TableCell>{insured.nacionality}</TableCell>
            <TableCell>{insured.createdAt}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  </>
  )
}
