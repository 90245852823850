import { Box, Button, Card, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { sendRecoveryPassword } from '../../api/authApi';

export const RecoverPassword = () => {
	const [recoveryEmail, setRecoveryEmail] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [showMessageEmailSent, setShowMessageEmailSent] = useState<boolean>(false);

	const invalidateUsername = (username: string) => {
		if (username === '') {
			return true;
		}
		const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return regexEmail.test(username) ? false : true;
	};

	const handleChangeForm = useCallback((value: string) => {
		setRecoveryEmail(value);
	}, []);

	const onSendRecovery = async () => {
		setIsLoading(true);
		await sendRecoveryPassword(recoveryEmail);
		setIsLoading(false);
		setShowMessageEmailSent(true);
	};

	useEffect(() => {
    let timer: NodeJS.Timeout;
    if (showMessageEmailSent) {
      timer = setTimeout(() => {
        setShowMessageEmailSent(false);
				setRecoveryEmail('');
      }, 10000); // 10 seconds
    }
    return () => clearTimeout(timer);
  }, [showMessageEmailSent]);

	return (
		<Grid
			container
			spacing={1}
			direction="column"
			alignItems="center"
			justifyContent="center"
			sx={{ minHeight: { xs: '95vh', md: '92vh' }, backgroundColor: '#111111' }}>
			<Card
				sx={{
					width: { xs: '90%', sm: '80%', md: '40%' },
					margin: { sm: 'auto' },
					mr: { xs: '-2%' },
					textAlign: 'center',
					height: 'auto',
					borderRadius: '22px',
					border: '1px inset #F44336',
					backgroundColor: '#111111',
				}}>
				<Grid item xs={12}>
					<Typography
						sx={{
							marginTop: '5%',
							fontSize: '2rem',
							color: '#FFF4F4',
						}}>
						Recuperar Contraseña
					</Typography>
				</Grid>
				<Grid container item justifyContent="center" sx={{ mt: 2 }}>
					<Box sx={{ margin: 'auto' }}>
						<Typography sx={{ mb: '5px', mr: '5px', color: '#FFEBEE' }}>
							Por favor, ingrese a continuación su correo electrónico para restablecer la contraseña mediante el mail
							que acabamos de enviarle
						</Typography>
					</Box>
				</Grid>
				<Grid
					item
					xs={12}
					sx={{
						marginTop: 2,
						marginBottom: 10,
						width: { xs: '90%', sm: '80%', md: '50%' },
						margin: 'auto',
					}}>
					<TextField
						label="Correo Electrónico"
						type="email"
						placeholder="correoelectronico@gmail.com"
						fullWidth
						name="email"
						value={recoveryEmail}
						onChange={({ currentTarget: { value } }) => handleChangeForm(value)}
						error={invalidateUsername(recoveryEmail)}
						InputLabelProps={{
							style: {
								color: 'white', // Color del texto del placeholder
								opacity: 0.6, // Opacidad del placeholder
							},
						}}
						sx={{
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor: 'white', // Bordes blancos
								},
								'&:hover fieldset': {
									borderColor: 'white', // Bordes blancos cuando se pasa el cursor
								},
								'&.Mui-focused fieldset': {
									borderColor: '#3F51B5', // Bordes azules cuando está enfocado
								},
								backgroundColor: '#181818', // Fondo negro
								color: 'white', // Texto blanco
							},
							marginTop: '5%',
							'& input:-webkit-autofill, & textarea:-webkit-autofill, & select:-webkit-autofill': {
								// al activarse el autocomplete (auto-fill)
								WebkitTextFillColor: 'white', // font color
								transition: 'background-color 5000s ease-in-out 0s', // background color
							},
						}}
					/>
				</Grid>
				<Grid container item spacing={2} justifyContent="space-between" sx={{ mt: 2 }}>
					<Grid item xs={8} sm={6} sx={{ margin: 'auto', mt: '-2%' }}>
						<Button
							style={{
								width: '80%',
								backgroundColor: '#181818',
								border: invalidateUsername(recoveryEmail) ? '' : '1px inset #E57373',
								borderRadius: '1rem',
								color: 'white',
							}}
							type="submit"
							variant="contained"
							onClick={onSendRecovery}
							disabled={invalidateUsername(recoveryEmail) || isLoading}>
							{isLoading ? <CircularProgress size={24} color="inherit" /> : 'Recuperar'}
						</Button>
					</Grid>
				</Grid>
				{showMessageEmailSent && (
					<Grid container item justifyContent="center" sx={{ mt: 2 }}>
						<Box sx={{ margin: 'auto', backgroundColor: 'success.main', padding: 2, borderRadius: 1 }}>
							<Typography sx={{ mb: '5px', mr: '5px', color: '#FFEBEE' }}>
								El correo electrónico se ha enviado
							</Typography>
						</Box>
					</Grid>
				)}
				<Grid container item justifyContent="center" sx={{ mb: 3 }}>
					<Box sx={{ margin: 'auto', mt: '.7rem' }}>
						<Typography sx={{ mb: '5px', mr: '5px', color: '#FFEBEE' }}>
							¿Recordaste tu Contraseña? ¡Ingresa!
						</Typography>
						<Link to="/login" color="inherit" style={{ marginLeft: '10px', color: '#E57373' }}>
							Ingresar
						</Link>
					</Box>
				</Grid>
				<Grid container item justifyContent="center" sx={{ mt: 3 }}></Grid>
			</Card>
		</Grid>
	);
};
