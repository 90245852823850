import { Card } from '@mui/material';
import { IRecivedAnsers, IRecivedAsks } from '../../api/interfaces/interfaces';
import { AnswerContentComponent } from './AnswerContentComponent';
import { AskContentComponent } from './AskContentComponent';
interface CardContentComponentProps {
	answer?: IRecivedAnsers;
	ask?: IRecivedAsks;
}

export const CardContentComponent = (props: CardContentComponentProps) => {
	const { answer, ask } = props;
	return (
		<Card style={{ padding: 2, margin: 2, backgroundColor: '#1c1c1c' }}>
			{answer && <AnswerContentComponent answer={answer}/>}
			{ask && <AskContentComponent ask={ask}/>}
		</Card>
	);
};
