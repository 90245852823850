import { useCallback, useEffect, useState } from 'react';
import {
	AppBar,
	Box,
	CssBaseline,
	IconButton,
	Menu,
	MenuItem,
	Toolbar,
	Typography,
	Grid,
	TextField,
	useMediaQuery,
	MenuProps,
	styled,
	Avatar,
} from '@mui/material';
import { LockOpenSharp, PersonAddAltOutlined } from '@mui/icons-material';
import { Link, Outlet, useLocation } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import logo from '../../../src/images/LogoR.png';
import { logout } from '../../api/authApi';
import { UseUserContext } from '../../provider/UserContext';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import { getCommetsCountWithoutResponseByProductOwner } from '../../api/commentsApi';
import AddIcon from '@mui/icons-material/Add';
import { UseGlobalContext } from '../../provider/GlobalContext';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { MenuNotifications } from '../notifications/MenuNotifications';
import { getUserNotifications } from '../../api/utilsApi';
import { INotification } from '../../api/interfaces/interfaces';
import HomeIcon from '@mui/icons-material/Home';
import BuildIcon from '@mui/icons-material/Build';
import KeyboardIcon from '@mui/icons-material/Keyboard';
const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={10}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 180,
		backgroundColor: '#282828',
		color: 'white',
	},
}));

export default function NavBar() {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [anchorElNoti, setAnchorNoti] = useState<HTMLButtonElement | null>(null);
	const [countQuestions, setCountQuestions] = useState<number>(0);
	const [notifications, setNotifications] = useState<INotification>();

	const { searchedText, setSearchedText, setExecuteSearch } = UseGlobalContext();
	const userContext = UseUserContext();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const isSmallScreen = useMediaQuery('(max-width:500px)');

	const loadCountQuestions = async () => {
		const count = await getCommetsCountWithoutResponseByProductOwner();
		if (count !== countQuestions) setCountQuestions(count);
	};

	const handleSearch = () => {
		if (pathname !== '/') navigate('/');
		setExecuteSearch();
	};
	const getNotifications = async () => {
		const response = await getUserNotifications();
		setNotifications(response);
	};
	useEffect(() => {
		//Esto implementado de esta forma evita multiples peticiones por re-render.
		getNotifications();
		loadCountQuestions();
		const intervalId = setInterval(() => loadCountQuestions(), 60000);
		const intervalId2 = setInterval(() => getNotifications(), 60000);
		return () => {
			clearInterval(intervalId);
			clearInterval(intervalId2);
		};
	}, []);

	const handleInputChange = useCallback(
		(value: string) => {
			setSearchedText(value);
			if (value.includes('\n')) {
				const activeElement = document.activeElement;
				if (activeElement instanceof HTMLInputElement) {
					activeElement.blur();
				}
			}
		},
		[setSearchedText],
	);

	function adjustText(value: string): string {
		return value === undefined ? '' : value?.length > 15 ? value?.substring(0, 15) + '...' : value;
	}

	// corrección para solucionar la recarga al momento de estar en la vista inicial
	const handleLogoClick = () => {
		setExecuteSearch();
		if (pathname === '/') {
			window.location.reload();
		}
	};


	return (
		<Box sx={{ display: 'flex' }}>
			<Outlet />
			<CssBaseline />
			<AppBar position="fixed" sx={{ display: 'grid', backgroundColor: '#111111' }} key={'AppBar'}>
				<Toolbar key={'Toolbar1'} sx={{ width: '100%', padding: 0 }}>
					<Grid container direction="row" alignItems="center" key={'Grid'}>
						<Link to={'/'} key={'Button1'} style={{ textDecoration: 'none', color: 'inherit' }}>
							<img
								src={logo}
								alt="logo"
								style={{ maxWidth: '100%', maxHeight: '6vh', minHeight: '4vh' }}
								key={'img'}
								onClick={() => handleLogoClick()}
							/>
						</Link>
					</Grid>
					<Toolbar
						sx={{ backgroundColor: '#111111', padding: 0, width: '100%', mr: { xl: '10vw', md: '10vw', xs: '0' } }}
						key={'Toolbar9'}>
						{userContext.token !== undefined && userContext.token !== '' && (
							<Box
								sx={{
									height: { xs: '5.2vh', sm: '5.5vh', md: '5.5vh' },
									width: { xs: '60.8vw', sm: '3  0.5vw', md: '30.5vw' },
								}}
								key="box1">
								<TextField
									onChange={({ target: { value } }) => handleInputChange(value)}
									onKeyDown={(e) => e.key === 'Enter' && searchedText.length > 3 && handleSearch()}
									variant="outlined"
									placeholder="Buscar repuestos…"
									fullWidth
									key="TextField"
									value={searchedText}
									InputProps={{
										startAdornment: (
											<IconButton
												onClick={() => searchedText.length > 3 && handleSearch()}
												edge="start"
												aria-label="search"
												key="iconbtn"
												sx={{ p: 1, borderRadius: 10 }}>
												<SearchIcon sx={{ fontSize: '1.5rem', color: '#717171' }} key="search" />
											</IconButton>
										),
										sx: {
											backgroundColor: 'transparent',
											outline: 'none',
											color: 'white',
											height: '5vh',
											/// quitar el borde negro del NavBar al hacer hover/click
											'&:hover .MuiOutlinedInput-notchedOutline': {
												borderColor: 'transparent',
											},
											'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
												borderColor: 'transparent',
											},
											'& .MuiOutlinedInput-notchedOutline': {
												borderColor: 'transparent',
											},
										},
									}}
									sx={{
										border: 'solid thin',
										color: '#717171',
										borderColor: '#717171',
										// ':focus': {
										// 	outline: 'none',
										// 	bordeColor: 'blue',
										// },
										borderRadius: 10,
									}}
								/>
							</Box>
						)}
					</Toolbar>

					<Toolbar sx={{ backgroundColor: '#111111', padding: 0, paddingLeft: '0' }} key={'tool'}>
						{userContext.token !== undefined &&
							userContext.token !== '' && [
								<>
									{/* Condición para ocultar el componente cuando la pantalla es menor a 750px */}
									<style>{`
                        @media screen and (max-width: 500px) {
                            .hideBelow500px {
                                display: none;
                            }
                        }
                    `}</style>
								</>,
								<Button
									className="hideBelow500px" // Agregar la clase para ocultar el botón
									sx={{
										backgroundColor: '#111111',
										minWidth: 'max-content',
										color: 'white',
										marginTop: '.5rem',
										marginRight: '-1rem',
										transition: 'transform 0.3s ease',
										'&:hover': {
											animation: 'shake .8s',
										},
										'@keyframes shake': {
											'0%': { transform: 'translateY(0)' },
											'25%': { transform: 'translateY(-5px)' },
											'50%': { transform: 'translateY(5px)' },
											'75%': { transform: 'translateY(-5px)' },
											'100%': { transform: 'translateY(0)' },
										},
									}}
									onClick={() => navigate('/publish-product')}
									size="small">
									<AddIcon sx={{ border: 'solid thin', borderRadius: '20px', mr: '.3rem', mb: '.5rem' }} />
									publicar
								</Button>,
							]}
					</Toolbar>
					<Toolbar sx={{ backgroundColor: '#111111', padding: 0, paddingLeft: '0 !important' }} key={'tool2'}>
						{userContext.token !== undefined &&
							userContext.token !== '' && [
								<>
									{/* Condición para ocultar el componente cuando la pantalla es menor a 750px */}
									<style>{`
                        @media screen and (max-width: 500px) {
                            .hideBelow500px {
                                display: none;
                            }
                        }
                    `}</style>
								</>,
								<Button
									onClick={() => navigate('/')}
									sx={{
										color: 'white', paddingRight: '24%', paddingLeft: 0, marginRight: '-.8rem',
										transition: 'transform 0.3s ease',
										'&:hover': {
											animation: 'shake .8s',
										},
										'@keyframes shake': {
											'0%': { transform: 'translateY(0)' },
											'25%': { transform: 'translateY(-5px)' },
											'50%': { transform: 'translateY(5px)' },
											'75%': { transform: 'translateY(-5px)' },
											'100%': { transform: 'translateY(0)' },
										},
									}}
									className="hideBelow500px">
									<HomeIcon />
								</Button>,
								<Button
									// className="hideBelow500px" // Agregar la clase para ocultar el botón
									style={{
										backgroundColor: '#111111',
										minWidth: 'min-content',
										// marginLeft: '-2rem',
									}}
									onClick={({ currentTarget }) => setAnchorNoti(currentTarget)}
									size="small">
									<Badge
										color="error"
										invisible={notifications?.recivedAsks?.length === 0 && notifications?.recivedAnsers?.length === 0}
										variant="dot"
										sx={{
											transition: 'transform 0.3s ease',
											'&:hover': {
												animation: 'shake .8s',
											},
											'@keyframes shake': {
												'0%': { transform: 'translateY(0)' },
												'25%': { transform: 'translateY(-5px)' },
												'50%': { transform: 'translateY(5px)' },
												'75%': { transform: 'translateY(-5px)' },
												'100%': { transform: 'translateY(0)' },
											},
										}}
									>
										<NotificationsIcon
											sx={{ color: 'white', backgroundColor: '#111111' }}
											fontSize="small"
										/>
									</Badge>
								</Button>,
								<MenuNotifications
									anchorEl={anchorElNoti}
									setAnchorEl={setAnchorNoti}
									notifications={notifications!}
									reloadNotification={getNotifications}
								/>,
							]}
					</Toolbar>
					<Typography
						sx={{
							fontSize: '18px',
							display: { xs: 'none', sm: 'block', md: 'block', lg: 'block' },
						}}>
						{adjustText(userContext.name)}
					</Typography>
					<IconButton
						size="small"
						edge="end"
						aria-label="account of the current user"
						aria-haspopup="true"
						color="inherit"
						onClick={({ currentTarget }) => setAnchorEl(currentTarget)}>
						<Avatar src={userContext.userImage} alt="user" />
					</IconButton>
					<StyledMenu id="account-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
						{(userContext.token === undefined || userContext.token === '') && [
							<MenuItem
								onClick={() => {
									setAnchorEl(null);
									navigate('/login');
								}}
								key={'login'}>
								<LockOpenSharp sx={{ marginLeft: '-1.5%', marginRight: '2%' }} />
								Iniciar sesión
							</MenuItem>,
							<MenuItem
								onClick={() => {
									setAnchorEl(null);
									navigate('/register');
								}}
								key={'register'}>
								<PersonAddAltOutlined sx={{ marginRight: '2%' }} />
								Crear un nuevo usuario
							</MenuItem>,
						]}
						{userContext.token !== undefined &&
							userContext.token !== '' &&
							!isSmallScreen && [
								// <MenuItem
								// 	onClick={() => {
								// 		setAnchorEl(null);
								// 		navigate('/');
								// 	}}
								// 	key={'manageProducts'}
								// 	sx={{transition: 'transform 0.3s ease',
								// 	'&:hover': {
								// 		transform: 'translateX(-6px)',
								// 		backgroundColor: '#212121'
								// 	}}}>
								// 	<HomeIcon sx={{ marginRight: '2%' }} />
								// 	Inicio
								// </MenuItem>,
								<MenuItem
									onClick={() => {
										setAnchorEl(null);
										navigate('/office');
									}}
									key={'userOffice'}
									sx={{
										transition: 'transform 0.3s ease',
										'&:hover': {
											transform: 'translateX(-6px)',
											backgroundColor: '#212121'
										}
									}}>
									<KeyboardIcon sx={{ marginRight: '2%' }} />
									Mi oficina
								</MenuItem>,
								<MenuItem
									onClick={() => {
										setAnchorEl(null);
										navigate('/profile');
									}}
									key={'editUserProfile'}
									sx={{
										transition: 'transform 0.3s ease',
										'&:hover': {
											transform: 'translateX(-6px)',
											backgroundColor: '#212121'

										}
									}}>
									<AssignmentIndIcon sx={{ marginRight: '2%', }} />
									Mi perfil
								</MenuItem>,
								<MenuItem
									onClick={() => {
										setAnchorEl(null);
										navigate('/manage-asks');
									}}
									key={'showComments'}
									sx={{
										transition: 'transform 0.3s ease',
										'&:hover': {
											transform: 'translateX(-6px)',
											backgroundColor: '#212121'

										}
									}}>
									<Badge
										badgeContent={countQuestions}
										color="error"
										sx={{ marginRight: '2%' }}
										showZero={false}
										max={99}
										anchorOrigin={{
											vertical: 'top',
											horizontal: 'left',
										}}>
										<QuestionAnswerIcon />
									</Badge>
									Preguntas
								</MenuItem>,
								<MenuItem
									onClick={() => {
										setAnchorEl(null);
										navigate('/manage-products');
									}}
									key={'manageProducts'}
									sx={{
										transition: 'transform 0.3s ease',
										'&:hover': {
											transform: 'translateX(-6px)',
											backgroundColor: '#212121'

										}
									}}>
									<BuildIcon sx={{ marginRight: '2%' }} />
									Mis repuestos
								</MenuItem>,
								<MenuItem
									onClick={() => {
										setAnchorEl(null);
										logout(userContext) && navigate('/login');
									}}
									key={'logout'}
									sx={{
										transition: 'transform 0.3s ease',
										'&:hover': {
											transform: 'translateX(-6px)',
											backgroundColor: '#212121'
										}
									}}>
									<LogoutIcon sx={{ marginRight: '2%' }} />
									Cerrar sesión
								</MenuItem>,
							]}
						{userContext.token !== undefined &&
							userContext.token !== '' &&
							isSmallScreen && [
								<MenuItem
									onClick={() => {
										setAnchorEl(null);
										navigate('/');
									}}
									key={'inicio'}>
									<HomeIcon sx={{ marginRight: '2%' }} />
									Inicio
								</MenuItem>,
								<MenuItem
									onClick={() => {
										setAnchorEl(null);
										// showNewProduct(true);
										navigate('/publish-product');
									}}
									key={'showNewProduct'}>
									<FileUploadIcon sx={{ marginRight: '2%' }} />
									Publicar repuesto
								</MenuItem>,
								<MenuItem
									onClick={() => {
										setAnchorEl(null);
										navigate('/manage-products');
									}}
									key={'manageProducts'}>
									<Inventory2OutlinedIcon sx={{ marginRight: '2%' }} />
									Mis repuestos
								</MenuItem>,
								<MenuItem
									onClick={() => {
										setAnchorEl(null);
										navigate('/manage-asks');
									}}
									key={'showComments'}>
									<Badge
										badgeContent={countQuestions}
										color="error"
										sx={{ marginRight: '2%' }}
										showZero={false}
										max={99}
										anchorOrigin={{
											vertical: 'top',
											horizontal: 'left',
										}}>
										<QuestionAnswerIcon />
									</Badge>
									Preguntas
								</MenuItem>,
								<MenuItem
									onClick={() => {
										setAnchorEl(null);
										navigate('/profile');
									}}
									key={'editUserProfile'}>
									<AssignmentIndIcon sx={{ marginRight: '2%' }} />
									Mi perfil
								</MenuItem>,
								<MenuItem
									onClick={() => {
										setAnchorEl(null);
										logout(userContext) && navigate('/login');
									}}
									key={'logout'}>
									<LogoutIcon sx={{ marginRight: '2%' }} />
									Cerrar sesión
								</MenuItem>,
							]}
					</StyledMenu>
				</Toolbar>
			</AppBar>
		</Box>
	);
}
