import { Alert, AlertColor } from "@mui/material";
// import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from "react";
import { IAlert } from "../../api/interfaces/interfaces";
import { makeStyles } from "@material-ui/styles";

interface CustomAlertProps {
  type: AlertColor;
  message: string;
  setAlert: (value: IAlert) => void;
}

const useStyles = makeStyles((theme) => ({
  alert: {
    position: "fixed",
    // bottom: theme, // Puedes ajustar este valor según sea necesario
    left: 0,
    right: 0,
    // zIndex: theme.zIndex.snackbar,
    width: '30vw'
  },
}));

export const CustomAlert = (props: CustomAlertProps) => {
  const classes = useStyles();

  const { message, type, setAlert } = props;

  useEffect(() => {
    setTimeout(
      () => setAlert({ show: false, message: "", type: "info" }),
      2000
    );
  }, []);
  return (
    <Alert severity={type} elevation={22} className={classes.alert}>
      {message}
    </Alert>
  );
};
