import {
	Box,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	MenuProps,
	styled,
	Menu,
	MenuItem,
	Typography,
	Card,
} from '@mui/material';
import { CardContentComponent } from './CardContentComponent';
import { Link, useNavigate } from 'react-router-dom';
import { INotification } from '../../api/interfaces/interfaces';
import { setNotificationReaded } from '../../api/utilsApi';
interface MenuNotificationsProps {
	anchorEl: HTMLButtonElement | null;
	setAnchorEl: (value: null) => void;
	notifications: INotification;
	reloadNotification: () => void;
}

const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={10}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 180,
		backgroundColor: '#111111',
		color: 'white',
		boxShadow: 'none !important'
	},
}));

export const MenuNotifications = (props: MenuNotificationsProps) => {
	const { anchorEl, setAnchorEl, notifications, reloadNotification } = props;
	const navigate = useNavigate();

	const setReaded = async (commentID: string) => await setNotificationReaded(commentID);

	const onClickNotification = async (productID: string, commentID: string, responseID: string | null) => {
		console.log(responseID ?? commentID);
		await setReaded(responseID ?? commentID);
		reloadNotification();
		navigate(`/product-detail/${productID}/${commentID}`);
	};
	return (
		<StyledMenu
			open={Boolean(anchorEl)}
			elevation={10}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			onClose={() => setAnchorEl(null)}
			anchorEl={anchorEl}
			sx={{boxShadow: 'none !important', transition: 'transform 0.3s ease',
											'&:hover': {
											  animation: 'shake 2s',
											},
											'@keyframes shake': {
											  '0%': { transform: 'translateY(0)' },
											  '25%': { transform: 'translateY(-5px)' },
											  '50%': { transform: 'translateY(5px)' },
											  '75%': { transform: 'translateY(-5px)' },
											  '100%': { transform: 'translateY(0)' },
											},}}
			>
			<CardHeader
				style={{ padding: 5, color: 'white',paddingLeft: '5%', paddingRight: '30%'}}
				sx={{boxShadow: 'none'}}
				title="Notificaciones"
				titleTypographyProps={{
					style: {
					  color: 'white',
					  fontWeight: 600,
					  fontSize: '1.1rem',
					//   backgroundColor: 'rgb(133 0 0)',
					width: '55%',
					  height: '2.5vh',
					}
				  }}
			/>
			<Divider style={{ color: 'white' }} />
			<Box sx={{ maxHeight: 200, overflowY: 'auto' }}>
				<CardContent style={{ padding: 0 }} onClick={() => setAnchorEl(null)}>
					{notifications?.recivedAsks?.map((item, index) => (
						<MenuItem key={index} onClick={() => onClickNotification(item?.productID, item.commentID, null)}>
							<CardContentComponent ask={item} />
						</MenuItem>
					))}
					{notifications?.recivedAnsers?.map((item, index) => (
						<MenuItem key={index} onClick={() => onClickNotification(item?.productID, item.commentID, item.responseID)}>
							<CardContentComponent answer={item} />
						</MenuItem>
					))}
					{notifications?.recivedAsks?.length === 0 && notifications?.recivedAnsers?.length === 0 && (
						<MenuItem>
							<Card style={{ padding: 2, margin: 2 }}>
								<Typography>No hay nuevas notificaciones</Typography>
							</Card>
						</MenuItem>
					)}
				</CardContent>
			</Box>
			{/* <CardActions
				style={{ padding: 5, color: 'white', backgroundColor: '#1c1c1c', display: 'flex', justifyContent: 'center' }}>
				<Link to="/manage-asks">
					<Typography color="white" style={{ textAlign: 'center' }} onClick={() => setAnchorEl(null)}>
						Ver mis Notificaciones
					</Typography>
				</Link>
			</CardActions> */}
		</StyledMenu>
	);
};
