import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { InsuredView } from "./InsuredView";
import { SinisterView } from "./SinisterView";
import { BillingView } from "./BillingView";

const StyledTabs = styled(Tabs)({
  borderBottom: "1px solid #ddd",
});

const StyledTab = styled(Tab)({
  padding: "10px 20px",
  textTransform: "none",
  fontWeight: 500,
  "&.Mui-selected": {
    borderBottom: "3px solid #1976D2",
  },
});

export const OfficeView = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Container maxWidth="lg" sx={{ marginTop: 4, background: "white" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={4}>
        <Typography variant="h5" fontWeight={300}>
          Gestión de Oficina
        </Typography>
      </Box>
      <StyledTabs value={tabIndex} onChange={handleTabChange}>
        <StyledTab label="Asegurados" />
        <StyledTab label="Siniestros" />
        <StyledTab label="Facturación" />
      </StyledTabs>

      <Box marginTop={4}>
        {tabIndex === 0 && <InsuredView />}
        {tabIndex === 1 && <SinisterView />}
        {tabIndex === 2 && <BillingView />}
      </Box>
    </Container>
  );
};
