import { createContext, useContext } from 'react';

export type UserContextType = {
	name: string;
	setName: (value: string) => void;
	token: string;
	setToken: (value: string) => void;
	userID: string;
	userImage: string;
	setUserImage: (value: string) => void;
	setUserID: (value: string) => void;
	setRefreshToken: (value: string) => void;
	refreshToken: string;
	logOut: () => void;
};

export const UserContext = createContext<UserContextType>({
	name: '',
	setName: () => {},
	token: '',
	setToken: () => {},
	userID: '',
	setUserID: () => {},
	refreshToken: '',
	setRefreshToken: () => {},
  userImage: '',
  setUserImage: ()=> {},
	logOut: () => {},
});

export const UseUserContext = () => useContext(UserContext);
