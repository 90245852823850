import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material'
import { useState } from 'react';
import { EmailChangeAceptedDialog } from './EmailChangeAceptedDialog';
import { EmailChangeDeniedDialog} from './EmailChangeDeniedDialog';
import { IAccountInformation } from '../../../../../api/interfaces/interfaces';
import { updateAccountInformation } from '../../../../../api/userApi';

interface ChangeEmailDialogComponentProps {
	accountInfo: IAccountInformation;
	setAccountInformation: (value: IAccountInformation) => void;
}

export const ChangeEmailDialogComponent = (props : ChangeEmailDialogComponentProps) => {
	const { accountInfo, setAccountInformation } = props;
    const [emailChanged, setEmailChanged] = useState(false);
	const [emailDenied, setEmailDenied] = useState(false);
	const [showDialogEmail, setShowDialogEmail] = useState(false);
	const [loading, setLoading] = useState(false);
	const [errorText, setErrorText] = useState('');
    const [emailForm, setEmailForm] = useState({
		newEmail: '',
		confirmEmail: '',
	});

    const handleOpenCloseDialogEmail = () => {
		setShowDialogEmail(!showDialogEmail);
	};

    ///////// card que aparecen al editar la password
	const handleChangePasswordDialog = () => {
		setEmailChanged(false);
	};
	const handlePasswordDeniedDialog = () => {
		setEmailDenied(false);
	};


	const onSave = async () => {
		setLoading(true);
		const { status } = await updateAccountInformation(accountInfo);
		if(status === 201 || status === 204 || status === 200){
			setEmailChanged(true);
			setErrorText('');
		}else {setEmailDenied(true);
		setErrorText('Completa los campos correctamente');
		}// status === 200 ?? handleOpenCloseDialogEmail();
		setLoading(false);
	};

    return (

        <>      
						<DialogTitle sx={{ color: 'white' }} fontSize="1.5rem">
							Modificar tu email
						</DialogTitle>
						<DialogContent>
                        <TextField
								label="Nuevo email"
								fullWidth
								error={!!errorText}
								helperText={errorText}
								margin="normal"
								onChange={({ target: { value } }) =>
									setEmailForm({
										...emailForm,
										newEmail: value,
									})
								}
								sx={{
									width: { xs: '80%', md: '80%' },
									marginLeft: '5%',
									borderTop: 'none',
									'& .MuiOutlinedInput-root': {
										'& fieldset': {
											borderColor: 'white', // Bordes blancos
										},
										'&:hover fieldset': {
											borderColor: 'white', // Bordes blancos cuando se pasa el cursor
										},
										'&.Mui-focused fieldset': {
											borderColor: '#3F51B5', // Bordes azules cuando está enfocado
										},
										backgroundColor: '#181818', // Fondo negro
										color: 'white', // Texto blanco
									},
								}}
								InputLabelProps={{
									sx: {
										color: 'white', // Color del texto del placeholder
										opacity: 0.6, // Opacidad del placeholder
										'&.Mui-focused': {
											color: 'white',
										},
									},
								}}
							/>
							<TextField
								label="Confirma tu nuevo email"
								fullWidth
								error={!!errorText}
								helperText={errorText}
								margin="normal"
								onChange={({ target: { value } }) =>
									setEmailForm({
										...emailForm,
										confirmEmail: value,
									})
								}
								sx={{
									width: { xs: '80%', md: '80%' },
									marginLeft: '5%',
									borderTop: 'none',
									'& .MuiOutlinedInput-root': {
										'& fieldset': {
											borderColor: 'white', // Bordes blancos
										},
										'&:hover fieldset': {
											borderColor: 'white', // Bordes blancos cuando se pasa el cursor
										},
										'&.Mui-focused fieldset': {
											borderColor: '#3F51B5', // Bordes azules cuando está enfocado
										},
										backgroundColor: '#181818', // Fondo negro
										color: 'white', // Texto blanco
									},
								}}
								InputLabelProps={{
									sx: {
										color: 'white', // Color del texto del placeholder
										opacity: 0.6, // Opacidad del placeholder
										'&.Mui-focused': {
											color: 'white',
										},
									},
								}}
							/>
            </DialogContent>
            <DialogActions>
							<Button
								disabled={
									emailForm.newEmail !== emailForm.confirmEmail ||
									emailForm.newEmail === accountInfo.email ||
									emailForm.newEmail.length < 8
								}
								onClick={() => {
									setAccountInformation({ email: emailForm.newEmail });
									handleOpenCloseDialogEmail();
									onSave();
								}}
								sx={{
									backgroundColor: '#111111',
									color: 'white',
									'&.Mui-disabled': {
										bgcolor: '#111',
										color: 'white',
										opacity: 0.5,
									},
									border: (theme) =>
										`2px inset ${emailForm.newEmail !== emailForm.confirmEmail ||
											emailForm.newEmail === accountInfo.email ||
											emailForm.newEmail.length < 8
											? '#B71C1C' // Si está desactivado, color azul
											: '#B71C1C' // Si está activado, color rojo
										}`,
									width: { xs: '60%', md: '50%' },
									margin: 'auto',
								}}>
								Guardar
							</Button>
						</DialogActions>
                        <Dialog open={emailChanged} onClose={handleChangePasswordDialog}>
					<EmailChangeAceptedDialog/>
				</Dialog>
				<Dialog open={emailDenied} onClose={handlePasswordDeniedDialog}>
					<EmailChangeDeniedDialog />
				</Dialog>

        </>
    )
}
