import { ICategory } from "./interfaces/interfaces";
import { axiosService } from "./axiosService";

export const getVehicleModels = async (): Promise<ICategory[]> => {
  const data = await axiosService.get(`/vehicleModels`).then((response)=> {
    if(response.status === 200)
      return response.data;
    else{
      console.warn(response);
      return {};
    }
  }, (err)=> {console.log('Error: ' + err)});
  return data;
};

export const getVehicleModelsByBrand = async (id: string): Promise<ICategory[]> => {
  const data = await axiosService.get(`/vehicleModels/vehicleModelsByBrandId/${id}`).then((response)=> {
    if(response.status === 200)
      return response.data;
    else{
      console.warn(response);
      return {};
    }
  }, (err)=> {console.log('Error: ' + err)});
  return data;
};