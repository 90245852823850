import { UUID } from 'crypto';
import { IProduct, IProductPreview, INewProduct, IProductsPreview, IProductsByOwner } from './interfaces/interfaces';
import { axiosService } from './axiosService';

export const createNewProduct = async (product: INewProduct): Promise<any> => {
	const data = await axiosService.post('products/createNewProduct', product).then(
		(response) => {
			// const { status, data } = response;
			if (response.status === 201) {
				const { status, data } = response;
				return { status, data };
			}
			return { status: 409 };
		},
		(err) => {
			return { 'status:': 409 };
		},
	);
	return data;
};

//Retorna todos los productos pero con informacion, Revisar interfaz. (propiedad image aun no esta disponible).
export const getProductsPreview = async (limit: number = 10, offset: number = 0): Promise<IProductsPreview> => {
	const data = await axiosService.get(`products/productsPreview?limit=${limit}&offset=${offset}`).then(
		(response) => {
			if (response.status === 200) return response.data;
			return [];
		},
		(err) => {
			console.error('Error: ' + err);
			return [];
		},
	);
	return data;
};

export const getProductsRecientlyAdded = async (limit?: string, offset?: string): Promise<IProductPreview[]> => {
	const data = await axiosService.get('products/recientlyAdded').then(
		(response) => {
			if (response.status === 200) return response.data;
			return [];
		},
		(err) => {
			console.error('Error: ' + err);
			return [];
		},
	);
	return data;
};

export const getProductByID = async (productId: string, limit: number = 10, offset: number = 0): Promise<IProduct> => {
	const data = await axiosService.get(`products/productById/${productId}?limit=${limit}&offset=${offset}`).then(
		(response) => {
			if (response.status === 200) return response.data;
			return {};
		},
		(err) => {
			console.error('Error: ' + err);
		},
	);
	return data;
};

export const getProductByOwner = async (limit: number = 10, offset: number = 0): Promise<IProductsByOwner> => {
	const data = await axiosService.get(`products/productsByOwner?limit=${limit}&offset=${offset}`).then(
		(response) => {
			if (response.status === 200) return response.data;
			return {};
		},
		(err) => {
			console.error('Error: ' + err);
			return [];
		},
	);
	return data;
};

export const getProductByOwnerID = async (sellerID: string, limit: number = 20, offset: number = 0): Promise<IProductsByOwner> => {
	const data = await axiosService.get(`products/productsByOwnerId/${sellerID}?limit=${limit}&offset=${offset}`).then(
		(response) => {
			if (response.status === 200) return response.data;
			return {};
		},
		(err) => {
			console.error('Error: ' + err);
			return [];
		},
	);
	return data;
};

export const  getProductBySearchByOwner = async (inputSearch: string, limit: number = 20, offset: number = 0, sellerID?: string ): Promise<IProductsByOwner> => {
	const url = sellerID ? `products/productsBySearchByOwnerId/${sellerID}` : `products/productsBySearchByOwner`;
	const data = await axiosService.get(`${url}/${inputSearch}?limit=${limit}&offset=${offset}`).then(
		(response) => {
			if (response.status === 200) return response.data;
			return {};
		},
		(err) => {
			console.error('Error: ' + err);
			return [];
		},
	);
	return data;
};

export const getProducts = async (): Promise<IProduct[]> => {
	const data = await axiosService.get('products').then(
		(response) => {
			if (response.status === 200) return response.data;
			return [];
		},
		(err) => {
			console.error('Error: ' + err);
			return [];
		},
	);
	return data;
};

export const getProductsBySearch = async (search: string): Promise<IProductsPreview> => {
	const data = await axiosService.get(`products/productsBySearch/${search}`).then(
		(response) => {
			if (response.status === 200) return response.data;
			return [];
		},
		(err) => {
			console.error('Error: ' + err);
			return [];
		},
	);
	return data;
};

export const getProductsConditions = async (): Promise<string[]> => {
	const data = await axiosService.get(`products/productsConditions`).then(
		(response) => {
			if (response.status === 200) return response.data;
			return [];
		},
		(err) => {
			console.error('Error: ' + err);
			return [];
		},
	);
	return data;
};

export const deleteProductByID = async (productID: string): Promise<any> => {
	const data = await axiosService.delete(`products/deleteProductById/${productID}`).then(
		(response) => {
			// const { status, data } = response;
			if (response.status === 204) {
				const { status, data } = response;
				return { status, data };
			}
			return { status: 409 };
		},
		(err) => {
			return { 'status:': 409 };
		},
	);
	return data;
};

export const updateProductByID = async (productID: UUID, productData: INewProduct): Promise<any> => {
	delete productData.images;
	const data = await axiosService.patch(`products/updateProductById/${productID}`, productData).then(
		(response) => {
			// const { status, data } = response;
			if (response.status === 201) {
				const { status, data } = response;
				return { status, data };
			}
			return { status: 409 };
		},
		(err) => {
			return { 'status:': 409 };
		},
	);
	return data;
};

export const updateProductStatusByID = async (productID: UUID, status: string): Promise<any> => {
	const data = await axiosService.patch(`products/updateProductStatusById/${productID}`, { status: status }).then(
		(response) => {
			// const { status, data } = response;
			if (response.status === 201) {
				const { status, data } = response;
				return { status, data };
			}
			return { status: 409 };
		},
		(err) => {
			return { 'status:': 409 };
		},
	);
	return data;
};
