import { useState } from 'react';
import { GlobalContext } from './GlobalContext';

interface GlobalProviderProps {
	children: React.ReactNode;
}

const GlobalProvider = ({ children }: GlobalProviderProps) => {
	const [searchedText, setSearchedText_] = useState('');
	const [executeSearch, setExecuteSearch_] = useState<boolean>(false);

	const setSearchedText = (name: string) => setSearchedText_(name);
	const setExecuteSearch = () => setExecuteSearch_(!executeSearch);

	return (
		<GlobalContext.Provider value={{ executeSearch, searchedText, setExecuteSearch, setSearchedText }}>
			{children}
		</GlobalContext.Provider>
	);
};

export default GlobalProvider;
